import './calendarDayEvent.scss';

import * as React from 'react';
import moment, { Moment } from 'moment';
import { ConditionalFragment } from 'react-conditionalfragment';
import { scheduleDescription } from '../../../utilities/scheduleDescription';
import { Col, Row } from 'reactstrap';
import { EventTypeColorBlock } from './EventTypeColorBlock';
import { CalendarHomeScheduleViewModel } from '../../calendar/CalendarHomeScheduleViewModel';

export interface CalendarDayEventProps {
    date: Moment,
    schedule: CalendarHomeScheduleViewModel,
    onClick?: React.MouseEventHandler<HTMLElement>,
}

/**
 * Event showing within a CalendarDay.
 */
export const CalendarDayEvent = (props: CalendarDayEventProps) => {
    const { date, schedule, onClick } = props;

    // Work out where we fit in multi-day events.
    const {
        startsBeforeThisDay,
        endsAfterThisDay,
        isMultiDayEvent,
        isDayTwoOfMultiDayEvent,
        isDayBeforeEndOfMultiDayEvent,
        isMonday,
    } = React.useMemo(() => {
        const startOfDay = moment(date).startOf('day').toISOString();
        const endOfDay = moment(date).endOf('day').toISOString();

        const startsBeforeThisDay = (moment(schedule.start).toISOString() < startOfDay);
        const endsAfterThisDay = (moment(schedule.end).toISOString() > endOfDay);
        const isMultiDayEvent = startsBeforeThisDay || endsAfterThisDay;
        const isDayTwoOfMultiDayEvent = startsBeforeThisDay && moment(schedule.start).add(1, 'day').toISOString() >= startOfDay;
        const isDayBeforeEndOfMultiDayEvent = endsAfterThisDay && moment(schedule.end).add(-1, 'day').toISOString() <= startOfDay;
        const isMonday = date.format('dddd').toLocaleLowerCase() === 'monday';

        return {
            startsBeforeThisDay,
            endsAfterThisDay,
            isMultiDayEvent,
            isDayTwoOfMultiDayEvent,
            isDayBeforeEndOfMultiDayEvent,
            isMonday,
        };
    }, [date, schedule]);

    return (
        <div className={`calendar-day-event ${startsBeforeThisDay ? 'calendar-day-event-starts-before' : ''} ${endsAfterThisDay ? 'calendar-day-event-ends-after' : ''} ${isMultiDayEvent ? 'calendar-day-event-multiday' : ''} ${isDayTwoOfMultiDayEvent ? 'calendar-day-event-multiday-second-day' : ''} ${isDayBeforeEndOfMultiDayEvent ? 'calendar-day-event-multiday-second-to-last-day' : ''}`}
            onClick={onClick}
        >
            <ConditionalFragment showIf={!schedule.isAllDay || startsBeforeThisDay || endsAfterThisDay}>
                <div className="calendar-day-event-time">
                    {scheduleDescription(schedule, { timeOnly: (!startsBeforeThisDay && !endsAfterThisDay) })}
                </div>
            </ConditionalFragment>
            <Row noGutters>
                <Col xs="auto">
                    <EventTypeColorBlock color={schedule.calendarEvent?.eventType?.color ?? 'transparent'}
                        transparent={startsBeforeThisDay && !isMonday} />
                </Col>
                <Col>
                    <div className="calendar-day-event-text">
                        {schedule.calendarEvent?.name}
                    </div>
                </Col>
            </Row>
        </div>
        );
};