import * as React from 'react';
import { atom, useRecoilState } from 'recoil';
import { persistAtom } from '../../configure/recoilPersistConfig';

/**
 * Mode of the calendar being shown.
 */
export type CalendarMode = 'calendar' | 'list';

export const calendarPreferencesCalendarModeState = atom<CalendarMode>({
    key: 'calendarPreferencesCalendarModeState',
    default: 'calendar',
    effects_UNSTABLE: [persistAtom],
});

/**
 * Manage the CalendarMode global state. 
 */
export function useCalendarPreferencesCalendarMode(): [CalendarMode, React.Dispatch<React.SetStateAction<CalendarMode>>] {
    const [value, setValue] = useRecoilState(calendarPreferencesCalendarModeState);
    return [value, setValue];
}