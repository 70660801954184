import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { CalendarEventProvidersList } from "./CalendarEventProvidersList";
import { EditCalendarEventProvider, CreateCalendarEventProvider } from "./EditCalendarEventProvider";
import { DeleteCalendarEventProvider } from "./DeleteCalendarEventProvider";

export const calendarEventProviderRoutes: Array<RouteEntry> = [
    { path: '/administration/providers', exact: true, component: CalendarEventProvidersList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/providers/edit/:id', component: EditCalendarEventProvider, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/providers/add', component: CreateCalendarEventProvider, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/providers/delete/:id', component: DeleteCalendarEventProvider, authorize: true, requireRole: IdentityRoles.Administration },
];
