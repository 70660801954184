import * as React from 'react';
import { Table, Row, Col, Collapse } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { StickyToolbar } from '../shared/StickyToolbar';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Waypoint } from 'react-waypoint';
import { useAnalyticEventsListViewModel } from '../../api/main/analyticEvents/viewModels/useAnalyticEventsListViewModel';
import { analyticEventsListViewModelQuery_items, analyticEventsListViewModelQuery_items_calendarEvent } from '../../api/main/generated/analyticEventsListViewModelQuery';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToggleStateArray } from 'use-toggle-state';
import { ISODateTimeInput } from '../shared/ISODateTimeInput';
import { AnalyticEventChart } from './AnalyticEventsChart';
import { ScrollTo } from '@scottbamford/react-scrollto';
import { scheduleDescription } from '../../utilities/scheduleDescription';

/**
 * List of CalendarEventOpenAnalyticEvents.
 */
export const CalendarEventOpenAnalyticEventsList = () => {
    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');

    const [fromDate, setFromDate] = React.useState<string>(() => moment().startOf('month').toISOString());
    const [toDate, setToDate] = React.useState<string>(() => moment().startOf('month').add(1, 'month').toISOString());

    const { data: { items: allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useAnalyticEventsListViewModel({ pageSize: undefined, fromDate: fromDate, toDate: toDate, eventType: 'CalendarEventOpen', });

    // Filter by the user's search client side so it can work when offline as well as online.
    const items = React.useMemo(() => {
        if (!allItems || !search) {
            return allItems;
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return allItems.filter(item =>
            item.url.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || (item.user?.email ?? '').toLocaleLowerCase()?.indexOf(lowerSearch) >= 0
            || (item.calendarEvent?.name ?? '').toLocaleLowerCase()?.indexOf(lowerSearch) >= 0
            || (item.calendarEventUrl?.calendarEvent?.name ?? '').toLocaleLowerCase()?.indexOf(lowerSearch) >= 0
            || t('common.datetime', '{{date, DD/MM/YYYY HH:mm}}', { date: moment(item.eventDate) }).toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || (item.calendarEventSchedule && scheduleDescription(item.calendarEventSchedule).toLocaleLowerCase()?.indexOf(lowerSearch) >= 0)
            || item.calendarEvent?.providers?.filter(it => it.provider.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0)?.length
        );
    }, [allItems, search, t]);

    useReplaceSearchParamsEffect({ search: search });

    // Group the items by their URL.
    const groups = React.useMemo(() => {
        if (!items) {
            return [];
        }

        let ret: Array<{
            calendarEvent: analyticEventsListViewModelQuery_items_calendarEvent,
            items: Array<analyticEventsListViewModelQuery_items>
        }> = [];

        for (const item of items) {
            if (!item.calendarEvent) {
                continue;
            }

            let group = ret.find(it => it.calendarEvent.id === item.calendarEventId);
            if (!group) {
                group = {
                    calendarEvent: item.calendarEvent,
                    items: []
                };
                ret.push(group);
            }

            group.items.push(item);
        }

        // Sort in descending item count order.
        ret.sort((a, b) => {
            if (a.items.length === b.items.length) {
                return 0;
            } else if (a.items.length > b.items.length) {
                return -1;
            } else {
                return 1;
            }
        });

        return ret;
    }, [items]);

    const chartData = React.useMemo(() => groups.map(group => ({
        id: group.calendarEvent.id,
        text: group.calendarEvent?.name,
        count: group.items.length,
    })), [groups]);

    // Scroll to.
    const [scrollToGroupUrl, setScrollToGroupUrl] = React.useState<string | undefined>(undefined);

    // Track which groups are open.
    const [groupIsOpen, toggleGroupOpen] = useToggleStateArray<string>([]);

    const groupColCount = 4;

    return (
        <>
            <Banner fluid>
                <Row>
                    <Col>
                        <h1>{t('calendarEventOpenAnalyticEventsList.heading', 'Calendar event opens')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <StickyToolbar>
                    <Row>
                        <Col xs={6} md="auto">
                            <span className="sr-only">{t('calendarEventOpenAnalyticEventsList.from', 'From')}</span>
                            <ISODateTimeInput value={fromDate} onChange={e => setFromDate(e.currentTarget.value)} />
                        </Col>
                        <Col xs={6} md="auto">
                            <span className="sr-only">{t('calendarEventOpenAnalyticEventsList.to', 'To')}</span>
                            <ISODateTimeInput value={toDate} onChange={e => setToDate(e.currentTarget.value)} />
                        </Col>
                        <Col xs={12} md="">
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />

                <AnalyticEventChart data={chartData} height={300} onItemClick={selected => {
                    // Scroll to the selected group.
                    setScrollToGroupUrl(selected.id);

                    // Expand the group if it is not expanded.
                    if (!groupIsOpen(selected.id)) {
                        toggleGroupOpen(selected.id);
                    }
                }} />

                <Table responsive striped>
                    <thead>
                        <tr>
                            <th className="">{t('calendarEventOpenAnalyticEventsList.link', 'Calendar event')}</th>
                            <th className="d-none d-md-table-cell">{t('calendarEventOpenAnalyticEventsList.link', 'Providers')}</th>
                            <th className="">{t('calendarEventOpenAnalyticEventsList.count', 'Count')}</th>
                            <th className="">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            groups?.map(group => {
                                return (
                                    <React.Fragment key={group.calendarEvent.id}>
                                        <tr style={{ cursor: 'pointer' }} onClick={() => toggleGroupOpen(group.calendarEvent.id)}>
                                            <td className="">
                                                <ScrollTo shouldScrollTo={scrollToGroupUrl === group.calendarEvent.id} />
                                                {group.calendarEvent.name}
                                            </td>
                                            <td className="d-none d-md-table-cell">
                                                {
                                                    group.calendarEvent.providers.map(item => (
                                                        <div key={item.id}>
                                                            {item.provider.name}
                                                        </div>
                                                    ))
                                                }
                                            </td>
                                            <td className="">{t('common.integer', '{{value, #,0}}', { value: group.items.length })}</td>
                                            <td className="text-right">
                                                <FontAwesomeIcon icon={groupIsOpen(group.calendarEvent.id)? 'caret-up': 'caret-down'} />
                                            </td>
                                        </tr>
                                        <ConditionalFragment showIf={groupIsOpen(group.calendarEvent.id)}>
                                            <tr>
                                                <td colSpan={groupColCount} style={{ display: 'none' }}>
                                                    {/* This row is here for maintaining visible striping in the table only */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={groupColCount} style={{ borderTopStyle: 'none' }}>
                                                    <Collapse isOpen={groupIsOpen(group.calendarEvent.id)}>
                                                        {/* Inner table of actual items in the group */}
                                                        <Table responsive striped>
                                                            <thead>
                                                                <tr>
                                                                    <th className="">{t('calendarEventOpenAnalyticEventsList.date', 'Date')}</th>
                                                                    <th className="">{t('calendarEventOpenAnalyticEventsList.user', 'User')}</th>
                                                                    <th className="d-none d-md-table-cell">{t('calendarEventOpenAnalyticEventsList.event', 'Event')}</th>
                                                                    <th className="d-none d-md-table-cell">{t('calendarEventOpenAnalyticEventsList.providers', 'Providers')}</th>
                                                                    <th className="">{t('calendarEventOpenAnalyticEventsList.schedule', 'Schedule')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    group.items.map(item => {
                                                                        return (
                                                                            <React.Fragment key={item.id}>
                                                                                <tr>
                                                                                    <td className="">{t('common.datetime', '{{date, DD/MM/YYYY HH:mm}}', { date: moment(item.eventDate) })}</td>
                                                                                    <td className="">{item.user?.email}</td>
                                                                                    <td className="d-none d-md-table-cell">
                                                                                        <Link to={`/administration/events/edit/${item?.calendarEvent?.id || ''}`}>
                                                                                            {item?.calendarEvent?.name}
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td className="d-none d-md-table-cell">
                                                                                        {
                                                                                            group.calendarEvent.providers.map(item => (
                                                                                                <div key={item.id}>
                                                                                                    {item.provider.name}
                                                                                                </div>
                                                                                            ))
                                                                                        }
                                                                                    </td>
                                                                                    <td className="">
                                                                                        {
                                                                                            item.calendarEventSchedule ? scheduleDescription(item.calendarEventSchedule)
                                                                                                : null
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Collapse>
                                                </td>
                                            </tr>
                                        </ConditionalFragment>
                                    </React.Fragment>
                                );
                            })
                        }
                        
                        <ConditionalFragment showIf={isLoading && !items?.length}>
                            <tr><td colSpan={groupColCount}><LoadingIndicator fullWidth /></td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && !items?.length && !hasMore()}>
                            <tr><td colSpan={groupColCount}>
                                <NoResultsFound search={search} />
                            </td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && hasMore()}>
                            <tr><td colSpan={groupColCount}>
                                <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                                <LoadingIndicator fullWidth />
                            </td></tr>
                        </ConditionalFragment>
                    </tbody>
                </Table>
            </MainContainer>
        </>
    );
};
