import * as React from 'react';
import { Button, Form, Label, FormGroup, Spinner, Input, Row, Col } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { useCalendarEventType } from '../../api/main/calendarEventTypes/useCalendarEventType';
import { useDeleteCalendarEventTypeCallback } from '../../api/main/calendarEventTypes/useDeleteCalendarEventTypeCallback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';

/**
 * Delete a CalendarEventType.
 */
export const DeleteCalendarEventType = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { data: { model }, isLoading, errors: loadErrors } = useCalendarEventType(id);
    const [remove, { errors: removeErrors }] = useDeleteCalendarEventTypeCallback();
    const history = useHistory();

    const [onRemoveFormSubmit, { isExecuting: isRemoving, errors: removeFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        await remove(model.id);

        history.goBack();
    }, [remove, model, history]);


    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>{t('deleteCalendarEventType.heading', 'Delete event type?')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, removeFormErrors, removeErrors]} />

                <Form onSubmit={e => { e.preventDefault(); onRemoveFormSubmit(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('deleteCalendarEventType.name', 'Name')}</Label>
                        <Input name="name" type="text" readOnly plaintext value={model?.name ?? ''} />
                    </FormGroup>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="danger" isExecuting={isRemoving}
                                executingChildren={<><Spinner size="sm" /> {t('common.deleting', 'Deleting...')}</>}>
                                <FontAwesomeIcon icon="trash" />
                                <> {t('common.delete', 'Delete')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
