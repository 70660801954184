import gql from "graphql-tag";
import { calendarHeadlineFields } from "../generated/calendarHeadlineFields";

export const calendarHeadlineFieldsFragment = gql`
    fragment calendarHeadlineFields on CalendarHeadline {
        id
        start
        end
        name
        descriptionHtml
        imageBlobReferenceId
        archived
        createdByUserId
        createdDate
    }
`;


export type CalendarHeadline = Omit<calendarHeadlineFields, '__typename'>;
