import { RouteEntry } from "../../shared/routes";
import { AdministrationNavigation } from "./AdministrationNavigation";
import { AdministrationHome } from "./AdministrationHome";

export const administrationRoutes: Array<RouteEntry> = [
    { path: '/administration', exact: true, component: AdministrationHome },
];

export const administrationSidebarRoutes: Array<RouteEntry> = [
    { path: '/administration', component: AdministrationNavigation },
];
