import * as React from 'react';
import { CalendarEventScheduleCreateInput, CalendarEventScheduleUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateCalendarEventScheduleCallback } from './useCreateCalendarEventScheduleCallback';
import { useUpdateCalendarEventScheduleCallback } from './useUpdateCalendarEventScheduleCallback';
import { CalendarEventSchedule } from '../models/CalendarEventSchedule';

/**
 * Get a callback to save a CalendarEventSchedule in the store using either a create or update.
 */
export function useSaveCalendarEventScheduleCallback(options: SaveInStoreOptions<CalendarEventSchedule, string> = {}) {
    const [_create, createStatus] = useCreateCalendarEventScheduleCallback();
    const create = React.useCallback((model: Partial<CalendarEventSchedule>) => _create(model as CalendarEventScheduleCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateCalendarEventScheduleCallback();
    const update = React.useCallback((id: string, changes: Partial<CalendarEventSchedule>) => _update(id, changes as CalendarEventScheduleUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
