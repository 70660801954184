import './calendarHomeStickyToolbar.scss';

import * as React from 'react';
import { StickyToolbar, StickyToolbarProps } from '../shared/StickyToolbar';

export interface CalendarHomeStickyToolbarProps extends StickyToolbarProps {
}

/**
 * Specialised version of StickyToolbar for use on the CalendarHome screen.
 * 
 * This is mostly about formatting and hiding/showing items as appropriate.  Its paired tightly with CalendarHome.
 */
export const CalendarHomeStickyToolbar = (props: CalendarHomeStickyToolbarProps) => {
    const { className, ...rest } = props;

    return (
        <StickyToolbar className={`calendar-home-sticky-toolbar ${className ?? ''}`} {...rest} />
        );
};