import React from 'react';
import { Button, Card, CardText, CardBody, CardTitle, Row, Col } from 'reactstrap';
import { useIosInstallPrompt } from './useIosInstallPrompt';
import { useWebInstallPrompt } from './useWebInstallPrompt';
import './installPwaPrompt.scss';
import { useTranslation } from 'react-i18next';
import { useToggleState } from 'use-toggle-state';

export const InstallPwaPrompt = () => {
    let [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
    const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();

    const [isHidden, toggleHidden] = useToggleState(false);

    const { t } = useTranslation();

    // If we can't be installed then don't show the prompt (NOTE disable this to force the prompt for development purposes).
    if (!iosInstallPrompt && !webInstallPrompt) {
        return null;
    }

    // If user has closed the prompt directly, don't show it regardless of anything else.
    if (isHidden) {
        return null;
    }

    return (
        <Card color="dark" className="install-pwa-prompt">
            <CardBody>
                <CardTitle className="text-center">
                    <div className="install-pwa-prompt-title-image">
                        <span className="sr-only">
                            {t('installPwaPrompt.TheSchoolYear', 'TheSchoolYear')}
                        </span>
                    </div>
                </CardTitle>
                <p>
                    {t('installPwaPrompt.body', 'TheSchoolYear can be installed as an app on your phone, tablet, or PC so you always have every date available whenever and wherever you need it.')}
                </p>

                {
                    iosInstallPrompt ? (
                        <>
                            <CardText className="text-center">
                                {t('installPwaPrompt.iosInstall.beforeIcon', 'Tap ')}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="install-pwa-prompt-ios-share-icon"
                                    viewBox="0 0 120 169"
                                >
                                    <g fill="currentColor">
                                        <path d="M60 0l28 28-2 2a586 586 0 0 0-4 4L64 15v90h-8V15L38 34l-4-4-2-2L60 0z" />
                                        <path d="M0 49h44v8H8v104h104V57H76v-8h44v120H0V49z" />
                                    </g>
                                </svg>
                                {t('installPwaPrompt.iosInstall.afterIcon', ' then "Add to Home Screen"')}
                            </CardText>
                            <div className="d-flex justify-content-center">
                                <Button color="primary" outline onClick={() => {
                                    handleIOSInstallDeclined();
                                    toggleHidden();
                                }}>
                                    {t('common.close', 'Close')}
                                </Button>
                            </div>
                        </>
                    ) : (
                            <Row>
                                <Col>
                                </Col>
                                <Col xs="auto">
                                    <Button color="primary" onClick={handleWebInstallAccepted}>
                                        {t('installPwaPrompt.install', 'Install')}
                                    </Button>
                                </Col>
                                <Col xs="auto">
                                    <Button color="primary" outline onClick={() => {
                                        handleWebInstallDeclined();
                                        toggleHidden();
                                    }}>
                                        {t('common.close', 'Close')}
                                    </Button>
                                </Col>
                            </Row>
                        )
                }
            </CardBody>
        </Card>
    );
};