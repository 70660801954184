/**
 * Get a full URL from a string that may be a partial url (e.g. may be "bing.com" rather than "https://bing.com").
 * @param url
*/
export function getFullUrl(url: string): string
{
    if (url.indexOf(':/') >= 0) {
        return url;
    }

    return `http://${url}`;
}