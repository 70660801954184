import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CmsTextCreateInput } from '../generated/globalTypes';
import { createCmsTextMutation, createCmsTextMutationVariables } from '../generated/createCmsTextMutation';
import { cmsTextFieldsFragment } from '../models/CmsText';

/**
 * Get a callback to create a CmsText in the store.
 */
export function useCreateCmsTextCallback(): [(model: CmsTextCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createCmsTextMutation, createCmsTextMutationVariables>(
        gql`
            mutation createCmsTextMutation ($model: CmsTextCreateInput!) {
                createCmsText(model: $model) {
                    ...cmsTextFields
                }
            }

            ${cmsTextFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: CmsTextCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
