import * as React from 'react';
import { CalendarEventCreateInput, CalendarEventUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateCalendarEventCallback } from './useCreateCalendarEventCallback';
import { useUpdateCalendarEventCallback } from './useUpdateCalendarEventCallback';
import { CalendarEvent } from '../models/CalendarEvent';

/**
 * Get a callback to save a CalendarEvent in the store using either a create or update.
 */
export function useSaveCalendarEventCallback(options: SaveInStoreOptions<CalendarEvent, string> = {}) {
    const [_create, createStatus] = useCreateCalendarEventCallback();
    const create = React.useCallback((model: Partial<CalendarEvent>) => _create(model as CalendarEventCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateCalendarEventCallback();
    const update = React.useCallback((id: string, changes: Partial<CalendarEvent>) => _update(id, changes as CalendarEventUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
