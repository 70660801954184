import * as React from 'react';
import { CalendarEventUrlCreateInput, CalendarEventUrlUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateCalendarEventUrlCallback } from './useCreateCalendarEventUrlCallback';
import { useUpdateCalendarEventUrlCallback } from './useUpdateCalendarEventUrlCallback';
import { CalendarEventUrl } from '../models/CalendarEventUrl';

/**
 * Get a callback to save a CalendarEventUrl in the store using either a create or update.
 */
export function useSaveCalendarEventUrlCallback(options: SaveInStoreOptions<CalendarEventUrl, string> = {}) {
    const [_create, createStatus] = useCreateCalendarEventUrlCallback();
    const create = React.useCallback((model: Partial<CalendarEventUrl>) => _create(model as CalendarEventUrlCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateCalendarEventUrlCallback();
    const update = React.useCallback((id: string, changes: Partial<CalendarEventUrl>) => _update(id, changes as CalendarEventUrlUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
