import * as React from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'util';

export interface AnalyticEventsChartDataItem {
    id: string,
    text: string,
    count: number
}

export interface AnalyticEventsChartProps {
    data: Array<AnalyticEventsChartDataItem>,

    onItemClick?: (selectedItem: AnalyticEventsChartDataItem) => void,

    width?: string | number | undefined,
    height?: string | number | undefined,
}

export const AnalyticEventChart = (props: AnalyticEventsChartProps) => {
    const { t } = useTranslation();

    const {
        data,
        onItemClick,
        width,
        height,
    } = props;


    const series = React.useMemo(() => [{
        name: t('analyticEventsChart.seriesName', 'Clicks'),
        data: data.map(item => ({
            x: item.text,
            y: item.count,

            _item: item,
        }))
    }], [data, t]);

    const options = React.useMemo(() => ({
        chart: {
            toolbar: {
                show: false,
            },
            events: {
                dataPointSelection: (e, chart, config) => {
                    // If the chart type doesn't give us a useful data point for where we clicked, do nothing.
                    if (isUndefined(config.dataPointIndex) || isUndefined(config.seriesIndex)) {
                        return;
                    }

                    // Raise the onItemClick event.
                    if (onItemClick) {
                        const selectedItem = config.w.config.series[config.seriesIndex].data[config.dataPointIndex]?._item;
                        if (selectedItem) {
                            onItemClick(selectedItem);
                        }
                    }
                },
            }
        },
        xaxis: {
            type: 'category',
        }
    } as ApexCharts.ApexOptions), [onItemClick]);

    // We need to force the refresh of the chart sometimes by updating its key as its too optomistic with its internal caching and
    // so doesn't end up changes top options very well at all if we dont.
    const key = React.useMemo(() => JSON.stringify(options), [options]);

    return (
        <Chart key={key} options={options} series={series} type="bar" width={width} height={height} />
        );
};
