import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadPagedResult } from '../../../../shared/abstractStore';
import { calendarEventsListViewModelQuery, calendarEventsListViewModelQueryVariables } from '../../generated/calendarEventsListViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { calendarEventFieldsFragment } from '../../models/CalendarEvent';
import { blobReferenceFieldsFragment } from '../../models/BlobReference';
import { CalendarEventsAsyncLoadPagedOptions } from '../useCalendarEvents';
import { useFetchMoreCallback } from '../../../../shared/useFetchMoreCallback';
import { calendarEventScheduleFieldsFragment } from '../../models/CalendarEventSchedule';

/**
 * View model for CalendarEventsList that replaces the call to useCalendarEvents() and loads the right related data.
 * @param options
 */
export function useCalendarEventsListViewModel(options: CalendarEventsAsyncLoadPagedOptions = {}): AsyncLoadPagedResult<calendarEventsListViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<calendarEventsListViewModelQuery, calendarEventsListViewModelQueryVariables>(
        gql`
        query calendarEventsListViewModelQuery ($offset: Int, $first: Int, $includeArchived: Boolean) {
            items: calendarEvents (offset: $offset, first: $first, includeArchived: $includeArchived) {
                ...calendarEventFields

                eventType {
                    id
                    name
                    color
                }

                providers {
                    id
                    provider {
                        id
                        name
                    }
                }

                tagLinks {
                    id
                    itemTag {
                        id
                        name
                    }
                }

                schedules {
                    ...calendarEventScheduleFields
                }

                imageBlobReference {
                    ...blobReferenceFields
                }
            }
        }

        ${calendarEventFieldsFragment}
        ${blobReferenceFieldsFragment}
        ${calendarEventScheduleFieldsFragment}
        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize,
                includeArchived: options.includeArchived,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<calendarEventsListViewModelQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])]
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}
