import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CalendarEventUrlCreateInput } from '../generated/globalTypes';
import { createCalendarEventUrlMutation, createCalendarEventUrlMutationVariables } from '../generated/createCalendarEventUrlMutation';
import { calendarEventUrlFieldsFragment } from '../models/CalendarEventUrl';

/**
 * Get a callback to create a CalendarEventUrl in the store.
 */
export function useCreateCalendarEventUrlCallback(): [(model: CalendarEventUrlCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createCalendarEventUrlMutation, createCalendarEventUrlMutationVariables>(
        gql`
            mutation createCalendarEventUrlMutation ($model: CalendarEventUrlCreateInput!) {
                createCalendarEventUrl(model: $model) {
                    ...calendarEventUrlFields
                }
            }

            ${calendarEventUrlFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: CalendarEventUrlCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
