import gql from "graphql-tag";
import { calendarEventCalendarEventProviderFields } from "../generated/calendarEventCalendarEventProviderFields";

export const calendarEventCalendarEventProviderFieldsFragment = gql`
    fragment calendarEventCalendarEventProviderFields on CalendarEventCalendarEventProvider {
        id
        calendarEventId
        calendarEventProviderId
        displayOrder
        createdByUserId
        createdDate
    }
`;


export type CalendarEventCalendarEventProvider = Omit<calendarEventCalendarEventProviderFields, '__typename'>;
