import gql from "graphql-tag";
import { calendarEventUrlFields } from "../generated/calendarEventUrlFields";

export const calendarEventUrlFieldsFragment = gql`
    fragment calendarEventUrlFields on CalendarEventUrl {
        id
        calendarEventId
        name
        url
        linkType
        displayOrder
        archived
        createdByUserId
        createdDate
    }
`;


export type CalendarEventUrl = Omit<calendarEventUrlFields, '__typename'>;
