import { RouteEntry } from "../shared/routes";
import { apiAuthorizationRoutes } from "../components/api-authorization/routes";
import { accountRoutes } from "../components/account/routes";
import { profileRoutes } from "../components/profiles/routes";
import { userRoutes } from "../components/users/routes";
import { NotFound } from "../components/shared/NotFound";
import { homeRoutes } from "../components/home/routes";
import { administrationRoutes, administrationSidebarRoutes } from "../components/administration/routes";
import { calendarRoutes } from "../components/calendar/routes";
import { calendarEventTypeRoutes } from "../components/calendarEventTypes/routes";
import { calendarEventProviderRoutes } from "../components/calendarEventProviders/routes";
import { calendarHeadlineRoutes } from "../components/calendarHeadlines/routes";
import { schoolPhaseRoutes } from "../components/schoolPhases/routes";
import { schoolTypeRoutes } from "../components/schoolTypes/routes";
import { tagRoutes } from "../components/tags/routes";
import { calendarEventRoutes } from "../components/calendarEvents/routes";
import { analyticEventRoutes } from "../components/analyticEvents/routes";
import { cmsTextRoutes } from "../components/cms/cmsTexts/routes";
import { cmsPagesRoutes } from "../components/cmsPages/routes";
import { linksRoutes } from "../components/links/routes";
import { cmsImageRoutes } from "../components/cms/cmsImages/routes";
import { cmsVideoRoutes } from "../components/cms/cmsVideos/routes";


export const appRoutes: Array<RouteEntry> = [
    ...apiAuthorizationRoutes,
    ...accountRoutes,
    ...homeRoutes,
    ...profileRoutes,
    ...userRoutes,

    ...analyticEventRoutes,
    ...administrationRoutes,
    ...calendarRoutes,
    ...calendarEventTypeRoutes,
    ...calendarEventProviderRoutes,
    ...calendarHeadlineRoutes,
    ...calendarEventRoutes,
    ...cmsPagesRoutes,
    ...cmsImageRoutes,
    ...cmsTextRoutes,
    ...cmsVideoRoutes,
    ...linksRoutes,
    ...schoolPhaseRoutes,
    ...schoolTypeRoutes,
    ...tagRoutes,

    { path: undefined, component: NotFound }
];

export const sidebarRoutes: Array<RouteEntry> = [
    ...administrationSidebarRoutes,
];