import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { ItemTagLinkUpdateInput } from '../generated/globalTypes';
import { updateItemTagLinkMutation, updateItemTagLinkMutationVariables } from '../generated/updateItemTagLinkMutation';
import { itemTagLinkFieldsFragment } from '../models/ItemTagLink';

/**
 * Get a callback to update a ItemTagLink in the store.
 */
export function useUpdateItemTagLinkCallback(): [(id: string, model: ItemTagLinkUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<updateItemTagLinkMutation, updateItemTagLinkMutationVariables>(
        gql`
            mutation updateItemTagLinkMutation ($model: ItemTagLinkUpdateInput!) {
                updateItemTagLink(model: $model) {
                    ...itemTagLinkFields
                }
            }

            ${itemTagLinkFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: ItemTagLinkUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
