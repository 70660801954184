import { Guid } from 'guid-string';
import * as React from 'react';
import { useParams } from 'react-router';
import { useCreateAnalyticEventCallback } from '../../api/main/analyticEvents/useCreateAnalyticEventCallback';
import { useSearchParams } from '../../shared/useURLSearchParams';
import { LoadingIndicator } from '../shared/LoadingIndicator';

/**
 * Redirect the user to a link, logging the event for anlytical purposes on the way.
 */
export const RedirectToLink = () => {
    const { url } = useParams<{ url: string }>();
    const {
        calendarEventId,
        calendarEventUrlId,
    } = useSearchParams();

    const [createAnalyticEvent] = useCreateAnalyticEventCallback();
    const [hasBeenLogged, setHasBeenLogged] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (hasBeenLogged) {
            return;
        }

        setHasBeenLogged(true);

        (async () => {
            const realUrl = decodeURIComponent(url);

            await createAnalyticEvent({
                id: Guid.newGuid(),
                eventType: 'LinkClick',
                calendarEventId: calendarEventId,
                calendarEventUrlId: calendarEventUrlId,
                url: realUrl,
            });

            window.location.href = realUrl;
        })();
    }, [hasBeenLogged, setHasBeenLogged, createAnalyticEvent, url, calendarEventId, calendarEventUrlId]);

    return (
        <LoadingIndicator fullWidth />
        );
};