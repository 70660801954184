import './calendarMonthHeadline.scss';

import * as React from 'react';
import moment, { Moment } from 'moment';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { CalendarHeadline } from '../../api/main/models/CalendarHeadline';
import { HtmlDisplay } from '../../shared/htmlEditor';
import { BlobReference } from '../../api/main/models/BlobReference';
import { ConditionalFragment } from 'react-conditionalfragment';
import { CalendarMode } from '../../globalState/calendarPreferences/useCalendarPreferencesCalendarMode';

export interface CalendarMonthHeadlineProps {
    date: Moment,
    changeDate?: (date: Moment) => void,
    headlines: Array<CalendarHeadline>,
    images: Array<BlobReference>,
    calendarMode?: CalendarMode
}

/**
 * Component a headline with a picture, summary, and any other children (e.g. navigation) provided.
 */
export const CalendarMonthHeadline = (props: CalendarMonthHeadlineProps) => {
    const { date, changeDate, headlines, images, calendarMode, } = props;

    const { t } = useTranslation();

    // We want to work out some dates relative to the date so we can show sensible navigation.
    const { previousMonth, nextMonth } = React.useMemo(() => {
        return {
            previousMonth: moment(date).add(-1, 'month'),
            nextMonth: moment(date).add(1, 'month'),
        }
    }, [date]);

    // Wrapper around changeDate to let us handle undefined events.
    const onChangeDate = React.useCallback((date) => {
        if (!changeDate) {
            return;
        }

        changeDate(date);
    }, [changeDate]);

    // Work out the current best headline to use based on the date.
    const currentHeadline = React.useMemo(() => {
        // Reverse the headlines so they run newest to oldest.
        let orderedHeadlines = [...headlines];
        orderedHeadlines.reverse();

        // Work out the to show the headline for.  For most months that will be the start of the month, but
        // if the current date falls within the month in question, we base things off that instead.
        let isoDate = date.toISOString();
        const today = moment().startOf('day');
        const endOfMonth = moment(date).add(1, 'month');
        if (today >= date && today < endOfMonth) {
            isoDate = today.toISOString();
        }

        // Find the headline with the latest date that is not in the future and has not expired.
        const ret = orderedHeadlines.find(it => {
            const theEndDate = moment(it.start).add(1, 'month').toISOString();
            return it.start <= isoDate && theEndDate > isoDate
        });
        return ret;
    }, [headlines, date]);

    const currentHeadlineImage = React.useMemo(() => {
        if (!currentHeadline || !images) {
            return undefined;
        }

        const ret = images.find(it => it.id === currentHeadline.imageBlobReferenceId);
        return ret;
    }, [images, currentHeadline]);

    return (
        <div className={`calendar-month-headline calendar-month-headline-${calendarMode}`}
            style={{ backgroundImage: currentHeadlineImage ? `url("${currentHeadlineImage?.url}")` : undefined }}
        >
            <Row noGutters>
                <Col xs={12} md={3} lg={4} xl={5}>
                    <div className="calendar-month-headline-picture">

                    </div>
                </Col>
                <Col>
                    <div className="calendar-month-headline-summary">
                        <h2>{currentHeadline?.name ?? t('calendarMonthHeadline.defaultTitle', '{{date, MMMM}} headlines', { date })}</h2>
                        {
                            currentHeadline?.descriptionHtml ? (
                                <HtmlDisplay html={currentHeadline?.descriptionHtml ?? ''} />
                            ) : (
                                    <p>
                                        {t('calendarMonthHeadline.defaultText', 'There are no dates you need to know yet - check back again soon!')}
                                    </p>
                                )
                        }
                    </div>
                </Col>
            </Row>

            <div className="calendar-month-headline-navigation">
                <Row noGutters>
                    <ConditionalFragment showIf={calendarMode === 'calendar'}>
                        <Col xs="auto">
                            <Button color="secondary" outline className="calendar-month-headline-navigation-previous-button" onClick={() => onChangeDate(previousMonth)}>
                                <FontAwesomeIcon icon="chevron-left" />
                                <> </>{t('common.date.month', '{{date, MMMM}}', { date: previousMonth })}
                            </Button>
                        </Col>
                    </ConditionalFragment>
                    <Col>
                        <div className="calendar-month-headline-navigation-month-text">
                            <span className="calendar-month-headline-navigation-month-text-inner">
                                {t('common.date.monthAndYear', '{{date, MMMM}} {{date, YYYY}}', { date: date })}
                            </span>
                        </div>
                    </Col>
                    <ConditionalFragment showIf={calendarMode === 'calendar'}>
                        <Col xs="auto">
                            <Button color="secondary" outline className="calendar-month-headline-navigation-next-button" onClick={() => onChangeDate(nextMonth)}>
                                {t('common.date.month', '{{date, MMMM}}', { date: nextMonth })}
                                <> </><FontAwesomeIcon icon="chevron-right" />
                            </Button>
                        </Col>
                    </ConditionalFragment>
                </Row>
            </div>
        </div>
        );
};