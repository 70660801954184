import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CalendarEventUpdateInput } from '../generated/globalTypes';
import { updateCalendarEventMutation, updateCalendarEventMutationVariables } from '../generated/updateCalendarEventMutation';
import { calendarEventFieldsFragment } from '../models/CalendarEvent';

/**
 * Get a callback to update a CalendarEvent in the store.
 */
export function useUpdateCalendarEventCallback(): [(id: string, model: CalendarEventUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<updateCalendarEventMutation, updateCalendarEventMutationVariables>(
        gql`
            mutation updateCalendarEventMutation ($model: CalendarEventUpdateInput!) {
                updateCalendarEvent(model: $model) {
                    ...calendarEventFields
                }
            }

            ${calendarEventFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: CalendarEventUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
