import gql from "graphql-tag";
import { itemTagFields } from "../generated/itemTagFields";

export const itemTagFieldsFragment = gql`
    fragment itemTagFields on ItemTag {
        id
        name
        color
        archived
        displayOrder
        createdByUserId
        createdDate
        tagType
    }
`;


export type ItemTag = Omit<itemTagFields, '__typename'>;
