import * as React from 'react';
import { useParams } from "react-router";
import { useGlobalSearchText } from "../../globalState/globalSearchText/useGlobalSearchText";
import { CalendarHome } from './CalendarHome';

/**
 * Component that handles routing based searching of the calendar.
 */
export const SearchCalendar = () => {
    const {
        searchText
    } = useParams<{ searchText: string | undefined }>();
    const [, setGlobalSearchText] = useGlobalSearchText();

    // Set the global search text to reflect the one we found on the route.
    React.useEffect(() => {
        setGlobalSearchText(searchText ?? '');
    }, [searchText, setGlobalSearchText]);

    // Render the CalendarHome component as our whole UI.
    return (<CalendarHome startInCalendarMode="list" />);
};