import * as React from 'react';
import { Button, Form, Label, FormGroup, Spinner, Input, Row, Col } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { useProfile } from '../../api/main/profiles/useProfile';
import { useLockoutCallback } from '../../api/account';
import { useDeleteProfileCallback } from '../../api/main/profiles/useDeleteProfileCallback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';

/**
 * Delete a user (profile) by locking them out permanently.
 */
export const DeleteUser = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { data: { model }, isLoading, errors: loadErrors } = useProfile(id);
    const [remove, { errors: removeErrors }] = useDeleteProfileCallback();
    const [lockout, { errors: lockoutErrors }] = useLockoutCallback();
    const history = useHistory();

    const [onRemoveFormSubmit, { isExecuting: isRemoving, errors: removeFormErrors }] = useAsyncCallback(async () => {
        if (!model || !model.user) {
            return;
        }

        await remove(model.id);
        await lockout({ email: model.user.email });

        history.goBack();
    }, [lockout, model, history]);

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>{t('deleteUser.heading', 'Are you sure you want to delete this user?')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, removeFormErrors, removeErrors, lockoutErrors]} />

                <Form onSubmit={e => { e.preventDefault(); onRemoveFormSubmit(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="firstName">{t('deleteUser.firstName', 'First name')}</Label>
                                <Input name="firstName" type="text" readOnly plaintext value={model?.firstName ?? ''} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="lastName">{t('deleteUser.lastName', 'Last name')}</Label>
                                <Input name="lastName" type="text" readOnly plaintext value={model?.lastName ?? ''} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label htmlFor="email">{t('deleteUser.email', 'Email')}</Label>
                        <Input name="lastName" type="text" readOnly plaintext value={model?.user?.email ?? ''} />
                    </FormGroup>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="danger" isExecuting={isRemoving}
                                executingChildren={<><Spinner size="sm" /> {t('common.deleting', 'Deleting...')}</>}>
                                <FontAwesomeIcon icon="trash" />
                                <> {t('common.delete', 'Delete')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
