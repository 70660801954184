import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { ItemTagLinkCreateInput } from '../generated/globalTypes';
import { createItemTagLinkMutation, createItemTagLinkMutationVariables } from '../generated/createItemTagLinkMutation';
import { itemTagLinkFieldsFragment } from '../models/ItemTagLink';

/**
 * Get a callback to create a ItemTagLink in the store.
 */
export function useCreateItemTagLinkCallback(): [(model: ItemTagLinkCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createItemTagLinkMutation, createItemTagLinkMutationVariables>(
        gql`
            mutation createItemTagLinkMutation ($model: ItemTagLinkCreateInput!) {
                createItemTagLink(model: $model) {
                    ...itemTagLinkFields
                }
            }

            ${itemTagLinkFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: ItemTagLinkCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
