import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CalendarEventScheduleCreateInput } from '../generated/globalTypes';
import { createCalendarEventScheduleMutation, createCalendarEventScheduleMutationVariables } from '../generated/createCalendarEventScheduleMutation';
import { calendarEventScheduleFieldsFragment } from '../models/CalendarEventSchedule';

/**
 * Get a callback to create a CalendarEventSchedule in the store.
 */
export function useCreateCalendarEventScheduleCallback(): [(model: CalendarEventScheduleCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createCalendarEventScheduleMutation, createCalendarEventScheduleMutationVariables>(
        gql`
            mutation createCalendarEventScheduleMutation ($model: CalendarEventScheduleCreateInput!) {
                createCalendarEventSchedule(model: $model) {
                    ...calendarEventScheduleFields
                }
            }

            ${calendarEventScheduleFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: CalendarEventScheduleCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
