import * as React from 'react';
import { ValidatedInput, ValidatedInputProps } from 'pojo-validator-reactstrap';
import moment, { MomentInput } from 'moment';

export interface ValidatedDateTimeInputProps extends ValidatedInputProps {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    format?: string
}

/**
 * Input with validation support for a datetime picker using moment() to keep our dates straight.
 * @param props
 */
export const ValidatedISODateTimeInput = (props: ValidatedDateTimeInputProps) => {
    const { type, value, format, onChange, ...rest } = props;
    const theType = type || 'datetime-local';
    const theFormat = format || (theType === 'datetime' || theType === 'datetime-local'? 'YYYY-MM-DD[T]HH:mm': 'YYYY-MM-DD');

    // Handle the value
    const theValue = React.useMemo(() => moment(value as MomentInput).local().format(theFormat), [value, theFormat]);

    // Wrap the real on change event to always return the ISO date string in UTC time.
    const onChangeWrapper = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            const newValue = moment(event.currentTarget.value).utc().toISOString();
            const newEvent = {
                ...event,
                currentTarget: {
                    ...event.currentTarget,
                    value: newValue,
                },
                target: {
                    ...event.target,
                    value: newValue,
                }
            };

            onChange(newEvent);
        }
    }, [onChange]);

    return (
        <ValidatedInput type={theType} value={theValue} onChange={onChangeWrapper} {...rest} />
    );
};
