import * as React from 'react';

import './eventTypeColorBlock.scss'

export interface EventTypeColorBlockProps {
    color: string,
    className?: string,
    /**
     * When true is passed, we render as a fully transparent block of the right size rather than a colour block.
     */
    transparent?: boolean,
}

/**
 * Standard way to show colors 
 * @param props
 */
export const EventTypeColorBlock = (props: EventTypeColorBlockProps) => {
    const { color, className, transparent = false, } = props;

    return (
        <div className={`event-type-color-block ${transparent ? 'event-type-color-block-transparent' : ''} ${className ?? ''}`}
            style={{ backgroundColor: transparent ? 'transparent': color }}
            >
        </div>
        );
};