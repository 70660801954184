import * as React from 'react';
import { Button, Form, Label, FormGroup, Spinner, Row, Col, ButtonGroup } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useChanges } from '../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { Guid } from 'guid-string';
import { useSaveCalendarHeadlineCallback } from '../../api/main/calendarHeadlines/useSaveCalendarHeadlineCallback';
import { CalendarHeadline } from '../../api/main/models/CalendarHeadline';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { HtmlEditor } from '../../shared/htmlEditor';
import { FileUploadButton } from '../shared/fileUploadButton/FileUploadButton';
import { useUploadBlobCallback } from '../../api/main/blobReferences/useUploadBlobCallback';
import { BlobReference } from '../../api/main/models/BlobReference';
import { useEditCalendarHeadlineViewModel } from '../../api/main/calendarHeadlines/viewModels/useEditCalendarHeadlineViewModel';
import { UploadedImagePreview } from '../shared/uploadedImagePreview/UploadedImagePreview';
import { ValidatedISODateTimeInput } from '../shared/ValidatedISODateTimeInput';
import moment from 'moment';

interface EditCalendarHeadlineProps {
    isCreate?: boolean,
}

/**
 * Create a new CalendarHeadline.
 */
export const CreateCalendarHeadline = () => (<EditCalendarHeadline isCreate={true} />);

/**
 * Edit a CalendarHeadline.
 */
export const EditCalendarHeadline = (props: EditCalendarHeadlineProps) => {
    const { isCreate } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const { data: { model: storeModel }, isLoading, errors: loadErrors } = useEditCalendarHeadlineViewModel(id);
    const { model, change, changes } = useChanges<CalendarHeadline>(storeModel, isCreate ? { id: Guid.newGuid(), name: '', descriptionHtml: '', start: moment().startOf('month').toISOString(), } : {});
    const [save, { errors: saveErrors }] = useSaveCalendarHeadlineCallback();
    const history = useHistory();


    // Image display and uploading.
    const [imageBlob, setImageBlob] = React.useState<BlobReference | null>();
    React.useEffect(() => setImageBlob(storeModel?.imageBlobReference), [storeModel]);

    const [uploadBlob] = useUploadBlobCallback();
    const [uploadImageBlob, { isExecuting: isUploadingImageBlob, errors: uploadImageBlobErrors }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        // Upload the blob.
        const blob = await uploadBlob(files);
        if (!blob) {
            return;
        }

        // Update the model.
        change({ imageBlobReferenceId: blob.id });

        // Update the blob in the state.
        setImageBlob(blob);
    }, [uploadBlob, change, setImageBlob]);

    const clearImage = React.useCallback(() => {
        change({ imageBlobReferenceId: null });
        setImageBlob(null);
    }, [change]);

    
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editCalendarHeadline.nameRequired', 'Name is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        await save(model.id, changes, !!isCreate);

        history.goBack();
    }, [validate, save, model, changes, isCreate, history]);

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>
                            {
                                isCreate ? (
                                    <>{t('editCalendarHeadline.createHeading', 'Add headline')}</>
                                        ): (
                                        <>{t('editCalendarHeadline.editHeading', 'Edit headline')}</>
                                        )
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, saveFormErrors, saveErrors, uploadImageBlobErrors]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="start">{t('editCalendarHeadline.start', 'Date')}</Label>
                        <ValidatedISODateTimeInput type="date" value={model?.start ?? ''} onChange={e => change({ start: e.currentTarget.value, end: moment(e.currentTarget.value).add(1, 'month').toISOString() /* Headlines expire after a month */ })} onBlur={e => validate('start')} validationErrors={validationErrors['start']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="name">{t('editCalendarHeadline.name', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="imageBlobReferenceId">{t('editCalendarHeadline.backgroundImage', 'Background image')}</Label>

                        <UploadedImagePreview src={imageBlob?.url ?? ''} />
                        <ButtonGroup>
                            <FileUploadButton onUpload={files => uploadImageBlob(files)} isExecuting={isUploadingImageBlob}
                                executingChildren={<><Spinner size="sm" /><> </>{t('common.uploading', 'Uploading...')}</>}
                            >
                                {t('editCalendarHeadline.uploadBackgroundImage', 'Upload background image...')}
                            </FileUploadButton>
                            <Button color="danger" outline onClick={clearImage}>
                                {t('editCalendarHeadline.clearBackgroundImage', 'Clear image')}
                            </Button>
                        </ButtonGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="descriptionHtml">{t('editCalendarHeadline.descriptionHtml', 'Description')}</Label>
                        <HtmlEditor value={model?.descriptionHtml ?? ''} onChange={value => change({ descriptionHtml: value })} />
                    </FormGroup>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
