import * as React from 'react';
import { Button, Form, Label, FormGroup, Spinner, Row, Col } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useChanges } from '../../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedFormFeedback, ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { Guid } from 'guid-string';
import { useSaveCmsImageCallback } from '../../../api/main/cmsImages/useSaveCmsImageCallback';
import { CmsImage } from '../../../api/main/models/CmsImage';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/Banner';
import { Background } from '../../shared/background/Background';
import { FileUploadButton } from '../../shared/fileUploadButton/FileUploadButton';
import { UploadedImagePreview } from '../../shared/uploadedImagePreview/UploadedImagePreview';
import { useUploadBlobCallback } from '../../../api/main/blobReferences/useUploadBlobCallback';
import { BlobReference } from '../../../api/main/models/BlobReference';
import { CmsAdministrationWarning } from '../shared/CmsAdministrationWarning';
import { useEditCmsImageViewModel } from '../../../api/main/cmsImages/viewModels/useEditCmsImageViewModel';

interface EditCmsImageProps {
    isCreate?: boolean,
}

/**
 * Create a new CmsImage.
 */
export const CreateCmsImage = () => (<EditCmsImage isCreate={true} />);

/**
 * Edit a CmsImage.
 */
export const EditCmsImage = (props: EditCmsImageProps) => {
    const { isCreate } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const { data: { model: storeModel }, isLoading, errors: loadErrors } = useEditCmsImageViewModel(id);
    const { model, change, changes } = useChanges<CmsImage>(storeModel, isCreate ? { id: Guid.newGuid(), name: '', } : {});
    const [save, { errors: saveErrors }] = useSaveCmsImageCallback();
    const history = useHistory();

    // Image display and uploading.
    const [imageBlob, setImageBlob] = React.useState<BlobReference | null>();
    React.useEffect(() => setImageBlob(storeModel?.blobReference), [storeModel]);

    const [uploadBlob] = useUploadBlobCallback();
    const [uploadImageBlob, { isExecuting: isUploadingImageBlob, errors: uploadImageBlobErrors }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        // Upload the blob.
        const blob = await uploadBlob(files);
        if (!blob) {
            return;
        }

        // Update the model.
        change({ blobReferenceId: blob.id });

        // Update the blob in the state.
        setImageBlob(blob);
    }, [uploadBlob, change, setImageBlob]);

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editCmsImage.nameRequired', 'Name is required') : '',
            blobReferenceId: () => Guid.isEmpty(model?.blobReferenceId) ? t('editCmsImage.blobReferenceRequired', 'An uploaded image is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        await save(model.id, changes, !!isCreate);

        history.goBack();
    }, [validate, save, model, changes, isCreate, history]);

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>
                            {
                                isCreate ? (
                                    <>{t('editCmsImage.createHeading', 'Add CMS image')}</>
                                        ): (
                                        <>{t('editCmsImage.editHeading', 'Edit CMS image')}</>
                                        )
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <CmsAdministrationWarning />
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, saveFormErrors, saveErrors, uploadImageBlobErrors]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('editCmsImage.name', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="blobReferenceId">{t('editCmsImage.image', 'Image')}</Label>

                        <UploadedImagePreview src={imageBlob?.url ?? ''} />
                        <ValidatedFormFeedback name="blobReferenceId" validationErrors={validationErrors['blobReferenceId']} />

                        <FileUploadButton onUpload={files => uploadImageBlob(files)} isExecuting={isUploadingImageBlob}
                            executingChildren={<><Spinner size="sm" /><> </>{t('common.uploading', 'Uploading...')}</>}
                        >
                            {t('editCmsImage.uploadImage', 'Upload image...')}
                        </FileUploadButton>
                    </FormGroup>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
