import './calendarMonthHeadline.scss';

import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { CalendarHeadline } from '../../api/main/models/CalendarHeadline';
import { BlobReference } from '../../api/main/models/BlobReference';
import { HtmlDisplay } from '../../shared/htmlEditor';

export interface CalendarMonthHeadlinePreviewProps {
    model: CalendarHeadline,
    image: BlobReference | null | undefined,
}

/**
 * Component that mirrors CalendarMonthHeadline, but shows itself as a preview for the model passed in.
 * 
 * NOTE this class shares the stylessheet from CalenderMonthHeadline
 */
export const CalendarMonthHeadlinePreview = (props: CalendarMonthHeadlinePreviewProps) => {
    const { model, image } = props;

    return (
        <div className="calendar-month-headline-preview calendar-month-headline" style={{ backgroundImage: image ? `url("${image.url}")` : undefined }}>
            <Row noGutters>
                <Col xs={12} lg={6}>
                    <div className="calendar-month-headline-picture">
                    </div>
                </Col>
                <Col>
                    <div className="calendar-month-headline-summary">
                        <h2>{model.name}</h2>
                        <HtmlDisplay html={model.descriptionHtml} />
                    </div>
                </Col>
            </Row>
        </div>
        );
};
