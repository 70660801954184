import { Library, library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faLock, faUser, faSearch, faPlus, faCogs, faSave, faEdit, faUsers, faTrash, faCaretUp, faCaretDown, faCaretLeft, faUndo, faMinus, faExclamationCircle, faCaretRight, faTimesCircle, faCalendarWeek, faBusinessTime, faStopwatch, faUserClock, faTimes, faStop, faDesktop, faRuler, faFlagCheckered, faPlay, faUserCheck, faCreditCard, faPhone, faEnvelope, faSchool, faPoundSign, faVoteYea, faPersonBooth, faTrashAlt, faChevronLeft, faChevronRight, faDownload, faCross, faCheckCircle, faEye, faTrashRestore, faSync, faArchive, faBusAlt, faThumbsDown, faThumbsUp, faCloudDownloadAlt, faPhotoVideo, faTag, faUserTag, faArchway, faCalendarCheck, faLink, faCalendarDay, faFileAlt, faInfo, faCalendarPlus, faCalendarAlt, faUserGraduate } from '@fortawesome/free-solid-svg-icons';
import { faFrown as farFrown, faFrownOpen as farFrownOpen, faCircle as farCircle, faClock as farClock, faCalendarAlt as farCalendarAlt, faListAlt as farListAlt, faCalendarTimes as farCalendarTimes } from '@fortawesome/free-regular-svg-icons';

// Import the fontawsome icons we actually want to use.
export const initializeIcons = () => {
    configureIcons(library);
};

export const configureIcons = (library: Library) => {
    library.add(
        // solid
        faArchive,
        faArchway,
        faBusAlt,
        faBusinessTime,
        faCalendarCheck,
        faCalendarDay,
        faCalendarPlus,
        faCalendarWeek,
        faCalendarAlt,
        faCaretUp,
        faCaretDown,
        faCaretLeft,
        faCaretRight,
        faCheckCircle,
        faChevronLeft,
        faChevronRight,
        faCloudDownloadAlt,
        faCogs,
        faCreditCard,
        faCross,
        faDesktop,
        faDownload,
        faEdit,
        faExclamationCircle,
        faEye,
        faFileAlt,
        faFlagCheckered,
        faInfo,
        faLink,
        faLock,
        faMinus,
        faPersonBooth,
        faPhotoVideo,
        faPlay,
        faPlus,
        faPoundSign,
        faRuler,
        faSave,
        faSchool,
        faSearch,
        faSpinner,
        faStop,
        faStopwatch,
        faSync,
        faTag,
        faThumbsDown,
        faThumbsUp,
        faTimes,
        faTimesCircle,
        faTrash,
        faTrashAlt,
        faTrashRestore,
        faUndo,
        faUser,
        faUserCheck,
        faUserClock,
        faUserGraduate,
        faUserTag,
        faUsers,
        faVoteYea,

        // regular
        farCalendarAlt,
        farCalendarTimes,
        farCircle,
        farClock,
        farFrown,
        farFrownOpen,
        farListAlt,
        faPhone,
        faEnvelope,
    );
};
