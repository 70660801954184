import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { editCalendarHeadlineViewModelQuery, editCalendarHeadlineViewModelQueryVariables } from '../../generated/editCalendarHeadlineViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { calendarHeadlineFieldsFragment } from '../../models/CalendarHeadline';
import { blobReferenceFieldsFragment } from '../../models/BlobReference';
import { Guid } from 'guid-string';

/**
 * View model for EditCalendarHeadline that replaces the call to useCalendarHeadline() and loads the right related data.
 * @param options
 */
export function useEditCalendarHeadlineViewModel(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<editCalendarHeadlineViewModelQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<editCalendarHeadlineViewModelQuery, editCalendarHeadlineViewModelQueryVariables>(
        gql`
        query editCalendarHeadlineViewModelQuery ($id: ID!) {
            model: calendarHeadline(id: $id) {
                ...calendarHeadlineFields

                imageBlobReference {
                    ...blobReferenceFields
                }
            }
        }

        ${calendarHeadlineFieldsFragment}
        ${blobReferenceFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}