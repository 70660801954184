import gql from "graphql-tag";
import { calendarEventScheduleFields } from "../generated/calendarEventScheduleFields";

export const calendarEventScheduleFieldsFragment = gql`
    fragment calendarEventScheduleFields on CalendarEventSchedule {
        id
        calendarEventId
        start
        end
        isAllDay
        recurringType
        recurringInterval
        recurringOffset
        archived
        createdByUserId
        createdDate
        recurrenceOfCalendarEventScheduleId
        subscriptionSequenceNumber
    }
`;


export type CalendarEventSchedule = Omit<calendarEventScheduleFields, '__typename'>;
