import gql from "graphql-tag";
import { cmsImageFields } from "../generated/cmsImageFields";

export const cmsImageFieldsFragment = gql`
    fragment cmsImageFields on CmsImage {
        id
        name
        blobReferenceId
        archived
        createdByUserId
        createdDate
    }
`;


export type CmsImage = Omit<cmsImageFields, '__typename'>;
