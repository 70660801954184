import './calendarMonth.scss';

import * as React from 'react';
import moment, { Moment } from 'moment';
import { CalendarWeek } from './CalendarWeek';
import { CalendarWeekdayNames } from './CalendarWeekdayNames';
import { CalendarHomeScheduleViewModel } from '../../calendar/CalendarHomeScheduleViewModel';

export interface CalendarMonthProps {
    date: Moment,
    schedules?: Array<CalendarHomeScheduleViewModel>,
    onEventClick?: (schedule: { id: string, calendarEventId: string }) => void,
}

/**
 * Component showing a calendar for a month (always shown in full weeks to emulate a paper calendar).
 */
export const CalendarMonth = (props: CalendarMonthProps) => {
    const { date, schedules, onEventClick } = props;

    // Work out the start and end date we are to show for the month (may extend before and after the calendar month to make a full week).
    const { startDate, endDate } = React.useMemo(() => {
        const monthStart = moment(date).startOf('month');
        const monthEnd = moment(date).endOf('month');

        // Find the start of the week (based on a Monday week start).
        const startDate = moment(monthStart).startOf('week');

        // And the end of the week.
        const endDate = moment(monthEnd).endOf('week');

        return {
            monthStart,
            monthEnd,
            startDate,
            endDate,
        };
    }, [date]);

    // Render all weeks to show for the calendar.
    const renderWeeks = React.useCallback(() => {
        let ret: Array<React.ReactNode> = [];

        for (let day = moment(startDate); day <= endDate; day = moment(day).add(1, 'week')) {
            ret.push((
                <CalendarWeek key={day.toISOString()} date={day} schedules={schedules} onEventClick={onEventClick} />
            ));
        }

        return ret;
    }, [startDate, endDate, schedules, onEventClick]);


    return (
        <div className="calendar-month">
            <CalendarWeekdayNames date={startDate} />
            <div className="calendar-month-days">
                {renderWeeks()}
            </div>
        </div>
        );
};