import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { TagsList } from "./TagsList";
import { EditTag, CreateTag } from "./EditTag";
import { DeleteTag } from "./DeleteTag";

export const tagRoutes: Array<RouteEntry> = [
    { path: '/administration/keywords', exact: true, component: TagsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/keywords/edit/:id', component: EditTag, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/keywords/add', component: CreateTag, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/keywords/delete/:id', component: DeleteTag, authorize: true, requireRole: IdentityRoles.Administration },
];
