import * as React from 'react';
import { CalendarHeadlineCreateInput, CalendarHeadlineUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateCalendarHeadlineCallback } from './useCreateCalendarHeadlineCallback';
import { useUpdateCalendarHeadlineCallback } from './useUpdateCalendarHeadlineCallback';
import { CalendarHeadline } from '../models/CalendarHeadline';

/**
 * Get a callback to save a CalendarHeadline in the store using either a create or update.
 */
export function useSaveCalendarHeadlineCallback(options: SaveInStoreOptions<CalendarHeadline, string> = {}) {
    const [_create, createStatus] = useCreateCalendarHeadlineCallback();
    const create = React.useCallback((model: Partial<CalendarHeadline>) => _create(model as CalendarHeadlineCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateCalendarHeadlineCallback();
    const update = React.useCallback((id: string, changes: Partial<CalendarHeadline>) => _update(id, changes as CalendarHeadlineUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
