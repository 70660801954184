import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadPagedResult, AsyncLoadPagedOptions } from '../../../shared/abstractStore';
import { useFetchMoreCallback } from '../../../shared/useFetchMoreCallback';
import { profilesQuery, profilesQueryVariables } from '../generated/profilesQuery';
import { mainApiConfig } from '../../../configure/mainApiConfig';

/**
 * Get a list of profiles from the store.
 * @param id
 * @param options
 */
export function useProfiles(options: AsyncLoadPagedOptions = {}): AsyncLoadPagedResult<profilesQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<profilesQuery, profilesQueryVariables>(
        gql`
        query profilesQuery ($offset: Int, $first: Int) {
            items: profiles (offset: $offset, first: $first) {
                id
                firstName
                lastName
                organisationName
                user {
                  id
                  email
                  roleGroup {
                    id
                    name
                  }
                }
            }
        }
        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<profilesQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])]
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);
    
    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}
