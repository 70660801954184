import './calendarWeekdayNames.scss';

import * as React from 'react';
import moment, { Moment } from 'moment';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export interface CalendarWeekdayNamesProps {
    date: Moment,
}

/**
 * Component showing a weekday names aligned with the display of CalendarWeek (often shown on-top of it).
 */
export const CalendarWeekdayNames = (props: CalendarWeekdayNamesProps) => {
    const { date } = props;

    const { t } = useTranslation();

    // Work out the start and end of the week containing the passed in date.
    const { startDate, endDate } = React.useMemo(() => {
        // Find the start of the week (based on a Monday week start).
        const startDate = moment(date).startOf('week');
        // And the end of the week.
        const endDate = moment(date).endOf('week');

        return {
            startDate,
            endDate,
        };
    }, [date]);

    // Render all days to show for the calendar.
    const renderDays = React.useCallback((options: { includeWeekdays: boolean, includeWeekend: boolean }) => {
        let ret: Array<React.ReactNode> = [];

        for (let day = moment(startDate); day <= endDate; day = moment(day).add(1, 'day')) {
            // Special logic to hide the names of weekends on a seperate row on xs screens so the days don't get too compact (as they
            // are shown on a seperate row inside CalendarWeek.
            const dayOfWeek = day.format('E');
            const isWeekend = dayOfWeek === '6' /* Saturday */ || dayOfWeek === '7' /* Sunday */ ? true : false;

            if (isWeekend) {
                if (!options.includeWeekend) {
                    continue;
                }
            } else {
                if (!options.includeWeekdays) {
                    continue;
                }
            }

            ret.push((
                <Col key={day.toISOString()}>
                    <div className="calendar-weekday-names-day">
                        {t('common.dates.weekday', '{{date, dddd}}', { date: day })}
                    </div>
                </Col>
            ));
        }

        return ret;
    }, [startDate, endDate, t]);


    return (
        <Row className="calendar-weekday-names" noGutters>
            {renderDays({ includeWeekdays: true, includeWeekend: true, })}
        </Row>
        );
};