import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { editCalendarEventViewModelQuery, editCalendarEventViewModelQueryVariables } from '../../generated/editCalendarEventViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { calendarEventFieldsFragment } from '../../models/CalendarEvent';
import { blobReferenceFieldsFragment } from '../../models/BlobReference';
import { Guid } from 'guid-string';
import { calendarEventUrlFieldsFragment } from '../../models/CalendarEventUrl';
import { calendarEventCalendarEventProviderFieldsFragment } from '../../models/CalendarEventCalendarEventProvider';
import { calendarEventScheduleFieldsFragment } from '../../models/CalendarEventSchedule';
import { itemTagLinkFieldsFragment } from '../../models/ItemTagLink';

/**
 * View model for EditCalendarEvent that replaces the call to useCalendarEvent() and loads the right related data.
 * @param options
 */
export function useEditCalendarEventViewModel(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<editCalendarEventViewModelQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<editCalendarEventViewModelQuery, editCalendarEventViewModelQueryVariables>(
        gql`
        query editCalendarEventViewModelQuery ($id: ID!) {
            model: calendarEvent(id: $id) {
                ...calendarEventFields

                imageBlobReference {
                    ...blobReferenceFields
                }

                urls {
                    ...calendarEventUrlFields
                }

                providers {
                    ...calendarEventCalendarEventProviderFields
                }

                schedules {
                    ...calendarEventScheduleFields
                }

                tagLinks {
                    ...itemTagLinkFields
                }
            }
        }

        ${calendarEventFieldsFragment}
        ${blobReferenceFieldsFragment}
        ${calendarEventUrlFieldsFragment}
        ${calendarEventCalendarEventProviderFieldsFragment}
        ${calendarEventScheduleFieldsFragment}
        ${itemTagLinkFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}