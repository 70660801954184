import * as React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { CalendarEventType } from '../../api/main/models/CalendarEventType';
import { EventTypeColorBlock } from '../shared/calendar/EventTypeColorBlock';

import './eventTypeSelector.scss';

export interface EventTypeSelectorProps {
    items: Array<CalendarEventType>,
    isSelected: (id: string) => boolean,
    toggle: (id: string) => void,
}

/**
 * Component that lets the user select event types on and off, modeled after TagSelector to provider a very similar API to the consuming code.
 * @param props
 */
export const EventTypeSelector = (props: EventTypeSelectorProps) => {
    const {
        items,
        isSelected,
        toggle,
    } = props;

    return (
        <div className="event-type-selector">
            {
                items.map(item => (
                    <Button key={item.id} size="sm" color="secondary" outline={!isSelected(item.id)} className="event-type-selector-item" onClick={() => toggle(item.id)}>
                        <Row noGutters>
                            <Col xs="auto">
                                <EventTypeColorBlock color={item.color} />
                            </Col>
                            <Col>
                                {item.name}
                            </Col>
                        </Row>
                    </Button>
                    ))
            }
        </div>
    );
};