import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CalendarEventCalendarEventProviderUpdateInput } from '../generated/globalTypes';
import { updateCalendarEventCalendarEventProviderMutation, updateCalendarEventCalendarEventProviderMutationVariables } from '../generated/updateCalendarEventCalendarEventProviderMutation';
import { calendarEventCalendarEventProviderFieldsFragment } from '../models/CalendarEventCalendarEventProvider';

/**
 * Get a callback to update a CalendarEventCalendarEventProvider in the store.
 */
export function useUpdateCalendarEventCalendarEventProviderCallback(): [(id: string, model: CalendarEventCalendarEventProviderUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<updateCalendarEventCalendarEventProviderMutation, updateCalendarEventCalendarEventProviderMutationVariables>(
        gql`
            mutation updateCalendarEventCalendarEventProviderMutation ($model: CalendarEventCalendarEventProviderUpdateInput!) {
                updateCalendarEventCalendarEventProvider(model: $model) {
                    ...calendarEventCalendarEventProviderFields
                }
            }

            ${calendarEventCalendarEventProviderFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: CalendarEventCalendarEventProviderUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
