import { RouteEntry } from "../../../shared/routes";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { CmsImagesList } from "./CmsImagesList";
import { EditCmsImage, CreateCmsImage } from "./EditCmsImage";
import { DeleteCmsImage } from "./DeleteCmsImage";

export const cmsImageRoutes: Array<RouteEntry> = [
    { path: '/administration/cms/images', exact: true, component: CmsImagesList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/cms/images/edit/:id', component: EditCmsImage, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/cms/images/add', component: CreateCmsImage, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/cms/images/delete/:id', component: DeleteCmsImage, authorize: true, requireRole: IdentityRoles.Administration },
];
