import './loginPartialPage.scss';
import * as React from 'react';
import { useLoginPartialPageViewModel } from '../../api/main/cmsPages/viewModels/useLoginPartialPageViewModel';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { CmsTextHtmlDisplay } from '../cms/cmsTexts/CmsTextHtmlDisplay';
import { CmsVideoDisplay } from '../cms/cmsVideos/CmsVideoDisplay';
import { Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

/**
 * Cms based partial page shown on the Login and other Account pages. 
 */
export const LoginPartialPage = () => {
    const { t } = useTranslation();

    const { data: { video, text }, errors: loadErrors } = useLoginPartialPageViewModel();

    return (
        <div className="login-partial-page">
            <Card body className="login-partial-page-card">
                <h2>
                    {t('loginPartialPage.heading', 'What is TheSchoolYear?')}
                </h2>

                <AlertOnErrors errors={loadErrors} />

                <CmsVideoDisplay model={video} />
                <CmsTextHtmlDisplay model={text} />

                <p>
                    <Link to="/about">
                        {t('loginPartialPage.findOutMore', 'Find out more')}
                    </Link>
                </p>
            </Card>
        </div>
    );
};