import './calendarWeek.scss';

import * as React from 'react';
import moment, { Moment } from 'moment';
import { Row, Col } from 'reactstrap';
import { CalendarDay } from './CalendarDay';
import { CalendarHomeScheduleViewModel } from '../../calendar/CalendarHomeScheduleViewModel';

export interface CalendarWeekProps {
    date: Moment,
    schedules?: Array<CalendarHomeScheduleViewModel>,
    onEventClick?: (schedule: { id: string, calendarEventId: string }) => void,
}

/**
 * Component showing a week in a calendar.
 */
export const CalendarWeek = (props: CalendarWeekProps) => {
    const { date, schedules, onEventClick } = props;

    // Work out the start and end of the week containing the passed in date.
    const { startDate, endDate } = React.useMemo(() => {
        // Find the start of the week (based on a Monday week start).
        const startDate = moment(date).startOf('week');
        // And the end of the week.
        const endDate = moment(date).endOf('week');

        return {
            startDate,
            endDate,
        };
    }, [date]);

    // Render all days to show for the calendar.
    const renderDays = React.useCallback((options: { includeWeekdays: boolean, includeWeekend: boolean }) => {
        let ret: Array<React.ReactNode> = [];

        for (let day = moment(startDate); day <= endDate; day = moment(day).add(1, 'day')) {
            // Special logic to put weekends on a seperate row on xs screens so the days don't get too compact.
            const dayOfWeek = day.format('E');
            const isWeekend = dayOfWeek === '6' /* Saturday */ || dayOfWeek === '7' /* Sunday */ ? true : false;

            if (isWeekend) {
                if (!options.includeWeekend) {
                    continue;
                }
            } else {
                if (!options.includeWeekdays) {
                    continue;
                }
            }

            ret.push((
                <Col key={day.toISOString()} xs={12} sm="">
                    <CalendarDay date={day} schedules={schedules} onEventClick={onEventClick} />
                </Col>
            ));
        }

        return ret;
    }, [startDate, endDate, schedules, onEventClick]);


    return (
        <div className="calendar-week">
            <Row noGutters className="calendar-week-all-days">
                {renderDays({ includeWeekdays: true, includeWeekend: true, })}
            </Row>
        </div>
        );
};