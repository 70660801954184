import { RouteEntry } from "../../shared/routes";
import { AdministratorsUsersList } from './AdministratorsUserList';
import { EditUser, CreateUser } from "./EditUser";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { DeleteUser } from "./DeleteUser";
import { RegisteredUsersList } from "./RegisteredUsersList";

export const userRoutes: Array<RouteEntry> = [
    { path: '/administration/users/administrators', exact: true, component: AdministratorsUsersList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/users/administrators/edit/:id', component: EditUser, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/users/administrators/add', component: CreateUser, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/users/administrators/delete/:id', component: DeleteUser, authorize: true, requireRole: IdentityRoles.Administration },


    { path: '/administration/users/registered', exact: true, component: RegisteredUsersList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/users/registered/edit/:id', component: EditUser, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/users/registered/add', component: CreateUser, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/users/registered/delete/:id', component: DeleteUser, authorize: true, requireRole: IdentityRoles.Administration },
];
