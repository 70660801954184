import gql from "graphql-tag";
import { calendarEventTypeFields } from "../generated/calendarEventTypeFields";

export const calendarEventTypeFieldsFragment = gql`
    fragment calendarEventTypeFields on CalendarEventType {
        id
        name
        color
        archived
        displayOrder
        createdByUserId
        createdDate
    }
`;


export type CalendarEventType = Omit<calendarEventTypeFields, '__typename'>;
