import * as React from "react";
import { Form, Alert, FormGroup, Label, CustomInput, Spinner, Row, Col, Button } from "reactstrap";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Link, useHistory } from "react-router-dom";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync"
import { LoadingIndicator } from "../../components/shared/LoadingIndicator";
import { useExternalAuthenticationSchemes, useLoginWithPasswordCallback, useStartExternalLoginCallback, useResendConfirmationEmailCallback } from "../../api/account";
import { Login as LoginModel } from '../../api/account/models/Login';
import { useTranslation } from "react-i18next";
import { ExternalLoginButton } from "./ExternalLoginButton";
import { FormButtons } from "../shared/FormButtons";
import { SideContainer } from "../shared/sideContainer/SideContainer";
import { AccountBackground } from "./AccountBackground";
import { LinkContainer } from "react-router-bootstrap";
import { LoginPartialPage } from "../cmsPages/LoginPartialPage";

/**
 * Login screen.
 */
export const Login = () => {
    const { t } = useTranslation();
    const { data: { externalAuthenticationSchemes }, isLoading, errors: loadErrors } = useExternalAuthenticationSchemes();
    const [login, { isExecuting: isLoggingIn, errors: loginErrors }] = useLoginWithPasswordCallback();
    const [startExternalLogin] = useStartExternalLoginCallback();
    const [isPendingEmailConfirmation, setIsPendingEmailConfirmation] = React.useState<boolean>(false);
    const history = useHistory();

    const [resendConfirmationEmail, { isExecuting: isResendingConfirmationEmail, errors: resendConfirmationEmailErrors }] = useResendConfirmationEmailCallback();
    const [hasSentConfirmationEmail, setHasSentConfirmationEmail] = React.useState<boolean>(false);

    const { model, change } = useChanges<LoginModel>({ email: '', password: '', rememberMe: true });

    // Need the URL to pass on to registration or 2faif we redirect to it.
    const params = new URLSearchParams(window.location.search);
    const returnUrl = params.get('returnUrl') ?? params.get('ReturnUrl') ?? '';

    /**
     * Validate the model before trying to use it.
     */
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            email: () => !model.email ? t('login.emailRequired', 'Email is required') : '',
            password: () => !model.password ? t('login.passwordRequired', 'Password is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    /**
     * Perform a login by and handle the result.
     */
    const [isDoingFullPageRedirect, setIsDoingFullPageRedirect] = React.useState<boolean>(false);
    const performLogin = React.useCallback(async (): Promise<void> => {
        if (!validate()) {
            return;
        }

        let result = await login(model);

        if (result) {
            setIsPendingEmailConfirmation(result.requiresEmailConfirmation);

            if (result.requiresTwoFactor) {
                history.push(`/account/loginWithTwoFactor?returnUrl=${encodeURIComponent(result.returnUrl)}`);
            }

            // Redirect the whole page (not just the react app) as its likely the returnUrl is handled on the server.
            if (result.succeeded) {
                // Redirect the whole page (not just react) to the returnUrl to let the server handle as well as the client.
                if (!result.requiresEmailConfirmation && !result.requiresTwoFactor) {
                    setIsDoingFullPageRedirect(true);
                    window.location.href = result.returnUrl;
                }
            }
        }
    }, [login, model, setIsPendingEmailConfirmation, history, validate, setIsDoingFullPageRedirect]);

    // Render the UI.
    return (
        <AccountBackground centerChildren="vertically">
            <Row noGutters>
                <Col xs={12} md={6} xl={5}>
                    <SideContainer fluid>
                        <h1>
                            {t('login.signInHeading', 'Sign in')}
                        </h1>
                        <Form onSubmit={async e => { e.preventDefault(); await performLogin(); }}>
                            <AlertOnErrors simple errors={[loadErrors, loginErrors, resendConfirmationEmailErrors]} />
                            {
                                isPendingEmailConfirmation ?
                                    hasSentConfirmationEmail ? (
                                        <Alert color="success" >
                                            <>{t('login.confirmationEmailHasBeenResent', 'Confirmation link to verify the email for this account has been resent.  Please check your email to confirm.')} </>
                                            <ButtonAsync type="button" color="success" onClick={async e => { e.preventDefault(); await resendConfirmationEmail(model.email); setHasSentConfirmationEmail(true); }}
                                                isExecuting={isResendingConfirmationEmail}
                                                executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
                                                {t('common.resendEmail', 'Resend email')}
                                            </ButtonAsync>
                                        </Alert>
                                    ) : (
                                            <Alert color="success">
                                                <>{t('login.mustConfirmEmailBeforeLogin', 'You need to confirm your account before you can sign in.  Please check your email.')} </>
                                                <ButtonAsync type="button" color="success" onClick={async e => { e.preventDefault(); await resendConfirmationEmail(model.email); setHasSentConfirmationEmail(true); }}
                                                    isExecuting={isResendingConfirmationEmail}
                                                    executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
                                                    {t('common.resendEmail', 'Resend email')}
                                                </ButtonAsync>
                                        </Alert>
                                ) : null
                            }
                            <FormGroup>
                                <Label htmlFor="email">{t('login.email', 'Email')}</Label>
                                <ValidatedInput type="email" name="email" autoComplete="username" value={model.email} onChange={e => change({ email: e.currentTarget.value })} onBlur={e => validate('email')} validationErrors={validationErrors['email']} />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="password">{t('login.password', 'Password')}</Label>
                                <ValidatedInput type="password" name="password" autoComplete="current-password" value={model.password} onChange={e => change({ password: e.currentTarget.value })} onBlur={e => validate('password')} validationErrors={validationErrors['password']} />
                            </FormGroup>
                            <FormGroup>
                                <CustomInput id="rememberMe" name="rememberMe" type="switch" label={t('login.rememberMe', 'Remember me on this computer?')} checked={model.rememberMe} onChange={e => change({ rememberMe: e.currentTarget.checked })} />
                            </FormGroup>

                            <FormButtons>
                                <Row>
                                    <Col>
                                        <Link to={'/account/forgotPassword'}>
                                            {t('login.forgotYourPassword', 'Forgotten your password?')}
                                        </Link>
                                    </Col>
                                    <Col xs="auto">
                                        <ButtonAsync type="submit" color="primary" isExecuting={isLoggingIn || isDoingFullPageRedirect}
                                            executingChildren={<><Spinner size="sm" /> {t('login.loggingIn', 'Signing in...')}</>}>
                                            {t('login.signIn', 'Sign in')}
                                        </ButtonAsync>
                                    </Col>
                                </Row>

                                {/*
                                <LinkContainer to={`/account/register?returnUrl=${encodeURIComponent(returnUrl)}`}>
                                    <Button type="button" color="primary" outline>
                                        {t('login.register', 'Register as a new user')}
                                    </Button>
                                </LinkContainer>*/}
                            </FormButtons>

                            <div>
                                {
                                    isLoading ? (
                                        <LoadingIndicator />
                                    ) : (
                                            <>
                                                <div>
                                                    {
                                                        !externalAuthenticationSchemes ? null
                                                            : externalAuthenticationSchemes.length !== 0 ? (
                                                                <>
                                                                    <h6>{t('login.useExternalService', 'Or sign in using an identity provider')}</h6>
                                                                    {
                                                                        externalAuthenticationSchemes.map((item) => (
                                                                            <ExternalLoginButton key={item.name} type="button" provider={item.name} providerDisplayName={item.displayName} onClick={() => startExternalLogin(item.name, returnUrl)} />
                                                                        ))
                                                                    }
                                                                </>
                                                            )
                                                                : null
                                                    }
                                                </div>
                                            </>
                                        )
                                }
                            </div>

                            <h2>
                                {t('login.registerHeading', 'Not registered yet?')}
                            </h2>
                            <p>
                                {t('login.registerText', 'Register for your free TheSchoolYear account and keep yourself and your calendar up to date with everything thats happening in education, all the time.')}
                            </p>
                            <div className="text-center">
                                <LinkContainer to="/account/register">
                                    <Button color="primary">
                                        {t('login.registerButton', 'Sign up now')}
                                    </Button>
                                </LinkContainer>
                                <> </>
                                {t('login.or', 'or')}
                                <> </>
                                <LinkContainer to="/about">
                                    <Button color="primary">
                                        {t('login.aboutButton', 'Find out more')}
                                    </Button>
                                </LinkContainer>
                            </div>
                        </Form>
                    </SideContainer>
                </Col>
                <Col>
                    <LoginPartialPage />
                </Col>
            </Row>
        </AccountBackground>
    );
};
