import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { CalendarEventTypesList } from "./CalendarEventTypesList";
import { EditCalendarEventType, CreateCalendarEventType } from "./EditCalendarEventType";
import { DeleteCalendarEventType } from "./DeleteCalendarEventType";

export const calendarEventTypeRoutes: Array<RouteEntry> = [
    { path: '/administration/event-types', exact: true, component: CalendarEventTypesList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/event-types/edit/:id', component: EditCalendarEventType, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/event-types/add', component: CreateCalendarEventType, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/event-types/delete/:id', component: DeleteCalendarEventType, authorize: true, requireRole: IdentityRoles.Administration },
];
