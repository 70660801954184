import * as React from 'react';
import { CalendarEventTypeCreateInput, CalendarEventTypeUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateCalendarEventTypeCallback } from './useCreateCalendarEventTypeCallback';
import { useUpdateCalendarEventTypeCallback } from './useUpdateCalendarEventTypeCallback';
import { CalendarEventType } from '../models/CalendarEventType';

/**
 * Get a callback to save a CalendarEventType in the store using either a create or update.
 */
export function useSaveCalendarEventTypeCallback(options: SaveInStoreOptions<CalendarEventType, string> = {}) {
    const [_create, createStatus] = useCreateCalendarEventTypeCallback();
    const create = React.useCallback((model: Partial<CalendarEventType>) => _create(model as CalendarEventTypeCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateCalendarEventTypeCallback();
    const update = React.useCallback((id: string, changes: Partial<CalendarEventType>) => _update(id, changes as CalendarEventTypeUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
