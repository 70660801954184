import * as React from 'react';
import { Button } from 'reactstrap';
import { ItemTag } from '../../api/main/models/ItemTag';

import './tagSelector.scss';

export interface TagSelectorProps {
    items: Array<ItemTag>,
    isSelected: (id: string) => boolean,
    toggle: (id: string) => void,
    color?: 'primary' | 'secondary',
}

/**
 * Component that lets the user select tags on and off.
 * @param props
 */
export const TagSelector = (props: TagSelectorProps) => {
    const {
        items,
        isSelected,
        toggle,
        color = 'secondary',
    } = props;

    return (
        <div className="tag-selector">
            {
                items.map(item => (
                    <Button key={item.id} size="sm" color={color} outline={!isSelected(item.id)} className="tag-selector-item" onClick={() => toggle(item.id)}>
                        {item.name}
                    </Button>
                    ))
            }
        </div>
    );
};