import * as React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLinkTrackActive } from '../shared/NavLinkTrackActive';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { SidebarNavTitle } from '../../shared/sidebarNav';
import { SidebarRequired } from '../shared/SidebarRequired';

/**
 * Administration navigation menu for the app. 
 */
export const AdministrationNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <SidebarRequired />
            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <SidebarNavTitle>
                    {t('administrationNavigation.calendarTitle', 'Calendar management')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/events">
                        <FontAwesomeIcon icon={['far', 'clock']} className="nav-icon" />
                        <> {t('administrationNavigation.events', 'Events')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/headlines">
                        <FontAwesomeIcon icon="thumbs-up" className="nav-icon" />
                        <> {t('administrationNavigation.headlines', 'Headlines')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/providers">
                        <FontAwesomeIcon icon="exclamation-circle" className="nav-icon" />
                        <> {t('administrationNavigation.providers', 'Providers')}</>
                    </NavLink>
                </NavItem>

                <SidebarNavTitle>
                    {t('administrationNavigation.tagsTitle', 'Analytics')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/analytics/calendar-event-opens">
                        <FontAwesomeIcon icon={['far', 'clock']} className="nav-icon" />
                        <> {t('administrationNavigation.calendarEventOpens', 'Events opened')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/analytics/link-clicks">
                        <FontAwesomeIcon icon="link" className="nav-icon" />
                        <> {t('administrationNavigation.linkClicks', 'Link clicks')}</>
                    </NavLink>
                </NavItem>

                <SidebarNavTitle>
                    {t('administrationNavigation.tagsTitle', 'Tag management')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/event-types">
                        <FontAwesomeIcon icon="calendar-check" className="nav-icon" />
                        <> {t('administrationNavigation.eventTypes', 'Event types')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/school-types">
                        <FontAwesomeIcon icon="archway" className="nav-icon" />
                        <> {t('administrationNavigation.schoolTypes', 'School types')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/school-phases">
                        <FontAwesomeIcon icon="user-tag" className="nav-icon" />
                        <> {t('administrationNavigation.schoolPhases', 'School phases')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/keywords">
                        <FontAwesomeIcon icon="tag" className="nav-icon" />
                        <> {t('administrationNavigation.keywords', 'Keywords')}</>
                    </NavLink>
                </NavItem>

                <SidebarNavTitle>
                    {t('administrationNavigation.cmsTitle', 'CMS-like features')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/cms/texts">
                        <FontAwesomeIcon icon="file-alt" className="nav-icon" />
                        <> {t('administrationNavigation.cmsTexts', 'CMS text')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/cms/videos">
                        <FontAwesomeIcon icon="file-alt" className="nav-icon" />
                        <> {t('administrationNavigation.cmsVideos', 'CMS videos')}</>
                    </NavLink>
                </NavItem>
                {/*<NavItem>*/}
                {/*    <NavLink tag={NavLinkTrackActive} to="/administration/cms/images">*/}
                {/*        <FontAwesomeIcon icon="file-alt" className="nav-icon" />*/}
                {/*        <> {t('administrationNavigation.cmsImages', 'CMS images')}</>*/}
                {/*    </NavLink>*/}
                {/*</NavItem>*/}

                <SidebarNavTitle>
                    {t('administrationNavigation.usersTitle', 'User management')}
                </SidebarNavTitle>

                <NavItem>
                    <NavLink to="/administration/users/registered" tag={NavLinkTrackActive}>
                        <FontAwesomeIcon icon="user-graduate" className="nav-icon" />
                        <> {t('administrationNavigation.registerdUsers', 'Registered users')}</>
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink to="/administration/users/administrators" tag={NavLinkTrackActive}>
                        <FontAwesomeIcon icon="users" className="nav-icon" />
                        <> {t('administrationNavigation.administrators', 'Administrators')}</>
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
