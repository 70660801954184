import './sideContainer.scss'; 

import * as React from 'react';
import { Container, ContainerProps } from 'reactstrap';

export interface SideContainerProps extends ContainerProps {
    side?: 'left' | 'right',
    color?: 'dark' | 'transparent',
    className?: string,
}

/**
 * Container for items you want to show on a dark panel that appears to be snapped to the side of the screen.
 */
export const SideContainer = (props: SideContainerProps) => {
    const {
        side = 'left',
        color = 'dark',
        className,
        ...rest
    } = props;

    return (
        <Container className={`side-container side-container-${side} side-container-${color} ${className ?? ''}`} {...rest} />
    );
};