import gql from "graphql-tag";
import { itemTagLinkFields } from "../generated/itemTagLinkFields";

export const itemTagLinkFieldsFragment = gql`
    fragment itemTagLinkFields on ItemTagLink {
        id
        itemTagId
        targetId
        createdByUserId
        createdDate
    }
`;


export type ItemTagLink = Omit<itemTagLinkFields, '__typename'>;
