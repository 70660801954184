import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { ValidationErrors } from 'pojo-validator';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import * as React from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useTranslation } from 'react-i18next';
import { Button, Col, FormGroup, ListGroupItem, Row, } from 'reactstrap';
import { CalendarEventCalendarEventProvider } from '../../api/main/models/CalendarEventCalendarEventProvider';
import { CalendarEventProvider } from '../../api/main/models/CalendarEventProvider';

export interface ProviderLinkProps {
    model: CalendarEventCalendarEventProvider,
    change: (changes: Partial<CalendarEventCalendarEventProvider>) => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    remove: () => void,
    providers: Array<CalendarEventProvider>,
    singleItem?: boolean,
}

/**
 * Component for editing a provider attached to an event as a CalenderEventCalendarEventProvider.
 * @param props
 */
export const ProviderLink = (props: ProviderLinkProps) => {
    const {
        model, change,
        validate, validationErrors,
        remove,
        providers,
        singleItem = false,
    } = props;

    const { t } = useTranslation();

    return (
        <ListGroupItem style={singleItem ? { paddingLeft: '0px', paddingRight: '0px', paddingTop: '0px', paddingBottom: '0px', borderTopStyle: 'none', borderBottomStyle: 'none' } : undefined}>
            <Row>
                <Col>
                    <FormGroup>
                        <ValidatedInput placeholder={t('providerLink.name.placeholder', 'Link text')} type="select" value={model?.calendarEventProviderId ?? ''} onChange={e => change({ calendarEventProviderId: e.currentTarget.value })} onBlur={e => validate('calendarEventProviderId')} validationErrors={validationErrors['calendarEventProviderId']}>
                            <option value="">{t('providerLink.calendarEventProviderId.pleaseSelect', '(Please select the provider)')}</option>
                            {
                                providers?.map(item => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))
                            }
                        </ValidatedInput>
                    </FormGroup>
                </Col>
                <ConditionalFragment showIf={!singleItem}>
                    <Col xs="auto">
                        <FormGroup>
                            <div>
                                <Button color="danger" outline onClick={() => remove()}>
                                    <FontAwesomeIcon icon="trash" />
                                    <> </>
                                    <span className="sr-only">{t('common.delete', 'Delete')}</span>
                                </Button>
                            </div>
                        </FormGroup>
                    </Col>
                </ConditionalFragment>
            </Row>
        </ListGroupItem>
        );
}