import { CalendarEventSchedule } from '../api/main/models/CalendarEventSchedule';
import i18next from "i18next";
import moment from 'moment';

/**
 * Return a localized description of a schedule.
 */
export function scheduleDescription(schedule: CalendarEventSchedule, options?: { timeOnly?: boolean }): string {
    const formatDate = (date: string) => {
        if (options?.timeOnly) {
            // Only interested in the time element.
            if (schedule.isAllDay) {
                return i18next.t('scheduleDescription.allDay', 'All day');
            } else {
                return i18next.t('common.datetime', '{{date, HH:mm}}', { date: moment(date) });
            }
        } else {
            // Include date and time if required.
            if (schedule.isAllDay) {
                return i18next.t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(date) });
            } else {
                return i18next.t('common.datetime', '{{date, DD/MM/YYYY HH:mm}}', { date: moment(date) });
            }
        }
    }

    // If the start and end date are the same then return only the one date, otherwise return as a range.
    if (schedule.start === schedule.end) {
        return formatDate(schedule.start);
    }

    return i18next.t('scheduleDescription.dateRange', '{{start}} to {{end}}', { start: formatDate(schedule.start), end: formatDate(schedule.end), });
}