import { RouteEntry } from "../../shared/routes";
import { AboutPage } from "./AboutPage";
import { PrivacyPolicyPage } from "./PrivacyPolicyPage";
import { TermsAndConditionsPage } from "./TermsAndConditionsPage";

export const cmsPagesRoutes: Array<RouteEntry> = [
    { path: '/about', exact: true, component: AboutPage, authorize: false, },
    { path: '/privacy-policy', exact: true, component: PrivacyPolicyPage, authorize: false, },
    { path: '/terms-and-conditions', exact: true, component: TermsAndConditionsPage, authorize: false, },
];
