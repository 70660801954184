import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { aboutPageViewModelQuery } from '../../generated/aboutPageViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { cmsVideoFieldsFragment } from '../../models/CmsVideo';
import { blobReferenceFieldsFragment } from '../../models/BlobReference';
import { cmsTextFieldsFragment } from '../../models/CmsText';

/**
 * View model for LoginPartialPage that loads all cms data needed for the page at once.
 * @param options
 */
export function useAboutPageViewModel(options: AsyncLoadOptions = {}): AsyncLoadResult<aboutPageViewModelQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<aboutPageViewModelQuery>(
        gql`
        query aboutPageViewModelQuery {
            video: cmsVideo(name: "About.Video") {
                ...cmsVideoFields

                videoBlobReference {
                    ...blobReferenceFields
                }

                thumbnailBlobReference {
                    ...blobReferenceFields
                }
            }

            section1: cmsText(name: "About.Section1") {
                ...cmsTextFields
            }

            section2: cmsText(name: "About.Section2") {
                ...cmsTextFields
            }

            section3: cmsText(name: "About.Section3") {
                ...cmsTextFields
            }

            section4: cmsText(name: "About.Section4") {
                ...cmsTextFields
            }
        }

        ${cmsVideoFieldsFragment}
        ${blobReferenceFieldsFragment}
        ${cmsTextFieldsFragment}
        `,
        {
            variables: {
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}