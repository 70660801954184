import * as React from 'react';
import { CmsTextCreateInput, CmsTextUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateCmsTextCallback } from './useCreateCmsTextCallback';
import { useUpdateCmsTextCallback } from './useUpdateCmsTextCallback';
import { CmsText } from '../models/CmsText';

/**
 * Get a callback to save a CmsText in the store using either a create or update.
 */
export function useSaveCmsTextCallback(options: SaveInStoreOptions<CmsText, string> = {}) {
    const [_create, createStatus] = useCreateCmsTextCallback();
    const create = React.useCallback((model: Partial<CmsText>) => _create(model as CmsTextCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateCmsTextCallback();
    const update = React.useCallback((id: string, changes: Partial<CmsText>) => _update(id, changes as CmsTextUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
