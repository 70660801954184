import * as React from 'react';
import { Table, Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useProfiles } from '../../api/main/profiles/useProfiles';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { TableRowButtons } from '../shared/TableRowButtons';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useHistory } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { useToggleStateArray } from 'use-toggle-state';


/**
 * List of administrtors users.
 */
export const AdministratorsUsersList = () => {
    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');
    const { data: { items: allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useProfiles({ pageSize: undefined });
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const history = useHistory();

    // Filter by the user's search client side so it can work when offline as well as online.
    const items = React.useMemo(() => {
         // Only want to show administrators.
        let ret = (allItems ?? [])
            .filter(item => item.user?.roleGroup?.id === 'Administrator');

        if (!allItems || !search) {
            return ret;
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return ret.filter(item =>
            item.firstName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.lastName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.user.email.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || (item.user?.roleGroup?.name?.toLocaleLowerCase()?.indexOf(lowerSearch) ?? -1) >= 0
            || `${item.firstName} ${item.lastName}`.toLocaleLowerCase().indexOf(lowerSearch) >= 0 // Full name in languages "first last"
            || `${item.lastName} ${item.firstName}`.toLocaleLowerCase().indexOf(lowerSearch) >= 0 // Full name in languages "last first"
        );
    }, [allItems, search]);

    useReplaceSearchParamsEffect({ search: search });

    const colCount = 5;


    return (
        <>
            <Banner fluid>
                <Row>
                    <Col>
                        <h1>{t('administratorsUserList.heading', 'Administrators')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <LinkContainer to="/administration/users/administrators/add">
                                <Button color="primary">
                                    <FontAwesomeIcon icon="plus" /><> {t('administratorsUserList.add', 'Add')}</>
                                </Button>
                            </LinkContainer>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th className="d-table-cell d-lg-none">{t('administratorsUserList.user', 'User')}</th>
                            <th className="d-none d-lg-table-cell">{t('administratorsUserList.firstName', 'First name')}</th>
                            <th className="d-none d-lg-table-cell">{t('administratorsUserList.lastName', 'Last name')}</th>
                            <th className="d-none d-lg-table-cell">{t('administratorsUserList.email', 'Email')}</th>
                            <th className="d-none d-sm-table-cell">{t('administratorsUserList.securityGroup', 'Security group')}</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items?.map(item => (
                                <tr key={item.id} onDoubleClick={e => history.push(`/administration/users/administrators/edit/${item.id}`)}>
                                    <td className="d-table-cell d-lg-none">
                                        <div>{item.firstName} {item.lastName}</div>
                                        <div className="text-muted">{item.user.email}</div>
                                    </td>
                                    <td className="d-none d-lg-table-cell">{item.firstName}</td>
                                    <td className="d-none d-lg-table-cell">{item.lastName}</td>
                                    <td className="d-none d-lg-table-cell">{item.user.email}</td>
                                    <td className="d-none d-sm-table-cell">{item.user.roleGroup?.name ?? ''}</td>
                                    <td>
                                        <TableRowButtons>
                                            <ButtonGroup>
                                                <LinkContainer to={`/administration/users/administrators/edit/${item.id}`}>
                                                    <Button color="primary" outline>
                                                        <FontAwesomeIcon icon="edit" />
                                                        <> {t('common.edit', 'Edit')}</>
                                                    </Button>
                                                </LinkContainer>
                                                <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                                    <DropdownToggle color="primary" outline caret>
                                                        <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <LinkContainer to={`/administration/users/administrators/delete/${item.id}`}>
                                                            <DropdownItem className="text-danger">
                                                                <FontAwesomeIcon icon="trash" />
                                                                <> {t('common.delete', 'Delete')}</>
                                                            </DropdownItem>
                                                        </LinkContainer>
                                                    </DropdownMenu>
                                                </ButtonDropdown>
                                            </ButtonGroup>
                                        </TableRowButtons>
                                    </td>
                                </tr>
                            ))
                        }
                        <ConditionalFragment showIf={isLoading && !items?.length}>
                            <tr><td colSpan={colCount}><LoadingIndicator fullWidth /></td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && !items?.length}>
                            <tr><td colSpan={colCount}>
                                <NoResultsFound search={search} />
                            </td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && hasMore()}>
                            <tr><td colSpan={colCount}>
                                <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                                <LoadingIndicator fullWidth />
                            </td></tr>
                        </ConditionalFragment>
                    </tbody>
                </Table>
            </MainContainer>
        </>
    );
};
