import * as React from 'react';
import { Button, Row, Col, Form, Label, FormGroup, Spinner, FormText, Alert } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useProfile } from '../../api/main/profiles/useProfile';
import { useChanges } from '../../shared/useChanges';
import { useSaveProfileCallback } from '../../api/main/profiles/useSaveProfileCallback';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useInviteCallback, useChangeAccountEmailCallback, useResendInviteEmailCallback } from '../../api/account';
import { useAsyncCallback } from 'react-use-async-callback';
import { useChangeUserRoleGroupCallback } from '../../api/main/users/useChangeUserRoleGroupCallback';
import { Guid } from 'guid-string';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useProfileSupportingData } from '../../api/main/profiles/useProfileSupportingData';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { TwoValueSwitch } from '../shared/TwoValueSwitch';
import moment from 'moment';

interface EditUserProps {
    isCreate?: boolean,
}

/**
 * Create and invite a new user.
 */
export const CreateUser = () => (<EditUser isCreate={true} />);

/**
 * Edit a user (actually a profile).
 */
export const EditUser = (props: EditUserProps) => {
    const { isCreate } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const { data: { model: storeModel }, isLoading: _isLoading, errors: loadErrors } = useProfile(id);
    const { data: { roleGroups }, isLoading: isLoadingSupportingData, errors: loadSupportingDataErrors } = useProfileSupportingData();
    const isLoading = _isLoading || isLoadingSupportingData;
    const { model, change, changes } = useChanges(storeModel, isCreate ? { id: Guid.newGuid(), firstName: '', lastName: '', organisationName: '', organisationType: 'School', isSubscribedToDailyNewsLetter: false, isSubscribedToWeeklyNewsLetter: false, isIntrestedInWorkingTogether: false, urn: '', jobTitle: '', registrationSchoolPhasesJson: '', registeredDate: moment().toISOString() } : undefined);
    const { model: userModel, change: changeUserModel } = useChanges(storeModel?.user);
    const { model: roleGroupModel, change: changeRoleGroupModel } = useChanges<{ id: string }>({ id: storeModel?.user?.roleGroup?.id ?? '' }, isCreate? { id: 'Administrator'}: undefined);
    const [save, { errors: saveErrors }] = useSaveProfileCallback();
    const [invite, { errors: inviteErrors }] = useInviteCallback();
    const [resendInviteEmail, { isExecuting: isResendingInviteEmail, errors: resendInviteEmailErrors }] = useResendInviteEmailCallback();
    const [hasResentInviteEmail, setHasResentInviteEmail] = React.useState<boolean>(false);
    const [changeAccountEmail, { errors: changeEmailErrors }] = useChangeAccountEmailCallback();
    const [changeUserRoleGroupInStore, { errors: changeUserRoleGroupInStoreErrors }] = useChangeUserRoleGroupCallback();
    const history = useHistory();

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            firstName: () => !model?.firstName ? t('editUser.firstNameRequired', 'First name is required') : '',
            lastName: () => !model?.lastName ? t('editUser.lastNameRequired', 'Last name is required') : '',
            email: () => !userModel?.email ? t('editUser.emailRequired', 'Email is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model, userModel]);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        // If we are creating this user, make sure we save the user and get its id before continuing with saving the profile.
        let userId = model.userId;
        if (isCreate) {
            // Create an invited user but don't send the invite yet, we'll send it after the profile has been saved to so we can include their name
            // and make it more personal for higher engagement.
            const result = await invite({ ...userModel, sendEmail: false });
            userId = result.userId;
            changeUserModel({ id: userId });
            change({ userId: userId});
        }

        await save(model.id, { ...changes, userId: userId }, !!isCreate);

        // If we created a user invite, send the email now the profile has been saved so it can include more personal information (e.g. their name).
        if (isCreate) {
            await resendInviteEmail(userModel.email);
        }

        // Handle changes to the email address of existing users.
        if (!isCreate && storeModel && storeModel.user.email !== userModel.email) {
            await changeAccountEmail(storeModel.user.email, userModel.email);
        }

        // Handle the role group change.
        if (isCreate || storeModel?.user.roleGroup?.id !== roleGroupModel.id) {
            await changeUserRoleGroupInStore({ userId: userId, roleGroupId: roleGroupModel.id });
        }

        history.goBack();
    }, [validate, save, model, changes, isCreate, history, userModel, invite, changeUserModel, change, changeAccountEmail, resendInviteEmail]);

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>
                            {
                                isCreate ? (
                                    <>{t('editUser.createHeading', 'Add user')}</>
                                ) : (
                                        <>{t('editUser.editHeading', 'Edit user')}</>
                                    )
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, loadSupportingDataErrors, saveFormErrors, saveErrors, inviteErrors, resendInviteEmailErrors, changeEmailErrors, changeUserRoleGroupInStoreErrors]} />

                {
                    hasResentInviteEmail ? (
                        <Alert color="success" >
                            <>{t('editUser.confirmationEmailHasBeenResent', 'Invite email for this user has been resent.  Please ask the user to check their email to confirm.')} </>
                            <ButtonAsync type="button" color="success" onClick={async e => { e.preventDefault(); await resendInviteEmail(storeModel?.user?.email ?? userModel.email); }}
                                isExecuting={isResendingInviteEmail}
                                executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
                                {t('common.resendEmail', 'Resend email')}
                            </ButtonAsync>
                        </Alert>
                    ) : null
                }

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="firstName">{t('editUser.firstName', 'First name')}</Label>
                                <ValidatedInput name="firstName" type="text" value={model.firstName ?? ''} onChange={e => change({ firstName: e.currentTarget.value })} onBlur={e => validate('firstName')} validationErrors={validationErrors['firstName']} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="lastName">{t('editUser.lastName', 'Last name')}</Label>
                                <ValidatedInput name="lastName" type="text" value={model.lastName ?? ''} onChange={e => change({ lastName: e.currentTarget.value })} onBlur={e => validate('lastName')} validationErrors={validationErrors['lastName']} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label htmlFor="email">{t('editUser.email', 'Email')}</Label>
                        <Row>
                            <Col>
                                <ValidatedInput name="email" type="email" value={userModel.email ?? ''} onChange={e => changeUserModel({ email: e.currentTarget.value })} onBlur={e => validate('email')} validationErrors={validationErrors['email']} />
                            </Col>
                            {
                                storeModel && !storeModel.user.emailConfirmed && userModel.email === storeModel.user.email ? (
                                    <Col xs="auto">
                                        <ButtonAsync type="button" color="primary" outline onClick={async e => { e.preventDefault(); await resendInviteEmail(userModel.email); setHasResentInviteEmail(true); }}
                                        isExecuting={isResendingInviteEmail}
                                            executingChildren={<><Spinner size="sm" />{t('common.resendingEmail', 'Resending invite...')}</>}>
                                            <FontAwesomeIcon icon="envelope" />
                                            <> {t('editUser.resendInvite', 'Resend invite')}</>
                                        </ButtonAsync>
                                    </Col>
                                ): null
                            }
                        </Row>
                        {
                            storeModel && !storeModel.user.emailConfirmed && userModel.email === storeModel.user.email ? (
                                <FormText>
                                    {t('editUser.userHasNotAcceptedInvite', 'This user has not accepted the invite.  You can change their email if required or resend the invite.')}
                                </FormText>
                            ) : storeModel && !storeModel.user.emailConfirmed && userModel.email !== storeModel.user.email ? (
                                    <FormText>
                                        {t('editUser.userHasNotAcceptedInviteAndEmailChanged', 'This user has not yet accepted the invite, a new invite will be sent to the new email you have supplied.')}
                                    </FormText>
                                ) : storeModel && storeModel.user.emailConfirmed && userModel.email !== storeModel.user.email ? (
                                    <FormText color="warning">
                                        {t('editUser.userHasNotAcceptedInviteAndEmailChanged', 'This user will be asked to confirm the email change before it takes affect.  The old email address will be shown in the system until the change has been confirmed.')}
                                    </FormText>
                                    ) : storeModel && storeModel.user.emailConfirmed && userModel.email === storeModel.user.email ? (
                                        <FormText>
                                            {t('editUser.userHasNotAcceptedInviteAndEmailChanged', 'If you change this user\'s email they will be sent an email to confirm the change before it takes affect.')}
                                        </FormText>
                                    ): null
                        }
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="roleGroup">{t('editUser.roleGroup', 'Security group')}</Label>
                        <ValidatedInput name="roleGroup" type="select" value={roleGroupModel?.id ?? ''} onChange={e => changeRoleGroupModel({ id: e.currentTarget.value })} onBlur={e => validate('roleGroup')} validationErrors={validationErrors['roleGroup']}>
                            {
                                roleGroups?.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))
                            }
                        </ValidatedInput>
                    </FormGroup>

                    <ConditionalFragment showIf={roleGroupModel?.id !== "Administrator"}>
                        <FormGroup>
                            <Label htmlFor="organisationType">{t('editUser.organisationType', 'What type of organisation do you work for?')}</Label>
                            <ValidatedInput name="organsationType" type="select" value={model.organisationType ?? ''} onChange={e => change({ organisationType: e.currentTarget.value })} onBlur={e => validate('organisationType')} validationErrors={validationErrors['organisationType']}>
                                <option value="School">{t('editUser.organisationType.school', 'Maintained School')}</option>
                                <option value="MAT">{t('editUser.organisationType.mat', 'Academy')}</option>
                                <option value="Other">{t('editUser.organisationType.other', 'Other')}</option>
                            </ValidatedInput>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="organisationName">
                                {
                                    model?.organisationType === 'School' ? t('editUser.organisationName.school', 'School')
                                        : model?.organisationType === 'MAT' ? t('editUser.organisationName.mat', 'Academy')
                                            : t('editUser.organisationName.other', 'Organisation')
                                }
                            </Label>
                            <ValidatedInput name="organisationName" type="text" value={model.organisationName ?? ''} onChange={e => change({ organisationName: e.currentTarget.value })} onBlur={e => validate('organisationName')} validationErrors={validationErrors['organisationName']} />
                        </FormGroup>

                        <ConditionalFragment showIf={model?.organisationType === 'School' || model?.organisationType === 'MAT'}>
                            <FormGroup>
                                <Label htmlFor="urn">{t('editUser.urn', 'DfE number (optional)')}</Label>
                                <ValidatedInput name="urn" type="text" value={model.urn ?? ''} onChange={e => change({ urn: e.currentTarget.value })} onBlur={e => validate('urn')} validationErrors={validationErrors['urn']} />
                            </FormGroup>
                        </ConditionalFragment>

                        <FormGroup>
                            <Label htmlFor="jobTitle">{t('editUser.jobTitle', 'Job role / Job title')}</Label>
                            <ValidatedInput name="jobTitle" type="text" value={model.jobTitle ?? ''} onChange={e => change({ jobTitle: e.currentTarget.value })} onBlur={e => validate('jobTitle')} validationErrors={validationErrors['jobTitle']} />
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="">{t('editUser.subscribeToDailyNewslette', 'Subscribe to daily update email?')}</Label>

                            <Row>
                                <Col xs="auto">
                                    <TwoValueSwitch leftLabel={t('common.no', 'No')} rightLabel={t('common.yes', 'Yes')} checked={model?.isSubscribedToDailyNewsLetter ?? false} onChange={value => change({ isSubscribedToDailyNewsLetter: value })} />
                                </Col>
                                <Col>
                                    <div className="">
                                        {t('editUser.subscribeToDailyNewsletter.description', 'Subscribe to daily update emails to receive the need-to-know sector information for your school straight to your inbox every morning.')}
                                    </div>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="">{t('editUser.subscribeToWeeklyNewsletter', 'Subscribe to This Week on TheSchoolBus?')}</Label>

                            <Row>
                                <Col xs="auto">
                                    <TwoValueSwitch leftLabel={t('common.no', 'No')} rightLabel={t('common.yes', 'Yes')} checked={model?.isSubscribedToWeeklyNewsLetter ?? false} onChange={value => change({ isSubscribedToWeeklyNewsLetter: value })} />
                                </Col>
                                <Col>
                                    <div className="">
                                        {t('editUser.subscribeToDailyWeekly.description', 'Subscribe to This Week on TheSchoolBus to receive a weekly summary of what\'s happening in education to your inbox every week.')}
                                    </div>
                                </Col>
                            </Row>
                        </FormGroup>

                        <ConditionalFragment showIf={model?.organisationType === 'Other'}>
                            <Label htmlFor="">{t('editUser.isIntrestedInWorkingTogether', 'Are you a high quality provider in education interested in working with us or featuring your events on TheSchoolYear?')}</Label>
                            <Row>
                                <Col xs="auto">
                                    <TwoValueSwitch leftLabel={t('common.no', 'No')} rightLabel={t('common.yes', 'Yes')} checked={model?.isIntrestedInWorkingTogether ?? false} onChange={value => change({ isIntrestedInWorkingTogether: value })} />
                                </Col>
                                <Col>
                                    <div className="">
                                        {t('editUser.isIntrestedInWorkingTogether.description', 'If you work in education or education consultancy and are interested in having your events on TheSchoolYear or working with us on TheSchoolYear or TheSchoolBus let us know and we\'ll get in touch with you to arrange a good time to chat.')}
                                    </div>
                                </Col>
                            </Row>
                        </ConditionalFragment>
                    </ConditionalFragment>


                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
