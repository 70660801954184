import * as React from 'react';
import { Button, Form, Label, FormGroup, Spinner, Row, Col, ListGroup, ButtonGroup } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useChanges, useChangesArray } from '../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { Guid } from 'guid-string';
import { useSaveCalendarEventCallback } from '../../api/main/calendarEvents/useSaveCalendarEventCallback';
import { CalendarEvent } from '../../api/main/models/CalendarEvent';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { HtmlEditor } from '../../shared/htmlEditor';
import { FileUploadButton } from '../shared/fileUploadButton/FileUploadButton';
import { useUploadBlobCallback } from '../../api/main/blobReferences/useUploadBlobCallback';
import { BlobReference } from '../../api/main/models/BlobReference';
import { useEditCalendarEventViewModel } from '../../api/main/calendarEvents/viewModels/useEditCalendarEventViewModel';
import { UploadedImagePreview } from '../shared/uploadedImagePreview/UploadedImagePreview';
import { useEditCalendarEventSupportingData } from '../../api/main/calendarEvents/viewModels/useEditCalendarEventSupportingData';
import { TwoValueSwitch } from '../shared/TwoValueSwitch'
import { CalendarEventUrl } from '../../api/main/models/CalendarEventUrl';
import { EventUrl } from './EventUrl';
import { ProviderLink } from './ProviderLink';
import { useDeleteCalendarEventUrlCallback } from '../../api/main/calendarEventUrls/useDeleteCalendarEventUrlCallback';
import { useSaveCalendarEventUrlCallback } from '../../api/main/calendarEventUrls/useSaveCalendarEventUrlCallback';
import { useSaveCalendarEventCalendarEventProviderCallback } from '../../api/main/calendarEventCalendarEventProviders/useSaveCalendarEventCalendarEventProviderCallback';
import { CalendarEventCalendarEventProvider } from '../../api/main/models/CalendarEventCalendarEventProvider';
import { useDeleteCalendarEventCalendarEventProviderCallback } from '../../api/main/calendarEventCalendarEventProviders/useDeleteCalendarEventCalendarEventProviderCallback';
import { useSaveCalendarEventScheduleCallback } from '../../api/main/calendarEventSchedules/useSaveCalendarEventScheduleCallback';
import { useDeleteCalendarEventScheduleCallback } from '../../api/main/calendarEventSchedules/useDeleteCalendarEventScheduleCallback';
import { CalendarEventSchedule } from '../../api/main/models/CalendarEventSchedule';
import moment from 'moment';
import { Schedule } from './Schedule';
import { ItemTagLink } from '../../api/main/models/ItemTagLink';
import { useSaveItemTagLinkCallback } from '../../api/main/itemTagLinks/useSaveItemTagLinkCallback';
import { useDeleteItemTagLinkCallback } from '../../api/main/itemTagLinks/useDeleteItemTagLinkCallback';
import { TagSelector } from '../tags/TagSelector';
import { useValidatorArrayCallback } from '../../shared/validator-react-contrib/useValidatorArrayCallback';
import { EventTypeColorBlock } from '../shared/calendar/EventTypeColorBlock';

interface EditCalendarEventProps {
    isCreate?: boolean,
}

/**
 * Create a new CalendarEvent.
 */
export const CreateCalendarEvent = () => (<EditCalendarEvent isCreate={true} />);

/**
 * Edit a CalendarEvent.
 */
export const EditCalendarEvent = (props: EditCalendarEventProps) => {
    const { isCreate } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const { data: { model: storeModel }, isLoading: _isLoading, errors: loadErrors } = useEditCalendarEventViewModel(id);
    const { data: { calendarEventTypes, calendarEventProviders, itemTags, }, isLoading: isLoadingSupportingData, errors: loadSupportingDataErrors } = useEditCalendarEventSupportingData();
    const isLoading = _isLoading || isLoadingSupportingData;
    const { model, change, changes } = useChanges<CalendarEvent>(storeModel, isCreate ? { id: Guid.newGuid(), name: '', descriptionHtml: '', } : {});
    const [save, { errors: saveErrors }] = useSaveCalendarEventCallback();
    const history = useHistory();

    // CalendarEventUrl apis
    const urlsManager = useChangesArray<CalendarEventUrl, string>(storeModel?.urls, item => item.id);
    const [saveCalendarEventUrl] = useSaveCalendarEventUrlCallback();
    const [deleteCalendarEventUrl] = useDeleteCalendarEventUrlCallback();

    // CalendarEventCalendarEventProvider apis
    const providerLinksManager = useChangesArray<CalendarEventCalendarEventProvider, string>(storeModel?.providers, item => item.id);
    const [saveCalendarEventCalendarEventProvider] = useSaveCalendarEventCalendarEventProviderCallback();
    const [deleteCalendarEventCalendarEventProvider] = useDeleteCalendarEventCalendarEventProviderCallback();

    // CalendarEventSchedule apis
    const schedulesManager = useChangesArray<CalendarEventSchedule, string>(storeModel?.schedules, item => item.id);
    const [saveCalendarEventSchedule] = useSaveCalendarEventScheduleCallback();
    const [deleteCalendarEventSchedule] = useDeleteCalendarEventScheduleCallback();

    // ItemTagLink apis
    const tagLinksManager = useChangesArray<ItemTagLink, string>(storeModel?.tagLinks, item => item.id);
    const [saveItemTagLink] = useSaveItemTagLinkCallback();
    const [deleteItemTagLink] = useDeleteItemTagLinkCallback();
    const toggleTagLink = React.useCallback((id: string) => {
        if (!model) {
            return;
        }

        const existing = tagLinksManager.model.find(it => it.itemTagId === id);
        if (existing) {
            tagLinksManager.removeFor(existing.id);
        } else {
            tagLinksManager.addFor({ id: Guid.newGuid(), itemTagId: id, targetId: model.id, });
        }
    }, [tagLinksManager, model]);


    // Image display and uploading.
    const [imageBlob, setImageBlob] = React.useState<BlobReference | null>();
    React.useEffect(() => setImageBlob(storeModel?.imageBlobReference), [storeModel]);

    const [uploadBlob] = useUploadBlobCallback();
    const [uploadImageBlob, { isExecuting: isUploadingImageBlob, errors: uploadImageBlobErrors }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        // Upload the blob.
        const blob = await uploadBlob(files);
        if (!blob) {
            return;
        }

        // Update the model.
        change({ imageBlobReferenceId: blob.id });

        // Update the blob in the state.
        setImageBlob(blob);
    }, [uploadBlob, change, setImageBlob]);

    const clearImage = React.useCallback(() => {
        change({ imageBlobReferenceId: null });
        setImageBlob(null);
    }, [change]);

    // Validation
    //
    const [validateProvider, providerValidationErrors] = useValidatorArrayCallback<CalendarEventCalendarEventProvider>((myModel, validation, fieldsToCheck) => {
        const rules = {
            calendarEventProviderId: () => Guid.isEmpty(myModel?.calendarEventProviderId) ? t('editCalendarEvent.calendarEventProviderIdRequired', 'Provider is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    const [validateUrl, urlValidationErrors] = useValidatorArrayCallback<CalendarEventUrl>((myModel, validation, fieldsToCheck) => {
        const rules = {
            name: () => !myModel?.name ? t('editCalendarEvent.nameRequired', 'Link text is required') : '',
            //url: () => !myModel?.name ? t('editCalendarEvent.urlRequired', 'Url is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    const [validateSchedule, scheduleValidationErrors] = useValidatorArrayCallback<CalendarEventSchedule>((myModel, validation, fieldsToCheck) => {
        const rules = {
            start: () => !myModel?.start ? t('editCalendarEvent.nameRequired', 'Start date is required') : '',
            end: () => !myModel?.end ? t('editCalendarEvent.nameRequired', 'Start date is required')
                : myModel.end < myModel.start ? t('editCalendarEvent.nameRequired', 'End date cannot be before the start date')
                : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editCalendarEvent.nameRequired', 'Name is required') : '',
            calendarEventTypeId: () => Guid.isEmpty(model?.calendarEventTypeId) ? t('editCalendarEvent.calendarEventTypeIdRequired', 'Type of event is required') : '',

            // Validate related objects.
            providers: () => providerLinksManager.model.filter(it => !validateProvider(it)).length ? t('editCalendarEvent.providersInvalid', 'One or more providers are invalid') : '',
            urls: () => urlsManager.model.filter(it => !validateUrl(it)).length ? t('editCalendarEvent.urlsInvalid', 'One or more links are invalid') : '',
            schedules: () => schedulesManager.model.filter(it => !validateSchedule(it)).length ? t('editCalendarEvent.schedulesInvalid', 'One or more dates invalid') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model,
        providerLinksManager, validateProvider,
        urlsManager, validateUrl,
        schedulesManager, validateSchedule,
    ]);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        // Save the main form.
        await save(model.id, changes, !!isCreate);

        // Save changes to the URLs
        for (const item of urlsManager.added) {
            await saveCalendarEventUrl(item.id, urlsManager.changesFor(item.id), true);
        }
        for (const item of urlsManager.updated) {
            await saveCalendarEventUrl(item.id, urlsManager.changesFor(item.id), false);
        }
        for (const item of urlsManager.removed) {
            await deleteCalendarEventUrl(item.id);
        }
        urlsManager.markAsSaved();

        // Save changes to the linked providers
        for (const item of providerLinksManager.added) {
            await saveCalendarEventCalendarEventProvider(item.id, providerLinksManager.changesFor(item.id), true);
        }
        for (const item of providerLinksManager.updated) {
            await saveCalendarEventCalendarEventProvider(item.id, providerLinksManager.changesFor(item.id), false);
        }
        for (const item of providerLinksManager.removed) {
            await deleteCalendarEventCalendarEventProvider(item.id);
        }
        providerLinksManager.markAsSaved();

        // Save changes to the schedules
        for (const item of schedulesManager.added) {
            await saveCalendarEventSchedule(item.id, schedulesManager.changesFor(item.id), true);
        }
        for (const item of schedulesManager.updated) {
            await saveCalendarEventSchedule(item.id, schedulesManager.changesFor(item.id), false);
        }
        for (const item of schedulesManager.removed) {
            await deleteCalendarEventSchedule(item.id);
        }
        schedulesManager.markAsSaved();

        // Save changes to the tags
        for (const item of tagLinksManager.added) {
            await saveItemTagLink(item.id, tagLinksManager.changesFor(item.id), true);
        }
        for (const item of tagLinksManager.updated) {
            await saveItemTagLink(item.id, tagLinksManager.changesFor(item.id), false);
        }
        for (const item of tagLinksManager.removed) {
            await deleteItemTagLink(item.id);
        }
        tagLinksManager.markAsSaved();

        history.goBack();
    }, [validate, save, model, changes, isCreate, history,
        urlsManager, saveCalendarEventUrl, deleteCalendarEventUrl,
        providerLinksManager, saveCalendarEventCalendarEventProvider, deleteCalendarEventCalendarEventProvider,
        schedulesManager, saveCalendarEventSchedule, deleteCalendarEventSchedule,
        tagLinksManager, saveItemTagLink, deleteItemTagLink,
    ]);

    // When we are creating a new item, then once we have the store model we're going to add some defaults
    // to the linked objects.
    const [needToAddDefaults, setNeedToAddDefaults] = React.useState<boolean>(!!isCreate);
    React.useEffect(() => {
        if (!needToAddDefaults) {
            return;
        }

        // Wait until all the managers we want are ready.
        if (!model || !urlsManager || !providerLinksManager) {
            return;
        }

        // Add default records where we need them.
        urlsManager.addFor({ id: Guid.newGuid(), calendarEventId: model.id, name: '', url: '', linkType: 'Other', });
        providerLinksManager.addFor({ id: Guid.newGuid(), calendarEventId: model.id, });
        schedulesManager.addFor({ id: Guid.newGuid(), calendarEventId: model.id, start: moment().startOf('day').toISOString(), end: moment().startOf('day').toISOString(), isAllDay: true, });

        setNeedToAddDefaults(false);
    }, [needToAddDefaults, setNeedToAddDefaults, model, urlsManager, providerLinksManager, schedulesManager]);

    // Work out some interface changes based on related tables that often have only one item.
    const hasSingleSchedule = schedulesManager.model.length === 1;
    const hasSingleProviderLink = providerLinksManager.model.length === 1;
    const hasSingleUrl = urlsManager.model.length === 1;

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>
                            {
                                isCreate ? (
                                    <>{t('editCalendarEvent.createHeading', 'Add event')}</>
                                        ): (
                                        <>{t('editCalendarEvent.editHeading', 'Edit event')}</>
                                        )
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, saveFormErrors, saveErrors, uploadImageBlobErrors, loadSupportingDataErrors]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('editCalendarEvent.name', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="calendarEventTypeId">{t('editCalendarEvent.calendarEventTypeId', 'Type of event')}</Label>

                                <Row noGutters>
                                    <Col xs="auto">
                                        <EventTypeColorBlock color={calendarEventTypes?.find(item => item.id === model.calendarEventTypeId)?.color ?? 'transparent'} />
                                    </Col>
                                    <Col>
                                        <ValidatedInput name="calendarEventTypeId" type="select" value={model?.calendarEventTypeId ?? ''} onChange={e => change({ calendarEventTypeId: e.currentTarget.value })} onBlur={e => validate('calendarEventTypeId')} validationErrors={validationErrors['calendarEventTypeId']}>
                                            <option value="">{t('editCalendarEvent.eventTypeId.pleaseSelect', '(Please select the type of event)')}</option>
                                            {
                                                calendarEventTypes?.map(item => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))
                                            }
                                        </ValidatedInput>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md="auto">
                            <FormGroup>
                                <Label htmlFor="isFeatured">{t('editCalendarEvent.isFeatured', 'Feature in the plan ahead area?')}</Label>
                                <TwoValueSwitch leftLabel={t('common.no', 'No')} rightLabel={t('common.yes', 'Yes')}
                                    checked={model?.isFeatured ?? false} onChange={checked => change({ isFeatured: checked })} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label htmlFor="location">{t('editCalendarEvent.location', 'Location')}</Label>
                        <ValidatedInput name="location" type="text" value={model?.location ?? ''} onChange={e => change({ location: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="providers">
                            {
                                hasSingleProviderLink ? t('editCalendarEvent.provider', 'Provider')
                                    : t('editCalendarEvent.providers', 'Providers')
                            }
                        </Label>

                        <ListGroup className="mb-1" flush={hasSingleProviderLink}>
                            {
                                providerLinksManager.model.map(item => (
                                    <ProviderLink key={item.id}
                                        singleItem={hasSingleProviderLink}
                                        model={item}
                                        change={changes => providerLinksManager.changeFor(item.id, changes)}
                                        remove={() => providerLinksManager.removeFor(item.id)}
                                        validate={() => validateProvider(item)}
                                        validationErrors={providerValidationErrors(item.id)}
                                        providers={calendarEventProviders ?? []}
                                    />
                                ))
                            }
                        </ListGroup>
                        <Button size="sm" color="primary" outline onClick={() => providerLinksManager.addFor({ id: Guid.newGuid(), calendarEventId: model?.id ?? '', })}>
                            {t('editCalendarEvent.addProviderLink', 'Add another provider')}
                        </Button>

                    </FormGroup>

                    <FormGroup>
                        <ConditionalFragment showIf={!hasSingleSchedule}>
                            <Label htmlFor="schedules">{t('editCalendarEvent.schedules', 'Dates')}</Label>
                        </ConditionalFragment>

                        <ListGroup className="mb-1" flush={hasSingleSchedule}>
                            {
                                schedulesManager.model.map(item => (
                                    <Schedule key={item.id}
                                        singleItem={hasSingleSchedule}
                                        model={item}
                                        change={changes => schedulesManager.changeFor(item.id, changes)}
                                        remove={() => schedulesManager.removeFor(item.id)}
                                        validate={() => validateSchedule(item)}
                                        validationErrors={scheduleValidationErrors(item.id)}
                                    />
                                ))
                            }
                        </ListGroup>
                        <Button size="sm" color="primary" outline onClick={() => schedulesManager.addFor({ id: Guid.newGuid(), calendarEventId: model?.id ?? '', start: moment().startOf('day').toISOString(), end: moment().startOf('day').toISOString(), isAllDay: true, })}>
                            {t('editCalendarEvent.addSchedule', 'Add another date')}
                        </Button>
                    </FormGroup>

                    <Row>
                        <Col xs={12} md="auto">
                            <FormGroup>
                                <Label>{t('editCalendarEvent.schoolTypes', 'School type')}</Label>
                                <TagSelector items={itemTags?.filter(it => it.tagType === 'SchoolType') ?? []}
                                    isSelected={id => !!tagLinksManager.model.find(it => it.itemTagId === id)}
                                    toggle={(id) => toggleTagLink(id)} />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md="auto">
                            <FormGroup>
                                <Label>{t('editCalendarEvent.schoolPhases', 'School phases')}</Label>
                                <TagSelector items={itemTags?.filter(it => it.tagType === 'SchoolPhase') ?? []}
                                    isSelected={id => !!tagLinksManager.model.find(it => it.itemTagId === id)}
                                    toggle={(id) => toggleTagLink(id)} />
                            </FormGroup>
                        </Col>
                        <Col xs={12} lg="">
                            <FormGroup>
                                <Label>{t('editCalendarEvent.keywords', 'Keywords')}</Label>
                                <TagSelector items={itemTags?.filter(it => it.tagType === 'Other') ?? []}
                                    isSelected={id => !!tagLinksManager.model.find(it => it.itemTagId === id)}
                                    toggle={(id) => toggleTagLink(id)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    

                    <FormGroup>
                        <Label htmlFor="imageBlobReferenceId">{t('editCalendarEvent.promotionalImage', 'Promotional image')}</Label>

                        <UploadedImagePreview src={imageBlob?.url ?? ''} />
                        <ButtonGroup>
                            <FileUploadButton onUpload={files => uploadImageBlob(files)} isExecuting={isUploadingImageBlob}
                                executingChildren={<><Spinner size="sm" /><> </>{t('common.uploading', 'Uploading...')}</>}
                            >
                                {t('editCalendarEvent.uploadPromotionalImage', 'Upload promotional image...')}
                            </FileUploadButton>
                            <Button color="danger" outline onClick={clearImage}>
                                {t('editCalendarHeadline.clearBackgroundImage', 'Clear image')}
                            </Button>
                        </ButtonGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="descriptionHtml">{t('editCalendarEvent.descriptionHtml', 'Description')}</Label>
                        <HtmlEditor value={model?.descriptionHtml ?? ''} onChange={value => change({ descriptionHtml: value })} />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="links">{t('editCalendarEvent.links', 'Links')}</Label>

                        <ListGroup className="mb-1" flush={hasSingleUrl}>
                            {
                                urlsManager.model.map(item => (
                                    <EventUrl key={item.id}
                                        singleItem={hasSingleUrl}
                                        model={item}
                                        change={changes => urlsManager.changeFor(item.id, changes)}
                                        remove={() => urlsManager.removeFor(item.id)}
                                        validate={() => validateUrl(item)}
                                        validationErrors={urlValidationErrors(item.id)}
                                    />
                                ))
                            }
                        </ListGroup>
                        <Button size="sm" color="primary" outline onClick={() => urlsManager.addFor({ id: Guid.newGuid(), calendarEventId: model?.id ?? '', name: '', url: '', linkType: 'Other', })}>
                            {t('editCalendarEvent.addUrl', 'Add another link')}
                        </Button>

                    </FormGroup>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
