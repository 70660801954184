import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { ItemTagCreateInput } from '../generated/globalTypes';
import { createItemTagMutation, createItemTagMutationVariables } from '../generated/createItemTagMutation';
import { itemTagFieldsFragment } from '../models/ItemTag';

/**
 * Get a callback to create a ItemTag in the store.
 */
export function useCreateItemTagCallback(): [(model: ItemTagCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createItemTagMutation, createItemTagMutationVariables>(
        gql`
            mutation createItemTagMutation ($model: ItemTagCreateInput!) {
                createItemTag(model: $model) {
                    ...itemTagFields
                }
            }

            ${itemTagFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: ItemTagCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
