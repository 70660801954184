import gql from "graphql-tag";
import { analyticEventFields } from "../generated/analyticEventFields";

export const analyticEventFieldsFragment = gql`
    fragment analyticEventFields on AnalyticEvent {
        id
        userId
        eventDate
        eventType
        calendarEventId
        calendarEventUrlId
        url
    }
`;


export type AnalyticEvent = Omit<analyticEventFields, '__typename'>;
