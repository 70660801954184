import * as React from 'react';
import { ItemTagLinkCreateInput, ItemTagLinkUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateItemTagLinkCallback } from './useCreateItemTagLinkCallback';
import { useUpdateItemTagLinkCallback } from './useUpdateItemTagLinkCallback';
import { ItemTagLink } from '../models/ItemTagLink';

/**
 * Get a callback to save a ItemTagLink in the store using either a create or update.
 */
export function useSaveItemTagLinkCallback(options: SaveInStoreOptions<ItemTagLink, string> = {}) {
    const [_create, createStatus] = useCreateItemTagLinkCallback();
    const create = React.useCallback((model: Partial<ItemTagLink>) => _create(model as ItemTagLinkCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateItemTagLinkCallback();
    const update = React.useCallback((id: string, changes: Partial<ItemTagLink>) => _update(id, changes as ItemTagLinkUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
