import { BlobReference } from "../../api/main/models/BlobReference";
import { CalendarEvent } from "../../api/main/models/CalendarEvent";
import { CalendarEventCalendarEventProvider } from "../../api/main/models/CalendarEventCalendarEventProvider";
import { CalendarEventProvider } from "../../api/main/models/CalendarEventProvider";
import { CalendarEventSchedule } from "../../api/main/models/CalendarEventSchedule";
import { CalendarEventType } from "../../api/main/models/CalendarEventType";
import { CalendarEventUrl } from "../../api/main/models/CalendarEventUrl";
import { ItemTag } from "../../api/main/models/ItemTag";
import { ItemTagLink } from "../../api/main/models/ItemTagLink";
import { CalendarHomeScheduleViewModel } from "./CalendarHomeScheduleViewModel";

/**
 * Create a CalendarHomeScheduleViewModel for schedule.
 * @param schedule
 */
export function generateCalendarHomeScheduleViewModel(schedule: CalendarEventSchedule, optionalData: {
    calendarEvents: Array<CalendarEvent> | undefined | null,
    calendarEventProviderLinks: Array<CalendarEventCalendarEventProvider> | undefined | null,
    calendarEventUrls: Array<CalendarEventUrl> | undefined | null,
    itemTagLinks: Array<ItemTagLink> | undefined | null,
    providers: Array<CalendarEventProvider> | undefined | null,
    eventTypes: Array<CalendarEventType> | undefined | null,
    itemTags: Array<ItemTag> | undefined | null,
    blobReferences: Array<BlobReference> | undefined | null,
}): CalendarHomeScheduleViewModel {
    const {
        calendarEvents,
        calendarEventProviderLinks,
        calendarEventUrls,
        itemTagLinks,
        providers,
        eventTypes,
        itemTags,
        blobReferences,
    } = optionalData;

    const calendarEvent = calendarEvents?.find(it => it.id === schedule.calendarEventId);
    const eventType = eventTypes?.find(it => it.id === calendarEvent?.calendarEventTypeId);
    const myCalendarEventProviderLinks = calendarEventProviderLinks?.filter(it => it.calendarEventId === calendarEvent?.id) ?? [];
    const myCalendarEventUrls = calendarEventUrls?.filter(it => it.calendarEventId === calendarEvent?.id) ?? [];
    const myItemTagLinks = itemTagLinks?.filter(it => it.targetId === calendarEvent?.id) ?? [];
    const imageBlobReference = blobReferences?.find(it => it.id === calendarEvent?.imageBlobReferenceId);

    return {
        ...schedule,
        calendarEvent: !calendarEvent? undefined : {
            ...calendarEvent,
            providers: myCalendarEventProviderLinks.map(item => {
                const provider = providers?.find(it => it.id === item.calendarEventProviderId);
                const image = blobReferences?.find(it => it.id === provider?.imageBlobReferenceId);
                return {
                    ...item,
                    provider: !provider? undefined: {
                        ...provider,
                        imageBlobReference: image,
                    },
                };
            }),
            tagLinks: myItemTagLinks.map(item => {
                const tagLinks = itemTags?.find(it => it.id === item.itemTagId);
                return {
                    ...item,
                    itemTag: tagLinks,
                };
            }),
            urls: myCalendarEventUrls,
            eventType: eventType,
            imageBlobReference: imageBlobReference,
        }
    };
}