import * as React from 'react';
import { useHistory } from 'react-router';
import { useGlobalSearchText } from '../../../globalState/globalSearchText/useGlobalSearchText';
import { SearchInput } from '../../shared/searchInput/SearchInput';
import './headerSearchInput.scss';


/**
 * Search bar that is always available in the header for searching calendar items.
 */
export const HeaderSearchInput = () => {
    // Use global state to store the search text.
    const [searchText, setSearchText] = useGlobalSearchText();

    // When the user completes a search trigger the appropriate routing.
    const history = useHistory();
    const performSearch = React.useCallback(() => {
        const cleanSearchText = searchText.trim();
        if (!cleanSearchText) {
            history.push('/calendar');
        } else {
            history.push(`/calendar/search/${encodeURIComponent(cleanSearchText)}`);
        }
    }, [history, searchText]);
    
    return (
        <SearchInput className="header-search-input" value={searchText} onChange={e => setSearchText(e.currentTarget.value)} onSearchButtonClick={() => performSearch()} />
        );
};