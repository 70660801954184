import * as React from 'react';
import { BlobReference } from "../../../api/main/models/BlobReference";

export interface CmsVideoDisplayModel {
    id: string,
    name: string,
    videoBlobReference: BlobReference | undefined | null,
    thumbnailBlobReference: BlobReference | undefined | null,
}

export interface CmsVideoDisplayProps extends React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> {
    model: CmsVideoDisplayModel | undefined | null,
    controls?: boolean,
}

/**
 * Spealised video tag that lets us consitantly present a CmsVideo within components or pages.
 * @param props
 */
export const CmsVideoDisplay = (props: CmsVideoDisplayProps) => {
    const {
        model,
        className,
        controls = true,
        ...rest
    } = props;

    return (
        <div className="embed-responsive embed-responsive-16by9 mb-2">
            <video
                src={model?.videoBlobReference?.url ?? ''}
                poster={model?.thumbnailBlobReference?.url}
                controls={controls}
                {...rest}
                >
            </video>
        </div>
        );
};