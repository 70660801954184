import './calendarDayCard.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardTitle } from 'reactstrap';
import moment, { Moment } from 'moment';

export interface CalendarDayCardProps {
    date: Moment,
    title?: string,
    children?: React.ReactNode,
}

/**
 * Card
 */
export const CalendarDayCard = (props: CalendarDayCardProps) => {
    const { date, title, children } = props;

    const { t } = useTranslation();

    const daySettings = React.useMemo(() => {
        const today = moment().startOf('day');

        return {
            //dayNumber: date.format('DD'),
            //dayName: date.format('dddd'),
            isToday: today.diff(moment(date).startOf('day')) === 0 ? true : false,
            isPast: today.diff(moment(date).startOf('day')) > 0 ? true : false,
        };
    }, [date]);

    return (
        <Card className={`calendar-day-card ${daySettings.isToday ? 'calendar-day-card-today' : ''} ${daySettings.isPast ? 'calendar-day-card-past': ''}`}>
            <CardBody tag="div">
                <CardTitle>
                    {
                        title? title
                            : t('calendarDayCard.defaultTitle', '{{date, dddd Do MMMM}}', { date: date })}
                </CardTitle>
                {children}
            </CardBody>
        </Card>
        );
};