import gql from "graphql-tag";
import { calendarEventFields } from "../generated/calendarEventFields";

export const calendarEventFieldsFragment = gql`
    fragment calendarEventFields on CalendarEvent {
        id
        name
        descriptionHtml
        imageBlobReferenceId,
        archived
        calendarEventTypeId
        createdByUserId
        createdDate
        isPaidPromotion
        isFeatured
        location
    }
`;


export type CalendarEvent = Omit<calendarEventFields, '__typename'>;
