import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CmsVideoCreateInput } from '../generated/globalTypes';
import { createCmsVideoMutation, createCmsVideoMutationVariables } from '../generated/createCmsVideoMutation';
import { cmsVideoFieldsFragment } from '../models/CmsVideo';

/**
 * Get a callback to create a CmsVideo in the store.
 */
export function useCreateCmsVideoCallback(): [(model: CmsVideoCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createCmsVideoMutation, createCmsVideoMutationVariables>(
        gql`
            mutation createCmsVideoMutation ($model: CmsVideoCreateInput!) {
                createCmsVideo(model: $model) {
                    ...cmsVideoFields
                }
            }

            ${cmsVideoFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: CmsVideoCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
