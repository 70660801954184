import './colorDisplay.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface ColorDisplayProps {
    color: string | undefined,
    className?: string,
    style?: React.CSSProperties,
}

/**
 * Display of a color (similar to an input of type color but not editable and a div rather than an input).
 */
export const ColorDisplay = (props: ColorDisplayProps) => {
    const { color, className, style } = props;
    const { t } = useTranslation();

    return (
        <div className={`color-display ${className ?? ''}`} style={{ ...(style ?? {}), backgroundColor: color }}>
            <span className="sr-only">{
                color ? t('colorDisplay.color', 'Colour: {{color}}', { color })
                    : t('colorDisplay.noColor', 'No colour')
            }</span>
        </div>
        );
};
