import { useTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';

/**
 * Warning shown to administrators on CMS pages to let them know the CMS functionality isn't a full CMS.
 */
export const CmsAdministrationWarning = () => {
    const { t } = useTranslation();

    return (
        <Alert color="warning">
            {t('cmsAdministrationWarning.areYouSureWarning', 'The CMS-like functionality provided to administrators by this software is designed to allow you to update selected text, videos, and images used by the software and is not a full CMS.  Care should be taken at all times when using these features to ensure core software functionality is not compromised.')}
        </Alert>
        );
};