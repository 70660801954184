import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { ValidationErrors } from 'pojo-validator';
import { ValidateCallback } from 'pojo-validator-react';
import * as React from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useTranslation } from 'react-i18next';
import { Button, Col, FormGroup, Label, ListGroupItem, Row, } from 'reactstrap';
import { CalendarEventSchedule } from '../../api/main/models/CalendarEventSchedule';
import { TwoValueSwitch } from '../shared/TwoValueSwitch';
import { ValidatedISODateTimeInput } from '../shared/ValidatedISODateTimeInput';

export interface ScheduleProps {
    model: CalendarEventSchedule,
    change: (changes: Partial<CalendarEventSchedule>) => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    remove: () => void,
    singleItem?: boolean,
}

/**
 * Component for editing a URL attached to an event as a CalenderSchedule.
 * @param props
 */
export const Schedule = (props: ScheduleProps) => {
    const {
        model, change,
        validate, validationErrors,
        remove,
        singleItem = false,
    } = props;

    const { t } = useTranslation();

    // Work out the format to show for the date pickers.
    const dateInputType = model.isAllDay ? 'date' : 'datetime-local';

    return (
        <ListGroupItem style={singleItem ? { paddingLeft: '0px', paddingRight: '0px', paddingTop: '0px', paddingBottom: '0px', borderTopStyle: 'none', borderBottomStyle: 'none' } : undefined}>
            <Row>
                <Col>
                    <FormGroup>
                        <Label htmlFor="start">{t('schedule.start', 'Start')}</Label>
                        <ValidatedISODateTimeInput name="start" type={dateInputType} value={model?.start ?? ''} onChange={e => change({ start: e.currentTarget.value })} onBlur={e => validate('start')} validationErrors={validationErrors['start']} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label htmlFor="end">{t('schedule.end', 'End')}</Label>
                        <ValidatedISODateTimeInput name="end" type={dateInputType} value={model?.end ?? ''} onChange={e => change({ end: e.currentTarget.value })} onBlur={e => validate('end')} validationErrors={validationErrors['end']} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label htmlFor="isAllDay">&nbsp;</Label>
                        <TwoValueSwitch
                            leftLabel={t('schedule.isAllDay.left', 'All day event')}
                            rightLabel={t('schedule.isAllDay.right', 'Requires start time')}
                            checked={!(model?.isAllDay ?? false)} onChange={checked => {
                                if (!checked) {
                                    // Is an all day event, we need to loose the time element.
                                    change({ isAllDay: !checked, start: moment(model.start).startOf('day').toISOString(), end: moment(model.end).startOf('day').toISOString() });
                                } else {
                                    // Is not an all day event, just toggle the flag.
                                    change({ isAllDay: !checked, });
                                }
                            }} />
                    </FormGroup>
                </Col>
                <ConditionalFragment showIf={!singleItem}>
                    <Col xs="auto">
                        <FormGroup>
                            <Label htmlFor="delete" className="sr-only">&nbsp;</Label>
                            <div>
                                <Button color="danger" outline onClick={() => remove()}>
                                    <FontAwesomeIcon icon="trash" />
                                    <> </>
                                    <span className="sr-only">{t('common.delete', 'Delete')}</span>
                                </Button>
                            </div>
                        </FormGroup>
                    </Col>
                </ConditionalFragment>
            </Row>
        </ListGroupItem>
        );
}