import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadOptions, AsyncLoadResult } from '../../../../shared/abstractStore';
import { calendarHomeViewModelQuery, calendarHomeViewModelQueryVariables } from '../../generated/calendarHomeViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { calendarEventScheduleFieldsFragment } from '../../models/CalendarEventSchedule';
import { calendarEventFieldsFragment } from '../../models/CalendarEvent';
import { calendarEventUrlFieldsFragment } from '../../models/CalendarEventUrl';
import { itemTagLinkFieldsFragment } from '../../models/ItemTagLink';
import { calendarEventCalendarEventProviderFieldsFragment } from '../../models/CalendarEventCalendarEventProvider';

export interface CalendarHomeViewModelAsyncLoadOptions extends AsyncLoadOptions {
    fromDate?: string,
    toDate?: string,
}

/**
 * View model for Calendar that loads the scheduled events and related data.
 * @param options
 */
export function useCalendarHomeViewModel(options: CalendarHomeViewModelAsyncLoadOptions = {}): AsyncLoadResult<calendarHomeViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, }] = useLazyQuery<calendarHomeViewModelQuery, calendarHomeViewModelQueryVariables>(
        gql`
        query calendarHomeViewModelQuery ($fromDate: DateTimeOffset, $toDate: DateTimeOffset) {
            schedules: calendarEventSchedules (fromDate: $fromDate, toDate: $toDate) {
                ...calendarEventScheduleFields
            }

            calendarEvents {
                ...calendarEventFields
            }

            calendarEventCalendarEventProviders {
                ...calendarEventCalendarEventProviderFields
            }

            itemTagLinks {
                ...itemTagLinkFields
            }

            calendarEventUrls {
                ...calendarEventUrlFields
            }
        }

        ${calendarEventScheduleFieldsFragment}
        ${calendarEventScheduleFieldsFragment}
        ${calendarEventFieldsFragment}
        ${calendarEventCalendarEventProviderFieldsFragment}
        ${calendarEventUrlFieldsFragment}
        ${itemTagLinkFieldsFragment}
        `,
        {

            variables: {
                fromDate: options.fromDate,
                toDate: options.toDate,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
}
