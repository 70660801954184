import gql from "graphql-tag";
import { cmsTextFields } from "../generated/cmsTextFields";

export const cmsTextFieldsFragment = gql`
    fragment cmsTextFields on CmsText {
        id
        name
        html
        archived
        createdByUserId
        createdDate
    }
`;


export type CmsText = Omit<cmsTextFields, '__typename'>;
