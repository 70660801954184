import * as React from 'react';
import { Button, Form, Label, FormGroup, Spinner, Row, Col } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useChanges } from '../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { Guid } from 'guid-string';
import { useItemTag } from '../../api/main/itemTags/useItemTag';
import { useSaveItemTagCallback } from '../../api/main/itemTags/useSaveItemTagCallback';
import { ItemTag } from '../../api/main/models/ItemTag';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';

interface EditSchoolPhaseProps {
    isCreate?: boolean,
}

/**
 * Create a new SchoolPhase.
 */
export const CreateSchoolPhase = () => (<EditSchoolPhase isCreate={true} />);

/**
 * Edit a SchoolPhase.
 */
export const EditSchoolPhase = (props: EditSchoolPhaseProps) => {
    const { isCreate } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const { data: { model: storeModel }, isLoading, errors: loadErrors } = useItemTag(id);
    const { model, change, changes } = useChanges<ItemTag>(storeModel, isCreate ? { id: Guid.newGuid(), tagType: 'SchoolPhase', name: '', displayOrder: 99, color: '#000000' } : {});
    const [save, { errors: saveErrors }] = useSaveItemTagCallback();
    const history = useHistory();
    
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editSchoolPhase.nameRequired', 'Name is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        await save(model.id, changes, !!isCreate);

        history.goBack();
    }, [validate, save, model, changes, isCreate, history]);

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>
                            {
                                isCreate ? (
                                    <>{t('editSchoolPhase.createHeading', 'Add school phase')}</>
                                        ): (
                                        <>{t('editSchoolPhase.editHeading', 'Edit school phase')}</>
                                        )
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, saveFormErrors, saveErrors]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('editSchoolPhase.name', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="displayOrder">{t('editSchoolPhase.displayOrder', 'Order')}</Label>
                        <ValidatedInput name="displayOrder" type="number" step="1" value={model?.displayOrder ?? ''} onChange={e => change({ displayOrder: e.currentTarget.valueAsNumber })} onBlur={e => validate('displayOrder')} validationErrors={validationErrors['displayOrder']} />
                    </FormGroup>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
