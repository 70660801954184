import './calendarEventCardItem.scss';

import * as React from 'react';
import moment, { Moment } from 'moment';
import { ConditionalFragment } from 'react-conditionalfragment';
import { scheduleDescription } from '../../utilities/scheduleDescription';
import { Col, ListGroupItem, Row } from 'reactstrap';
import { EventTypeColorBlock } from '../shared/calendar/EventTypeColorBlock';
import { CalendarHomeScheduleViewModel } from './CalendarHomeScheduleViewModel';

export interface CalendarEventCardItemProps {
    date: Moment,
    schedule: CalendarHomeScheduleViewModel,
    onClick?: React.MouseEventHandler<HTMLElement>,
}

/**
 * Event showing within a CalendarDay.
 */
export const CalendarEventCardItem = (props: CalendarEventCardItemProps) => {
    const { date, schedule, onClick, } = props;

    // Work out where we fit in multi-day events.
    const { startsBeforeThisDay, endsAfterThisDay } = React.useMemo(() => {
        const startOfDay = moment(date).startOf('day').toISOString();
        const endOfDay = moment(date).endOf('day').toISOString();

        const startsBeforeThisDay = (moment(schedule.start).toISOString() < startOfDay);
        const endsAfterThisDay = (moment(schedule.end).toISOString() > endOfDay);

        return {
            startsBeforeThisDay,
            endsAfterThisDay,
        };
    }, [date, schedule]);

    return (
        <ListGroupItem className={`calendar-event-card-item ${startsBeforeThisDay ? 'calendar-event-card-item-starts-before' : ''} ${endsAfterThisDay ? 'calendar-event-card-item-ends-after' : ''}`}
            onClick={onClick}
            >
            <ConditionalFragment showIf={!schedule.isAllDay || startsBeforeThisDay || endsAfterThisDay}>
                <div className="calendar-event-card-item-time">
                    {scheduleDescription(schedule, { timeOnly: (!startsBeforeThisDay && !endsAfterThisDay) })}
                </div>
            </ConditionalFragment>
            <Row noGutters>
                <ConditionalFragment showIf={!startsBeforeThisDay}>
                    <Col xs="auto">
                        <EventTypeColorBlock color={schedule.calendarEvent?.eventType?.color ?? 'transparent'} />
                    </Col>
                </ConditionalFragment>
                <Col>
                    <div className="calendar-event-card-item-text">
                        {schedule.calendarEvent?.name}
                    </div>
                </Col>
                <ConditionalFragment showIf={!!schedule.calendarEvent?.providers}>
                    {
                        schedule.calendarEvent?.providers?.map(providerLink => {
                            // Only show providers with logos.
                            if (!providerLink.provider?.imageBlobReference) {
                                return null;
                            }

                            return (
                                <Col key={providerLink.id} xs="auto">
                                    <img className="img-fluid calendar-event-card-item-provider-logo" src={providerLink.provider.imageBlobReference.url ?? ''} alt={providerLink.provider?.name ?? ''} />
                                </Col>                
                              );
                        })
                    }

                </ConditionalFragment>
            </Row>
        </ListGroupItem>
        );
};