import gql from "graphql-tag";
import { profileFields } from "../generated/profileFields";
import { userFieldsFragment } from "./User";

export const profileFieldsFragment = gql`
    fragment profileFields on Profile {
        id
        userId
        firstName
        lastName
        archived
        organisationName
        organisationType
        isSubscribedToDailyNewsLetter
        isSubscribedToWeeklyNewsLetter
        isIntrestedInWorkingTogether
        urn
        jobTitle
        registrationSchoolPhasesJson
        registeredDate

        user {
            ...userFields,
        }
    }

    ${userFieldsFragment}
`;


export type Profile = Omit<profileFields, '__typename' | 'user'>;
