import { RouteEntry } from "../../../shared/routes";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { CmsVideosList } from "./CmsVideosList";
import { EditCmsVideo, CreateCmsVideo } from "./EditCmsVideo";
import { DeleteCmsVideo } from "./DeleteCmsVideo";

export const cmsVideoRoutes: Array<RouteEntry> = [
    { path: '/administration/cms/videos', exact: true, component: CmsVideosList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/cms/videos/edit/:id', component: EditCmsVideo, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/cms/videos/add', component: CreateCmsVideo, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/cms/videos/delete/:id', component: DeleteCmsVideo, authorize: true, requireRole: IdentityRoles.Administration },
];
