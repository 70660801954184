import { RouteEntry } from "../../../shared/routes";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { CmsTextsList } from "./CmsTextsList";
import { EditCmsText, CreateCmsText } from "./EditCmsText";
import { DeleteCmsText } from "./DeleteCmsText";

export const cmsTextRoutes: Array<RouteEntry> = [
    { path: '/administration/cms/texts', exact: true, component: CmsTextsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/cms/texts/edit/:id', component: EditCmsText, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/cms/texts/add', component: CreateCmsText, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/cms/texts/delete/:id', component: DeleteCmsText, authorize: true, requireRole: IdentityRoles.Administration },
];
