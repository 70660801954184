import * as React from 'react';
import { Table, Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { TableRowButtons } from '../shared/TableRowButtons';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useHistory } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Waypoint } from 'react-waypoint';
import { useCalendarEventsListViewModel } from '../../api/main/calendarEvents/viewModels/useCalendarEventsListViewModel';
import { UploadedImagePreview } from '../shared/uploadedImagePreview/UploadedImagePreview';
import { scheduleDescription } from '../../utilities/scheduleDescription';
import { EventTypeColorBlock } from '../shared/calendar/EventTypeColorBlock';


/**
 * List of CalendarEvents.
 */
export const CalendarEventsList = () => {
    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');
    const { data: { items: allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useCalendarEventsListViewModel({ pageSize: undefined });
    const [isMenuOpenIds, setIsMenuOpenIds] = React.useState<Array<string>>([]);
    const isMenuOpen = React.useCallback((id: string) => isMenuOpenIds.find(it => it === id) ? true : false, [isMenuOpenIds]);
    const toggleMenuOpen = React.useCallback((id: string) => {
        setIsMenuOpenIds(prevState => {
            if (prevState.find(it => it === id)) {
                return prevState.filter(it => it !== id);
            } else {
                return [...prevState, id];
            }
        });
    }, [setIsMenuOpenIds]);
    const history = useHistory();

    // Filter by the user's search client side so it can work when offline as well as online.
    const items = React.useMemo(() => {
        if (!allItems || !search) {
            return allItems;
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return allItems.filter(item =>
            item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.eventType.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.providers.filter(it => it.provider.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0).length
            || item.schedules.filter(it => scheduleDescription(it).toLocaleLowerCase().indexOf(lowerSearch) >= 0).length
        );
    }, [allItems, search]);

    useReplaceSearchParamsEffect({ search: search });

    const colCount = 7;

    return (
        <>
            <Banner fluid>
                <Row>
                    <Col>
                        <h1>{t('calendarEventsList.heading', 'Events')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <ButtonGroup>
                                <LinkContainer to="/administration/events/add">
                                    <Button color="primary">
                                        <FontAwesomeIcon icon="plus" /><> {t('calendarEventsList.add', 'Add')}</>
                                    </Button>
                                </LinkContainer>

                                <a className="btn btn-primary" href="/api/export/calendarEvents" download>
                                    <FontAwesomeIcon icon="download" /><> {t('calendarEventsList.export', 'Export to Excel')}</>
                                </a>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th className="d-table-cell d-md-none">{t('calendarEventsList.event', 'Event')}</th>
                            <th className="d-none d-md-table-cell">{t('calendarEventsList.name', 'Name')}</th>
                            <th className="d-none d-md-table-cell">{t('calendarEventsList.start', 'Date')}</th>
                            <th className="d-none d-md-table-cell">{t('calendarEventsList.eventType', 'Type of event')}</th>
                            <th className="d-none d-md-table-cell">{t('calendarEventsList.providers', 'Providers')}</th>
                            <th className="">&nbsp;</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items?.map(item => {
                                const editUrl = `/administration/events/edit/${item.id}`;
                                const onDoubleClick = () => history.push(editUrl);

                                return (
                                    <React.Fragment key={item.id}>
                                        <tr onDoubleClick={onDoubleClick}>
                                            <td className="d-table-cell d-md-none">
                                                <div>{item.name}</div>
                                                <Row noGutters>
                                                    <Col xs="auto">
                                                        <EventTypeColorBlock color={item.eventType.color} />
                                                    </Col>
                                                    <Col>
                                                        {item.eventType.name}
                                                    </Col>
                                                </Row>
                                                <div className="text-muted">
                                                    {
                                                        item.schedules.map(item => (
                                                            <div key={item.id}>
                                                                {scheduleDescription(item)}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </td>
                                            <td className="d-none d-md-table-cell">{item.name}</td>
                                            <td className="d-none d-md-table-cell">
                                                {
                                                    item.schedules.map(item => (
                                                        <div key={item.id}>
                                                            {scheduleDescription(item)}
                                                        </div>
                                                    ))
                                                }
                                            </td>
                                            <td className="d-none d-md-table-cell">
                                                <Row noGutters>
                                                    <Col xs="auto">
                                                        <EventTypeColorBlock color={item.eventType.color} />
                                                    </Col>
                                                    <Col>
                                                        {item.eventType.name}
                                                    </Col>
                                                </Row>
                                            </td>
                                            <td className="d-none d-md-table-cell">
                                                {
                                                    item.providers.map(item => (
                                                        <div key={item.id}>
                                                            {item.provider.name}
                                                        </div>
                                                        ))
                                                }
                                            </td>
                                            <td className="">
                                                <UploadedImagePreview size="sm" style={{ maxWidth: '150px' }} src={item.imageBlobReference?.url ?? ''} />
                                            </td>
                                            <td>
                                                <TableRowButtons>
                                                    <ButtonGroup>
                                                        <LinkContainer to={`/administration/events/edit/${item.id}`}>
                                                            <Button color="primary" outline>
                                                                <FontAwesomeIcon icon="edit" />
                                                                <> {t('common.edit', 'Edit')}</>
                                                            </Button>
                                                        </LinkContainer>
                                                        <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                                            <DropdownToggle color="primary" outline caret>
                                                                <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <LinkContainer to={`/administration/events/delete/${item.id}`}>
                                                                    <DropdownItem className="text-danger">
                                                                        <FontAwesomeIcon icon="trash" />
                                                                        <> {t('common.delete', 'Delete')}</>
                                                                    </DropdownItem>
                                                                </LinkContainer>
                                                            </DropdownMenu>
                                                        </ButtonDropdown>
                                                    </ButtonGroup>
                                                </TableRowButtons>
                                            </td>
                                        </tr>
                                        {/*<tr onDoubleClick={onDoubleClick}>*/}
                                        {/*    <td colSpan={colCount} style={{ display: 'none' }}>*/}
                                        {/*        */}{/* This row is here for maintaining visible striping in the table only */}
                                        {/*    </td>*/}
                                        {/*</tr>*/}
                                        {/*<tr onDoubleClick={onDoubleClick}>*/}
                                        {/*    <td colSpan={colCount} style={{ borderTopStyle: 'none' }}>*/}
                                        {/*        <CalendarMonthEventPreview model={item} image={item.imageBlobReference} />*/}
                                        {/*    </td>*/}
                                        {/*</tr>*/}
                                    </React.Fragment>
                                );
                            })
                        }
                        
                        <ConditionalFragment showIf={isLoading && !items?.length}>
                            <tr><td colSpan={colCount}><LoadingIndicator fullWidth /></td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && !items?.length && !hasMore()}>
                            <tr><td colSpan={colCount}>
                                <NoResultsFound search={search} />
                            </td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && hasMore()}>
                            <tr><td colSpan={colCount}>
                                <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                                <LoadingIndicator fullWidth />
                            </td></tr>
                        </ConditionalFragment>
                    </tbody>
                </Table>
            </MainContainer>
        </>
    );
};
