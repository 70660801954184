import * as React from 'react';
import { generateTrackedUrl } from "../../../utilities/generateTrackedUrl";


export interface TrackedLinkProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
    calendarEventUrlId?: string,
    calendarEventId?: string,
}

/**
 * An <a> link that replaces the href provided with a url that includes tracking of the users click and redirecting to the original href.
 * @param props
 */
export const TrackedLink = (props: TrackedLinkProps) => {
    const {
        href,
        calendarEventUrlId,
        calendarEventId,
        children,
        ...rest
    } = props;

    const trackingUrl = React.useMemo(() => generateTrackedUrl(href ?? '', calendarEventUrlId, calendarEventId), [href, calendarEventUrlId, calendarEventId]);

    return (
        <a href={trackingUrl} {...rest}>{children}</a>
        );
};