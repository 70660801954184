import './footer.scss';
import * as React from 'react';
import { AppFooter } from '@coreui/react';
import { Nav } from 'reactstrap';
import { FooterNavigation } from './FooterNavigation';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
    const { t } = useTranslation();

    return (
        <AppFooter className="footer">
            <Nav className="footer-nav">
                <FooterNavigation />
            </Nav>
            <div className="footer-company-text">
                {t('footer.companyText', 'Hub4Leaders Ltd. Elmswood Court, Springwood Close, Tytherington Business Park, Macclesfield, SK10 2XF | Registered Number: 836837')}
            </div>
        </AppFooter>
    );
};

