/**
 * Generate a route URL for a calendar event (trying to make the URL as user friendly as possible).
 */
export function generateCalendarEventRouteUrl(schedule: GenerateClendarEventRouteScheduleModel | undefined | null) {
    // If we have no schedule passed in, just return /calendar.
    if (!schedule) {
        return '/calendar';
    }

    // If our schedule has no calendar event (or that has no name) then return /calendar/event/${id}
    if (!schedule?.calendarEvent?.name) {
        return `/calendar/event/${schedule.id}`;
    }

    // If we have a name for the calendar event, try use it in the URL so it looks nicer (even though its actually ignored in all code).
    const nameUrlComponent = encodeURIComponent(
        schedule.calendarEvent.name
            .replace(' ', '-')
            .replace('/', '-')
            .replace('\\', '-')
            .toLocaleLowerCase()
    );

    return `/calendar/${nameUrlComponent}/${schedule.id}`;
}


export interface GenerateClendarEventRouteScheduleModel {
    id: string,
    calendarEvent?: {
        id: string,
        name: string,
    },
}
