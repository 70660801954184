import './calendarHome.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Card, Col, Collapse, ListGroup, Row } from 'reactstrap';
import { CalendarMonth } from '../shared/calendar/CalendarMonth';
import { MainContainer } from '../shared/MainContainer';
import moment, { Moment } from 'moment';
import { CalendarMonthHeadline } from './CalendarMonthHeadline';
import { useCalendarHomeViewModel } from '../../api/main/calendarEventSchedules/viewModels/useCalendarHomeViewModel';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { ConditionalFragment } from 'react-conditionalfragment';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { CalendarEventCardItem } from './CalendarEventCardItem';
import { useToggleState } from 'use-toggle-state';
import { CalendarEventModal } from './CalendarEventModal';
import { isNullOrUndefined } from 'util';
import { useHistory, useParams } from 'react-router';
import { scheduleDescription } from '../../utilities/scheduleDescription';
import { useGlobalSearchText } from '../../globalState/globalSearchText/useGlobalSearchText';
import { generateCalendarEventRouteUrl } from '../../utilities/generateCalendarEventRouteUrl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NoResultsFound } from '../shared/NoResultsFound';
import { CalendarHomeStickyToolbar } from './CalendarHomeStickyToolbar';
import { CalendarDayCard } from './CalendarDayCard';
import { useCalendarHomeSupportingData } from '../../api/main/calendarEventSchedules/viewModels/useCalendarHomeSupportingData';
import { TagSelector } from '../tags/TagSelector';
import { EventTypeSelector } from '../calendarEventTypes/EventTypeSelector';
import { Guid } from 'guid-string';
import { useCreateAnalyticEventCallback } from '../../api/main/analyticEvents/useCreateAnalyticEventCallback';
import { CalendarMode, useCalendarPreferencesCalendarMode } from '../../globalState/calendarPreferences/useCalendarPreferencesCalendarMode';
import { useCalendarPreferencesExcludedSchoolPhases } from '../../globalState/calendarPreferences/useCalendarPreferencesExcludedSchoolPhases';
import { useCalendarPreferencesExcludedEventTypes } from '../../globalState/calendarPreferences/useCalendarPreferencesExcludedEventTypes';
import { useCalendarPreferencesExcludedSchoolTypes } from '../../globalState/calendarPreferences/useCalendarPreferencesExcludedSchoolTypes';
import { InstallPwaPrompt } from '../shared/pwaInstall/InstallPwaPrompt';
import { generateCalendarHomeScheduleViewModel } from './generateCalendarHomeScheduleViewModel';
import { CalendarHomeScheduleViewModel } from './CalendarHomeScheduleViewModel';
import { useBlobReferences } from '../../api/main/blobReferences/useBlobReferences';

export interface CalendarHomeProps {
    /**
     * When set, the calendar will start in this mode regardless of the user's saved mode (without overwriting the saved mode).
     */
    startInCalendarMode?: CalendarMode,
}

/**
 * Main Calendar page showing the calendar of events.
 */
export const CalendarHome = (props: CalendarHomeProps) => {
    const { startInCalendarMode } = props;

    const { t } = useTranslation();

    // Get the calendar event from the route if one is provided as the default event to open.
    const {
        calendarEventScheduleId: _calendarEventScheduleId
    } = useParams<{ calendarEventScheduleId: string | undefined}>();
    const [calendarEventScheduleId, setCalendarEventScheduleId] = React.useState<string | undefined>(_calendarEventScheduleId);

    // Current date being shown in the calendar.
    const [calendarDate, setCalendarDate] = React.useState<Moment>(() => moment().startOf('month'));

    // Get the data we need from the apis.
    const { data: { schedules: _schedules, calendarEvents, calendarEventCalendarEventProviders, calendarEventUrls, itemTagLinks }, isLoading, errors: loadErrors } = useCalendarHomeViewModel();
    const { data: { calendarEventTypes, itemTags, headlines, providers }, errors: loadSupportingDataErrors } = useCalendarHomeSupportingData();

    // Load the images seperatly as we don't want any delay related to loading them to impact the display of everything else.
    const { data: { items: imageBlobReferences } } = useBlobReferences();

    // Get the search text from the gobal state.
    const [search, setSearch] = useGlobalSearchText();

    // Users preference for including/excluding Event types, School Phases, and School Types.
    const [isEventTypeExcluded, toggleEventTypeExcluded] = useCalendarPreferencesExcludedEventTypes();
    const [isSchoolPhaseExcluded, toggleSchoolPhaseExcluded] = useCalendarPreferencesExcludedSchoolPhases();
    const [isSchoolTypeExcluded, toggleSchoolTypeExcluded] = useCalendarPreferencesExcludedSchoolTypes();

    // Generate data in the structure of the view model expected by most of our child components.
    const schedulesViewModel = React.useMemo((): Array<CalendarHomeScheduleViewModel> | undefined => {
        if (!_schedules) {
            return undefined;
        }

        const ret = _schedules.map(item => generateCalendarHomeScheduleViewModel(
            item, {
                calendarEvents,
                calendarEventProviderLinks: calendarEventCalendarEventProviders,
                calendarEventUrls,
                itemTagLinks,
                eventTypes: calendarEventTypes,
                providers,
                itemTags,
                blobReferences: imageBlobReferences,
        }));
        return ret;
    }, [_schedules, calendarEvents, calendarEventCalendarEventProviders, calendarEventUrls,
        itemTagLinks, calendarEventTypes, providers, itemTags, imageBlobReferences]);

    // Filter the schedules.
    const schedules = React.useMemo(() => {
        if (!schedulesViewModel) {
            return schedulesViewModel;
        }

        let ret = schedulesViewModel;

        // Filter by the user's search.
        if (search) {
            const lowerSearch = search.toLocaleLowerCase();
            ret = ret.filter(item =>
                scheduleDescription(item).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.calendarEvent?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.calendarEvent?.eventType?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.calendarEvent?.providers ?? []).filter(it => (it.provider?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0).length
                || (item.calendarEvent?.tagLinks ?? []).filter(it => (it.itemTag?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0).length
                );
        }

        // Apply the tags and event type filters.
        ret = ret.filter(item => {
            // If the event type is excluded, exclude the item.
            if (isEventTypeExcluded(item.calendarEvent?.calendarEventTypeId ?? '')) {
                return false;
            }

            // If the item is flagged with school types, it needs to have at least one school type selected that is not
            // excluded in the users filter.
            const schoolTypeLinks = (item.calendarEvent?.tagLinks ?? []).filter(it => it.itemTag?.tagType === 'SchoolType');
            if (schoolTypeLinks.length) {
                let notExcludedCount = 0;
                for (const link of schoolTypeLinks) {
                    if (!isSchoolTypeExcluded(link.itemTag?.id ?? '')) {
                        ++notExcludedCount;
                    }
                }

                // If all the school types were excluded, don't show this item.
                if (notExcludedCount <= 0) {
                    return false;
                }
            }

            // If the item is flagged with school phases, it needs to have at least one school phase selected that is not
            // excluded in the users filter.
            const schoolPhaseLinks = (item.calendarEvent?.tagLinks ?? []).filter(it => it.itemTag?.tagType === 'SchoolPhase');
            if (schoolPhaseLinks.length) {
                let notExcludedCount = 0;
                for (const link of schoolPhaseLinks) {
                    if (!isSchoolPhaseExcluded(link.itemTag?.id ?? '')) {
                        ++notExcludedCount;
                    }
                }

                // If all the school types were excluded, don't show this item.
                if (notExcludedCount <= 0) {
                    return false;
                }
            }

            // If we get here we can show this item.
            return true;
        });

        return ret;
    }, [schedulesViewModel, search, isSchoolTypeExcluded, isSchoolPhaseExcluded, isEventTypeExcluded]);

    // Get the schedules grouped by day as shown in the list view for the current month.
    const { schedules: listViewGroupedSchedules, pastEventsCount, futureEventsCount, } = React.useMemo(() => {
        if (!schedules) {
            return {
                schedules: [],
                pastEventsCount: 0,
                futureEventsCount: 0,
            };
        }

        let ret: Array<{
            monthStart: Moment,
            days: Array<{ date: Moment, isPast: boolean, items: Array<CalendarHomeScheduleViewModel> }>
        }> = [];
        let pastEventsCount: number = 0;
        let futureEventsCount: number = 0;

        const today = moment().startOf('day').toISOString();

        for (const schedule of schedules) {
            const monthStart = moment(schedule.start).startOf('month');
            const dayStart = moment(schedule.start).startOf('day');

            // Find or create the month entry to put ourselves into.
            let monthEntry = ret.find(it => it.monthStart.toISOString() === monthStart.toISOString());
            if (!monthEntry) {
                monthEntry = { monthStart: monthStart, days: [] };
                ret.push(monthEntry);
            }

            // Find or create the day entry to put ourselves into
            let dayEntry = monthEntry.days.find(it => it.date.toISOString() === dayStart.toISOString());
            if (!dayEntry) {
                dayEntry = {
                    date: dayStart,
                    isPast: (dayStart.toISOString() < today)? true: false,
                    items: []
                };
                monthEntry.days.push(dayEntry);
            }

            dayEntry.items.push(schedule);

            // Count how many past events we've got (because we hide them in the list view).
            if (dayEntry.isPast) {
                ++pastEventsCount;
            } else {
                ++futureEventsCount;
            }
        }

        return {
            schedules: ret,
            pastEventsCount,
            futureEventsCount,
        };
    }, [schedules]);


    // Get the schedules for this week, grouped by day.
    const thisWeekSchedules = React.useMemo(() => {
        if (!schedules) {
            return [];
        }

        const weekStart = moment().startOf('week');
        const weekEnd = moment().add(1, 'week');

        let ret: Array<{ date: Moment, items: Array<CalendarHomeScheduleViewModel> }> = [];

        for (const schedule of schedules) {
            const date = moment(schedule.start).startOf('day');

            if (date < weekStart || date >= weekEnd) {
                continue;
            }

            let entry = ret.find(it => it.date.toISOString() === date.toISOString());
            if (!entry) {
                entry = { date: date, items: [] };
                ret.push(entry);
            }

            entry.items.push(schedule);
        }

        return ret;
    }, [schedules]);

    // Get the featured event schedules for planning ahead.
    const featuredSchedules = React.useMemo(() => {
        if (!schedules) {
            return [];
        }

        const thisWeekEnd = moment().endOf('week');
        const planAheadEnd = moment().startOf('day').add(6, 'month');

        let ret: Array<{ date: Moment, items: Array<CalendarHomeScheduleViewModel> }> = [];

        // Filter to show only featured items.
        const featuredSechedules = schedules.filter(item => item.calendarEvent?.isFeatured);

        // Filter out anything too with a date we're not interested in and group by day.
        for (const schedule of featuredSechedules) {
            const date = moment(schedule.start).startOf('day');

            if (date < thisWeekEnd || date >= planAheadEnd) {
                continue;
            }

            let entry = ret.find(it => it.date.toISOString() === date.toISOString());
            if (!entry) {
                entry = { date: date, items: [] };
                ret.push(entry);
            }

            entry.items.push(schedule);
        }

        return ret;
    }, [schedules]);

    // Showing of an event as a modal based on it's schedule.
    const [calendarEventModalIsOpen, toggleCalendarEvetModal] = useToggleState(!!calendarEventScheduleId);
    const history = useHistory();
    const [createAnalyticEvent] = useCreateAnalyticEventCallback();
    const openEvent = React.useCallback((schedule: { id: string, calendarEventId: string, }) => {
        setCalendarEventScheduleId(schedule.id);
        toggleCalendarEvetModal(true);
        history.push(generateCalendarEventRouteUrl(schedule));

        // Log the opening of the event in the analytics.
        createAnalyticEvent({
            id: Guid.newGuid(),
            eventType: 'CalendarEventOpen',
            calendarEventScheduleId: schedule.id,
            calendarEventId: schedule.calendarEventId,
        });
    }, [setCalendarEventScheduleId, toggleCalendarEvetModal, history, createAnalyticEvent]);
    const closeEvent = React.useCallback(() => {
        toggleCalendarEvetModal(false);
        history.push(generateCalendarEventRouteUrl(undefined));
    }, [toggleCalendarEvetModal, history]);

    // Automatically open the event modal when we are loaded with an event passed in the route.
    const [previousCalendarEventScheduleId, setPreviousCalendarEventScheduleId] = React.useState<string | undefined>(calendarEventScheduleId);
    React.useEffect(() => {
        // If the id hasn't changed in the route, do nothing.
        if (calendarEventScheduleId === previousCalendarEventScheduleId) {
            return;
        }

        // Otherwise we'll want to open the modal automatically.
        toggleCalendarEvetModal(!isNullOrUndefined(calendarEventScheduleId));
        setPreviousCalendarEventScheduleId(calendarEventScheduleId);
    }, [calendarEventScheduleId, previousCalendarEventScheduleId, setPreviousCalendarEventScheduleId, toggleCalendarEvetModal]);

    const eventModelForModal = schedules?.find(item => item.id === calendarEventScheduleId);

    // User can switch between two views.
    const [_calendarMode, _setCalendarMode] = useCalendarPreferencesCalendarMode();
    const [useStartInCalendarMode, setUseStartInCalendarMode] = React.useState<boolean>(!!startInCalendarMode);
    const calendarMode = startInCalendarMode && useStartInCalendarMode ? startInCalendarMode : _calendarMode;
    const setCalendarMode = React.useCallback(value => {
        setUseStartInCalendarMode(false);
        _setCalendarMode(value);
    }, [_setCalendarMode, setUseStartInCalendarMode]);

    // User can toggle showing older items in the list or not.
    const [isShowingPastItemsInList, toggleShowingPastItemsInList] = useToggleState(false);

    // Check that lets us customise the expierence for the current month
    const isCalendarDateCurrentMonth = React.useMemo(() => {
        return (calendarDate.startOf('month').toISOString() === moment().startOf('month').toISOString());
    }, [calendarDate]);

    // Filter
    const [isFilterOpen, toggleFilter] = useToggleState(false);
    const filterSummary = React.useMemo(() => {        
        // Work out details needed to let us show a useful summary of the applied filter.
        return {
            includedEventTypes: calendarEventTypes?.filter(it => !isEventTypeExcluded(it.id)) ?? [],
            excludedEventTypes: calendarEventTypes?.filter(it => isEventTypeExcluded(it.id)) ?? [],
            includedSchoolTypes: itemTags?.filter(it => it.tagType === 'SchoolType')?.filter(it => !isSchoolTypeExcluded(it.id)) ?? [],
            excludedSchoolTypes: itemTags?.filter(it => it.tagType === 'SchoolType')?.filter(it => isSchoolTypeExcluded(it.id)) ?? [],
            includedSchoolPhases: itemTags?.filter(it => it.tagType === 'SchoolPhase')?.filter(it => !isSchoolPhaseExcluded(it.id)) ?? [],
            excludedSchoolPhases: itemTags?.filter(it => it.tagType === 'SchoolPhase')?.filter(it => isSchoolPhaseExcluded(it.id)) ?? [],
        }
    }, [isSchoolPhaseExcluded, isSchoolTypeExcluded, isEventTypeExcluded, calendarEventTypes, itemTags]);

    // Render the UI.
    return (
        <div className="calendar-home">
            <MainContainer color="transparent" fluid>
                <AlertOnErrors errors={[loadErrors, loadSupportingDataErrors]} />
                <Row>
                    <Col>
                        <CalendarHomeStickyToolbar>
                            <Row>
                                <Col>
                                    <h1>
                                        {
                                            search ? (
                                                <>
                                                    {t('calendarHome.main.searchingTitle', 'Events matching "{{ search }}"', { search })}
                                                    <> </>
                                                </>
                                                        ): (
                                                        <>
                                                        {t('calendarHome.main.title', 'What\'s coming up')}
                                                        </>
                                                    )
                                        }

                                        <> </>
                                        <ButtonGroup className="calendar-home-title-filter-button-group mb-1">
                                            <Button size="sm" color="secondary" className="" onClick={() => toggleFilter()}>
                                                <span>
                                                    {
                                                        filterSummary.includedEventTypes.length > 0 && filterSummary.excludedEventTypes.length === 0 ? t('calendarHome.filterSummary.allEventTypes', 'All types of event; ')
                                                            : filterSummary.includedEventTypes.length === 0 ? t('calendarHome.filterSummary.noEventTypes', 'No types of event selected; ')
                                                            : filterSummary.includedEventTypes.length === 1 ? t('calendarHome.filterSummary.oneEventTypes', 'Type of event: {{name}}; ', { name: filterSummary.includedEventTypes[0].name ?? '' })
                                                                : t('calendarHome.filterSummary.multipleEventTypes', '{{count, #,0}} types of events; ', { count: filterSummary.includedEventTypes.length })
                                                    }
                                                </span>
                                                <span>
                                                    {
                                                        filterSummary.includedSchoolTypes.length > 0 && filterSummary.excludedSchoolTypes.length === 0 ? t('calendarHome.filterSummary.allSchoolTypes', 'All school types; ')
                                                            : filterSummary.includedSchoolTypes.length === 0 ? t('calendarHome.filterSummary.noSchoolTypes', 'No school types selected; ')
                                                            : filterSummary.includedSchoolTypes.length === 1 ? t('calendarHome.filterSummary.oneSchoolTypes', 'School type: {{name}}; ', { name: filterSummary.includedSchoolTypes[0].name ?? '' })
                                                                : t('calendarHome.filterSummary.multipleSchoolTypes', '{{count, #,0}} School types; ', { count: filterSummary.includedSchoolTypes.length })
                                                    }
                                                </span>
                                                <span>
                                                    {
                                                        filterSummary.includedSchoolPhases.length > 0 && filterSummary.excludedSchoolPhases.length === 0 ? t('calendarHome.filterSummary.allSchoolPhases', 'All phases')
                                                            : filterSummary.includedSchoolPhases.length === 0 ? t('calendarHome.filterSummary.noSchoolPhases', 'No phases selected')
                                                            : filterSummary.includedSchoolPhases.length === 1 ? t('calendarHome.filterSummary.oneSchoolPhases', 'Phase: {{name}}', { name: filterSummary.includedSchoolPhases[0].name ?? '' })
                                                                : t('calendarHome.filterSummary.multipleSchoolPhases', '{{count, #,0}} School phases', { count: filterSummary.includedSchoolPhases.length })
                                                    }
                                                </span>

                                                <> </>
                                                <FontAwesomeIcon icon={isFilterOpen ? 'caret-up' : 'caret-down'} />
                                                <span className="sr-only">
                                                    {t('calendarHome.toggleFilter', 'Toggle filter')}
                                                </span>
                                            </Button>
                                            <ConditionalFragment showIf={!!search}>
                                                <Button size="sm" color="danger" onClick={() => setSearch('')}>
                                                    <FontAwesomeIcon icon="times" /><> </>
                                                    {t('calendarHome.main.clearSearchButton', 'Clear search')}
                                                </Button>
                                            </ConditionalFragment>
                                        </ButtonGroup>
                                    </h1>
                                </Col>
                                <ConditionalFragment showIf={isLoading}>
                                    <Col xs="auto">
                                        <LoadingIndicator size="sm" />
                                    </Col>
                                </ConditionalFragment>
                                <Col xs="12" md="auto" className="text-right">
                                    <ConditionalFragment showIf={calendarMode === 'calendar' && !isCalendarDateCurrentMonth}>
                                        <Button color="primary" outline className="mb-2" onClick={() => setCalendarDate(moment().startOf('day'))}>
                                            <FontAwesomeIcon icon="calendar-day" /><> </>
                                            {t('calendarHome.gotoToday', 'Go to today')}
                                        </Button>
                                    </ConditionalFragment>
                                    <ButtonGroup className="mb-2">
                                        <Button outline={calendarMode !== 'calendar'} onClick={() => setCalendarMode('calendar')}>
                                            <FontAwesomeIcon icon={['far', 'calendar-alt']} /><> </>
                                            {t('calendarHome.views.calendar', 'Calendar')}
                                        </Button>
                                        <Button outline={calendarMode !== 'list'} onClick={() => setCalendarMode('list')}>
                                            <FontAwesomeIcon icon={['far', 'list-alt']} /><> </>
                                            {t('calendarHome.views.text', 'List')}
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                            <Collapse isOpen={isFilterOpen}>
                                <Card body className="calendar-home-title-filter-dropdown">
                                    <Row>
                                        <Col xs={12} md={3} lg={2}>
                                            {t('calendarHome.filter.eventTypes', 'Types of event:')}
                                        </Col>
                                        <Col>
                                            <EventTypeSelector items={calendarEventTypes ?? []} isSelected={id => !isEventTypeExcluded(id)} toggle={id => toggleEventTypeExcluded(id)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={3} lg={2}>
                                            {t('calendarHome.filter.schoolTypes', 'School type:')}
                                        </Col>
                                        <Col>
                                            <TagSelector items={itemTags?.filter(it => it.tagType === 'SchoolType') ?? []} isSelected={id => !isSchoolTypeExcluded(id)} toggle={id => toggleSchoolTypeExcluded(id)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={3} lg={2}>
                                            {t('calendarHome.filter.schoolTypes', 'School phase:')}
                                        </Col>
                                        <Col>
                                            <TagSelector items={itemTags?.filter(it => it.tagType === 'SchoolPhase') ?? []} isSelected={id => !isSchoolPhaseExcluded(id)} toggle={id => toggleSchoolPhaseExcluded(id)} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Collapse>
                        </CalendarHomeStickyToolbar>

                        <ConditionalFragment showIf={calendarMode === 'calendar'}>
                            <CalendarMonthHeadline
                                date={calendarDate} changeDate={(date) => setCalendarDate(date)}
                                headlines={headlines ?? []}
                                images={imageBlobReferences ?? []}
                                calendarMode={calendarMode}
                            />
                            <CalendarMonth date={calendarDate} schedules={schedules} onEventClick={schedule => openEvent(schedule)} />
                        </ConditionalFragment>

                        <ConditionalFragment showIf={calendarMode === 'list'}>
                            <ConditionalFragment showIf={pastEventsCount > 0}>
                                <div className="calendar-home-list-hidden-past-items-message" onClick={() => toggleShowingPastItemsInList()}>
                                    {
                                        isShowingPastItemsInList ?
                                            t('common.showingPastEventsMessage', 'We\'re including {{ count, #, 0}} historical events.  Click here to hide these historical events.', { count: pastEventsCount })
                                            : t('common.hiddingPastEventsMessage', 'We\'ve hidden {{count, #,0}} historical events for you to show you only future events.  Click here to view these historical events.', { count: pastEventsCount })
                                    }
                                    <> </>
                                    <FontAwesomeIcon icon={isShowingPastItemsInList? 'caret-up': 'caret-down'} />
                                </div>
                            </ConditionalFragment>

                            {
                                listViewGroupedSchedules.map(month => {
                                    // Only show months with only past items if the user has asked for it.
                                    if (!isShowingPastItemsInList && !month.days.filter(day => !day.isPast).length) {
                                        return null;
                                    }

                                    return (
                                        <React.Fragment key={month.monthStart.toISOString()}>
                                            <CalendarMonthHeadline
                                                date={month.monthStart} changeDate={(date) => setCalendarDate(date)}
                                                headlines={headlines ?? []}
                                                images={imageBlobReferences ?? []}
                                                calendarMode={calendarMode}
                                            />
                                            <div className="mb-3">
                                                {/* Need some space between the headline and the items in list mode */}
                                            </div>
                                            {
                                                month.days?.map(day => {
                                                    // Only show past items if the user has asked for it.
                                                    if (!isShowingPastItemsInList && day.isPast) {
                                                        return null;
                                                    }

                                                    return (
                                                        <CalendarDayCard key={day.date.toISOString()} date={day.date}>
                                                            <ListGroup flush>
                                                                {
                                                                    day.items.map(item => (
                                                                        <CalendarEventCardItem key={item.id} date={day.date} schedule={item}
                                                                            onClick={() => openEvent(item)}
                                                                        />
                                                                    ))
                                                                }
                                                            </ListGroup>
                                                        </CalendarDayCard>
                                                    );
                                                })
                                            }
                                        </React.Fragment>
                                    );
                                })
                            }

                            <ConditionalFragment showIf={!isLoading && !futureEventsCount}>
                                {
                                    !listViewGroupedSchedules?.length ? (
                                        <NoResultsFound search={search} icon={['far', 'calendar-times']}>
                                            <p>
                                                {
                                                    search ?
                                                        t('calendarHome.nothingMatchedSearch', 'Sorry there were no events matching your search of "{{search}}."', { date: calendarDate, search: search })
                                                        : t('calendarHome.nothingFound', 'There are no events listed at the moment.', { date: calendarDate, })
                                                }
                                            </p>
                                        </NoResultsFound>
                                    )
                                        : !isShowingPastItemsInList && futureEventsCount === 0 && pastEventsCount > 0 ? (
                                            <NoResultsFound search={search} icon={['far', 'calendar-times']}>
                                                <p>
                                                    {
                                                        search ?
                                                            t('calendarHome.nothingMatchedSearchFuture', 'Sorry there were no future events matching your search for "{{search}}."', { date: calendarDate, search: search })
                                                            : t('calendarHome.nothingFoundFuture', 'There are no future events listed at the moment.', { date: calendarDate, })
                                                    }
                                                </p>
                                                <p>
                                                    <Button size="sm" onClick={() => toggleShowingPastItemsInList()}>
                                                        {t('calendarHome.nothingFoundFutureViewPast', 'View the {{value, #,0}} historical events', { value: pastEventsCount })}
                                                    </Button>
                                                </p>
                                            </NoResultsFound>
                                            )
                                            : null
                                }
                                
                            </ConditionalFragment>
                        </ConditionalFragment>
                    </Col>
                    <Col xs={12} lg={4} xl={3}>
                        <h2>
                            {t('calendarHome.thisWeek.title', 'This week')}
                        </h2>

                        {
                            thisWeekSchedules.map(day => (
                                <CalendarDayCard key={day.date.toISOString()} date={day.date}
                                    title={t('calendarHome.thisWeek.dayHeading', '{{date, dddd Do}}', { date: day.date })}
                                    >
                                    <ListGroup flush>
                                        {
                                            day.items.map(item => (
                                                <CalendarEventCardItem key={item.id} date={day.date} schedule={item}
                                                    onClick={() => openEvent(item)}
                                                />
                                            ))
                                        }
                                    </ListGroup>
                                </CalendarDayCard>
                                ))
                        }

                        <h2>
                            {t('calendarHome.planAhead.title', 'Plan ahead')}
                        </h2>
                        {
                            featuredSchedules.map(day => (
                                <CalendarDayCard key={day.date.toISOString()} date={day.date}
                                    title={t('calendarHome.planAhead.dayHeading', '{{date, Do MMMM}}', { date: day.date })}
                                    >
                                    <ListGroup flush>
                                            {
                                                day.items.map(item => (
                                                    <CalendarEventCardItem key={item.id} date={day.date} schedule={item}
                                                        onClick={() => openEvent(item)}
                                                    />
                                                ))
                                            }
                                        </ListGroup>
                                </CalendarDayCard>
                            ))
                        }
                    </Col>
                </Row>
            </MainContainer>
            <ConditionalFragment showIf={calendarEventModalIsOpen && !!eventModelForModal}>
                <CalendarEventModal
                    isOpen={calendarEventModalIsOpen} toggle={() => closeEvent()}
                    model={eventModelForModal as CalendarHomeScheduleViewModel /* We know its not undefined at this point so can safely cast. */}
                />
            </ConditionalFragment>

            <InstallPwaPrompt />
        </div>
        );
};