import * as React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { NavLinkTrackActive } from '../shared/NavLinkTrackActive';

/**
 * Footer navigation menu for the app. 
 */
export const FooterNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to="/about">
                    {t('footerNavigation.about', 'About TheSchoolYear')}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="https://theschoolbus.net/" target="_blank" rel="noreferrer">
                    {t('footerNavigation.theSchoolBus', 'TheSchoolBus')}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to="/terms-and-conditions">
                    {t('footerNavigation.termsAndConditions', 'Terms and conditions')}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to="/privacy-policy">
                    {t('footerNavigation.privacyPolicy', 'Privacy policy')}
                </NavLink>
            </NavItem>
        </>
    );
};
