import * as React from "react";
import { Form, Alert, FormGroup, Label, Spinner, Row, Col, Button } from "reactstrap";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useSendPasswordResetEmailCallback } from "../../api/account";
import { ForgotPassword as ForgotPasswordModel } from '../../api/account/models/ForgotPassword';
import { useTranslation } from "react-i18next";
import { AccountBackground } from "./AccountBackground";
import { SideContainer } from "../shared/sideContainer/SideContainer";
import { FormButtons } from "../shared/FormButtons";
import { LinkContainer } from "react-router-bootstrap";
import { LoginPartialPage } from "../cmsPages/LoginPartialPage";

/**
 * Request a password reset emai because the user has forgotten their password.
 */
export const ForgotPassword = () => {
    const { t } = useTranslation();
    const [emailSent, setEmailSent] = React.useState<boolean>(false);
    const [sendResetEmail, { isExecuting: isSendingResetEmail, errors: sendResetEmailErrors }] = useSendPasswordResetEmailCallback();

    const { model, change } = useChanges<ForgotPasswordModel>({ email: '' });

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        if (!model) {
            return;
        }

        const rules = {
            email: () => !model.email ? t('forgotPassword.emailRequired', 'Email is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Render the UI.
    // 
    return (
        <AccountBackground centerChildren="vertically">
            <Row noGutters>
                <Col xs={12} md={6} xl={5}>
                    <SideContainer fluid>
                        <h1>{t('forgotPassword.forgotPasswordHeading', 'Forgot your password?')}</h1>
                        <p>
                            {t('forgotPassword.helpText', 'Enter your email and we\'ll send you a link you can use to recover your account and set a new password.')}
                        </p>
                        <Form onSubmit={async e => {
                            e.preventDefault();
                            const ok = await sendResetEmail(model.email);
                            setEmailSent(ok);
                        }}>

                            <AlertOnErrors simple errors={sendResetEmailErrors} />
                            {
                                emailSent ? (
                                    <Alert color="success">
                                        <Row>
                                            <Col>
                                                {t('forgotPassword.emailHasBeenSent', 'Please check your email and follow the link to reset your password.')}
                                            </Col>
                                            <Col xs="auto">
                                                <ButtonAsync type="submit" color="success" isExecuting={isSendingResetEmail}
                                                    executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
                                                    {t('common.resendEmail', 'Resend email')}
                                                </ButtonAsync>
                                            </Col>
                                        </Row>
                                    </Alert>
                                ) : null
                            }
                            <FormGroup>
                                <Label htmlFor="email">{t('forgotPassword.email', 'Email')}</Label>
                                <ValidatedInput type="email" name="email" autoComplete="username" value={model.email} onChange={e => change({ email: e.currentTarget.value })} onBlur={e => validate('email')} validationErrors={validationErrors['email']} />
                            </FormGroup>

                            <FormButtons>
                                <Row>
                                    <Col>
                                    </Col>
                                    <Col xs="auto">
                                        <ButtonAsync type="submit" color="primary" isExecuting={isSendingResetEmail}
                                            executingChildren={<><Spinner size="sm" /> {t('common.sending', 'Sending...')}</>}>
                                            {t('forgottenPassword.send', 'Send password reset email')}
                                        </ButtonAsync>
                                    </Col>
                                </Row>
                            </FormButtons>
                        </Form>

                        <h2>
                            {t('forgottenPassword.otherOptionsHeading', 'Need to login or sign up instead?')}
                        </h2>
                        <p>
                            {t('forgottenPassword.otherOptionsText', 'If you don\'t need to reset your password but already have an account or want to create one you can sign in or sign up instead.')}
                        </p>
                        <div className="text-center">
                            <LinkContainer to="/authentication/login">
                                <Button color="primary">
                                    {t('forgottenPassword.signInButton', 'Sign in')}
                                </Button>
                            </LinkContainer>
                            <> </>
                            {t('forgottenPassword.or', 'or')}
                            <> </>
                            <LinkContainer to="/account/register">
                                <Button color="primary">
                                    {t('forgottenPassword.registerButton', 'Sign up now')}
                                </Button>
                            </LinkContainer>
                        </div>
                    </SideContainer>
                </Col>
                <Col>
                    <LoginPartialPage />
                </Col>
            </Row>
        </AccountBackground>
    );
};
