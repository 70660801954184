import gql from "graphql-tag";
import { calendarEventProviderFields } from "../generated/calendarEventProviderFields";

export const calendarEventProviderFieldsFragment = gql`
    fragment calendarEventProviderFields on CalendarEventProvider {
        id
        name
        descriptionHtml
        imageBlobReferenceId
        color
        archived
        displayOrder
        createdByUserId
        createdDate
    }
`;


export type CalendarEventProvider = Omit<calendarEventProviderFields, '__typename'>;
