import './configure/polyfill';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { App } from './App';
import registerServiceWorker from './registerServiceWorker';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import authService from './api/api-authorization/AuthorizeService';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') || undefined;
const rootElement = document.getElementById('root');

// Configure google analytics and attach it to the routing events.
const history = createBrowserHistory({ basename: (baseUrl == null ? "/" : (baseUrl as string | undefined)) });
ReactGA.initialize('UA-78426939-4');
history.listen(location => {
    // Grab the readily available user information (we don't want to allow analytics to be a reason why we async load anything so we don't use getUser() here).
    var identity = authService._user?.profile;
    ReactGA.set({ userId: identity?.email ?? '' });

    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});

ReactDOM.render(
    <Router history={history}>
        <App />
    </Router>,
  rootElement);

registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

