import './background.scss';

import * as React from 'react';

export interface BackgroundProps {
    children?: React.ReactNode,
    className?: string,
    centerChildren?: 'vertically' | 'horizontally',
}

/**
 * Background for making the back of screens look nicer.
 * @param props
 */
export const Background = (props: BackgroundProps) => {
    const { children, className, centerChildren } = props;

    return (
        <div className={`background ${centerChildren ? `background-center-children-${centerChildren}` : ''} ${className ?? ''}`}>
            {children}
        </div>
        );
};