import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { CalendarHeadlinesList } from "./CalendarHeadlinesList";
import { EditCalendarHeadline, CreateCalendarHeadline } from "./EditCalendarHeadline";
import { DeleteCalendarHeadline } from "./DeleteCalendarHeadline";

export const calendarHeadlineRoutes: Array<RouteEntry> = [
    { path: '/administration/headlines', exact: true, component: CalendarHeadlinesList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/headlines/edit/:id', component: EditCalendarHeadline, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/headlines/add', component: CreateCalendarHeadline, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/headlines/delete/:id', component: DeleteCalendarHeadline, authorize: true, requireRole: IdentityRoles.Administration },
];
