import './header.scss';

import * as React from 'react';
import { Nav, Container } from 'reactstrap';
import { AppHeader, AppSidebarToggler } from '@coreui/react';
import { UserNav } from './UserNav';
import { Link } from 'react-router-dom';
import { Navigation } from './Navigation';
//import { LanguageSelect } from './LanguageSelect';
import { AppNavbarBrand } from './appNavbarBrand/AppNavbarBrand';
import { HeaderSearchInput } from './headerSearchInput/HeaderSearchInput';
import { AuthorizeContainer } from '../../shared/authorizeRoute';

export const Header = () => {
    return (
        <AppHeader fixed className="header">
            <Container fluid>
                <AppSidebarToggler className="d-lg-none" display="md" mobile />

                <Link to="/">
                    <AppNavbarBrand />
                </Link>
                <AppSidebarToggler className="d-md-down-none flex-shrink" display="lg" />

                <Nav className="menu-nav d-none d-md-flex">
                    <Navigation />
                </Nav>
                <Nav className="menu-nav-xs d-flex d-md-none flex-grow-1">        
                </Nav>

                <Nav className="menu-nav d-none d-sm-flex flex-grow-1">
                    <AuthorizeContainer>
                        <HeaderSearchInput />
                    </AuthorizeContainer>
                </Nav>
                
            
                <Nav className="signin-nav">
                    <UserNav />
                </Nav>

                {/*<Nav className="language-nav">
                    <NavItem>
                        <LanguageSelect />
                    </NavItem>
                </Nav>*/}

                {/* Special copy of the search box for xs displays */}
                <Nav className="menu-nav d-flex d-sm-none flex-grow-1 mb-1" style={{ minWidth: '100%'}}>
                    <AuthorizeContainer>
                        <HeaderSearchInput />
                    </AuthorizeContainer>
                </Nav>
            </Container>
        </AppHeader>
    );
};
