import * as React from 'react';
import { Button, Form, Label, FormGroup, Spinner, Row, Col, ButtonGroup } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useChanges } from '../../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedFormFeedback, ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { Guid } from 'guid-string';
import { useSaveCmsVideoCallback } from '../../../api/main/cmsVideos/useSaveCmsVideoCallback';
import { CmsVideo } from '../../../api/main/models/CmsVideo';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/Banner';
import { Background } from '../../shared/background/Background';
import { FileUploadButton } from '../../shared/fileUploadButton/FileUploadButton';
import { useUploadBlobCallback } from '../../../api/main/blobReferences/useUploadBlobCallback';
import { BlobReference } from '../../../api/main/models/BlobReference';
import { CmsAdministrationWarning } from '../shared/CmsAdministrationWarning';
import { useEditCmsVideoViewModel } from '../../../api/main/cmsVideos/viewModels/useEditCmsVideoViewModel';

interface EditCmsVideoProps {
    isCreate?: boolean,
}

/**
 * Create a new CmsVideo.
 */
export const CreateCmsVideo = () => (<EditCmsVideo isCreate={true} />);

/**
 * Edit a CmsVideo.
 */
export const EditCmsVideo = (props: EditCmsVideoProps) => {
    const { isCreate } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const { data: { model: storeModel }, isLoading, errors: loadErrors } = useEditCmsVideoViewModel(id);
    const { model, change, changes } = useChanges<CmsVideo>(storeModel, isCreate ? { id: Guid.newGuid(), name: '', } : {});
    const [save, { errors: saveErrors }] = useSaveCmsVideoCallback();
    const history = useHistory();

    // Video display and uploading.
    const [videoBlob, setVideoBlob] = React.useState<BlobReference | null>();
    React.useEffect(() => setVideoBlob(storeModel?.videoBlobReference), [storeModel]);

    const [uploadBlob] = useUploadBlobCallback();
    const [uploadVideoBlob, { isExecuting: isUploadingVideoBlob, errors: uploadVideoBlobErrors }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        // Upload the blob.
        const blob = await uploadBlob(files);
        if (!blob) {
            return;
        }

        // Update the model.
        change({ videoBlobReferenceId: blob.id });

        // Update the blob in the state.
        setVideoBlob(blob);
    }, [uploadBlob, change, setVideoBlob]);

    // Thumbnail display and uploading.
    const [thumbnailBlob, setThumbnailBlob] = React.useState<BlobReference | null>();
    React.useEffect(() => setThumbnailBlob(storeModel?.thumbnailBlobReference), [storeModel]);

    const [uploadThumbnailBlob, { isExecuting: isUploadingThumbnailBlob, errors: uploadThumbnailBlobErrors }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        // Upload the blob.
        const blob = await uploadBlob(files);
        if (!blob) {
            return;
        }

        // Update the model.
        change({ thumbnailBlobReferenceId: blob.id });

        // Update the blob in the state.
        setThumbnailBlob(blob);
    }, [uploadBlob, change, setThumbnailBlob]);

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editCmsVideo.nameRequired', 'Name is required') : '',
            videoBlobReferenceId: () => Guid.isEmpty(model?.videoBlobReferenceId) ? t('editCmsVideo.videoBlobReferenceRequired', 'An uploaded video is required') : '',
            thumbnailBlobReferenceId: () => Guid.isEmpty(model?.thumbnailBlobReferenceId) ? t('editCmsVideo.thumbnailBlobReferenceRequired', 'An uploaded thumbnail image is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        await save(model.id, changes, !!isCreate);

        history.goBack();
    }, [validate, save, model, changes, isCreate, history]);

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>
                            {
                                isCreate ? (
                                    <>{t('editCmsVideo.createHeading', 'Add CMS video')}</>
                                        ): (
                                        <>{t('editCmsVideo.editHeading', 'Edit CMS video')}</>
                                        )
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <CmsAdministrationWarning />
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, saveFormErrors, saveErrors, uploadVideoBlobErrors, uploadThumbnailBlobErrors]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('editCmsVideo.name', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="video">{t('editCmsVideo.video', 'Video')}</Label>

                        <div className="embed-responsive embed-responsive-16by9 mb-4">
                            <video
                                src={videoBlob?.url ?? ''}
                                poster={thumbnailBlob?.url ?? ''}
                                controls>
                            </video>
                        </div>

                        <ValidatedFormFeedback name="videoBlobReferenceId" validationErrors={validationErrors['videoBlobReferenceId']} />
                        <ValidatedFormFeedback name="thumbnailBlobReferenceId" validationErrors={validationErrors['thumbnailBlobReferenceId']} />

                        <ButtonGroup>
                            <FileUploadButton onUpload={files => uploadVideoBlob(files)} isExecuting={isUploadingVideoBlob}
                                executingChildren={<><Spinner size="sm" /><> </>{t('common.uploading', 'Uploading...')}</>}
                            >
                                {t('editCmsVideo.uploadVideo', 'Upload video...')}
                            </FileUploadButton>
                            <FileUploadButton onUpload={files => uploadThumbnailBlob(files)} isExecuting={isUploadingThumbnailBlob}
                                executingChildren={<><Spinner size="sm" /><> </>{t('common.uploading', 'Uploading...')}</>}
                            >
                                {t('editCmsVideo.uploadIThumbnail', 'Upload thumbnail...')}
                            </FileUploadButton>
                        </ButtonGroup>
                    </FormGroup>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
