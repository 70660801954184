import * as React from 'react';
import { CalendarEventCalendarEventProviderCreateInput, CalendarEventCalendarEventProviderUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateCalendarEventCalendarEventProviderCallback } from './useCreateCalendarEventCalendarEventProviderCallback';
import { useUpdateCalendarEventCalendarEventProviderCallback } from './useUpdateCalendarEventCalendarEventProviderCallback';
import { CalendarEventCalendarEventProvider } from '../models/CalendarEventCalendarEventProvider';

/**
 * Get a callback to save a CalendarEventCalendarEventProvider in the store using either a create or update.
 */
export function useSaveCalendarEventCalendarEventProviderCallback(options: SaveInStoreOptions<CalendarEventCalendarEventProvider, string> = {}) {
    const [_create, createStatus] = useCreateCalendarEventCalendarEventProviderCallback();
    const create = React.useCallback((model: Partial<CalendarEventCalendarEventProvider>) => _create(model as CalendarEventCalendarEventProviderCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateCalendarEventCalendarEventProviderCallback();
    const update = React.useCallback((id: string, changes: Partial<CalendarEventCalendarEventProvider>) => _update(id, changes as CalendarEventCalendarEventProviderUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
