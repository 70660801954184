import * as React from 'react';
import { atom, useRecoilState } from 'recoil';

export const globalSearchTextState = atom<string>({
    key: 'globalSearchTextState',
    default: '',
    // NOTE not persisted as its unique to the session.
});

/**
 * Hook that provides access to the Global search text from the global search text state while abstracting the global stage provider from the calling code.
 */
export function useGlobalSearchText(): [string, React.Dispatch<React.SetStateAction<string>>] {
    const [value, setValue] = useRecoilState(globalSearchTextState);
    return [value, setValue];
}