import * as React from 'react';
import { CmsImageCreateInput, CmsImageUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateCmsImageCallback } from './useCreateCmsImageCallback';
import { useUpdateCmsImageCallback } from './useUpdateCmsImageCallback';
import { CmsImage } from '../models/CmsImage';

/**
 * Get a callback to save a CmsImage in the store using either a create or update.
 */
export function useSaveCmsImageCallback(options: SaveInStoreOptions<CmsImage, string> = {}) {
    const [_create, createStatus] = useCreateCmsImageCallback();
    const create = React.useCallback((model: Partial<CmsImage>) => _create(model as CmsImageCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateCmsImageCallback();
    const update = React.useCallback((id: string, changes: Partial<CmsImage>) => _update(id, changes as CmsImageUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
