import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useCmsText } from '../../api/main/cmsTexts/useCmsText';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { CmsTextHtmlDisplay } from '../cms/cmsTexts/CmsTextHtmlDisplay';
import { Background } from '../shared/background/Background';
import { Banner } from '../shared/Banner';
import { MainContainer } from '../shared/MainContainer';

/**
 * Cms based page showing the privacy policy. 
 */
export const PrivacyPolicyPage = () => {
    const { t } = useTranslation();

    const { data: { model: cmsText }, errors: cmsTextErrors } = useCmsText(undefined, { name: 'Privacy policy' });

    return (
        <Background>
            <Banner>
                <h1>
                    {t('privcyPolicyPage.heading', 'Privacy policy')}
                </h1>
            </Banner>
            <MainContainer>
                <AlertOnErrors errors={cmsTextErrors} />

                <CmsTextHtmlDisplay model={cmsText} />
            </MainContainer>
        </Background>
    );
};