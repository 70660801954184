import * as React from 'react';
import { ItemTagCreateInput, ItemTagUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateItemTagCallback } from './useCreateItemTagCallback';
import { useUpdateItemTagCallback } from './useUpdateItemTagCallback';
import { ItemTag } from '../models/ItemTag';

/**
 * Get a callback to save a ItemTag in the store using either a create or update.
 */
export function useSaveItemTagCallback(options: SaveInStoreOptions<ItemTag, string> = {}) {
    const [_create, createStatus] = useCreateItemTagCallback();
    const create = React.useCallback((model: Partial<ItemTag>) => _create(model as ItemTagCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateItemTagCallback();
    const update = React.useCallback((id: string, changes: Partial<ItemTag>) => _update(id, changes as ItemTagUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
