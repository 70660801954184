import './calendarDay.scss';

import * as React from 'react';
import moment, { Moment } from 'moment';
import { CalendarDayEvent } from './CalendarDayEvent';
import { useTranslation } from 'react-i18next';
import { CalendarHomeScheduleViewModel } from '../../calendar/CalendarHomeScheduleViewModel';

export interface CalendarDayProps {
    date: Moment,
    schedules?: Array<CalendarHomeScheduleViewModel>,
    onEventClick?: (schedule: { id: string, calendarEventId: string }) => void,
}

/**
 * Component to show a single day in a calendar.
 */
export const CalendarDay = (props: CalendarDayProps) => {
    const { date, schedules, onEventClick } = props;

    const { t } = useTranslation();

    const daySettings = React.useMemo(() => {
        const today = moment().startOf('day');

        return {
            dayNumber: date.format('DD'),
            dayName: date.format('dddd'),
            isToday: today.diff(moment(date).startOf('day')) === 0 ? true : false,
            isPast: today.diff(moment(date).startOf('day')) > 0 ? true : false,
        };
    }, [date]);

    // Work out the schedules that actually need showing on this date (as we'll just have the full set as schesules right now)
    const mySchedules = React.useMemo(() => {
        const startOfDay = moment(date).startOf('day').toISOString();
        const endOfDay = moment(date).endOf('day').toISOString();

        const ret = schedules?.filter(it => moment(it.start).toISOString() <= endOfDay && moment(it.end).toISOString() >= startOfDay);
        return ret;
    }, [schedules, date]);

    return (
        <div className={`calendar-day calendar-day-${daySettings.dayName.toLowerCase()} ${daySettings.isToday ? 'calendar-day-today' : ''}`}>
            <div className={`calendar-day-date ${daySettings.isPast ? 'calendar-day-date-past' : 'calendar-day-date-future'}`}>
                {date.format('DD')}
                <span className="calendar-day-date-day-name">
                    <> </>
                    {t('common.dates.weekday', '{{date, dddd}}', { date })}
                </span>
            </div>
            <div className="calendar-day-events">
                {
                    mySchedules?.map(item => (
                        <CalendarDayEvent key={item.id}
                            date={date}
                            schedule={item}
                            onClick={() => {
                                if (onEventClick) {
                                    onEventClick(item);
                                }
                            }}
                            />
                    ))
                }
            </div>
        </div>
        );
};