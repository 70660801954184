import * as React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLinkTrackActive } from '../shared/NavLinkTrackActive';
import { IdentityRoles } from '../../configure/security/IdentityRoles';

/**
 * Primary navigation menu for the app. 
 */
export const Navigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <AuthorizeContainer>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/calendar">
                        <FontAwesomeIcon icon={['far', 'calendar-alt']} className="nav-icon" />
                        <> {t('navigation.calendar', 'Calendar')}</>
                    </NavLink>
                </NavItem>

                {/* Even though About doesn't need authentication to be accesed, we only show it in the main navigation if we are logged in, otherwise its all alone for people outside (and accessible by clicking the logo/automatically) */}
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/about">
                        <FontAwesomeIcon icon="info" className="nav-icon" />
                        <> {t('navigation.calendar', 'About')}</>
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>
            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration">
                        <FontAwesomeIcon icon="cogs" className="nav-icon" />
                        <> {t('navigation.administration', 'Administration')}</>
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
