import * as React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback, ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useTranslation } from "react-i18next";
import { Guid } from "guid-string";
import { Profile as ProfileModel } from '../../api/main/models/Profile';
import { useRegisterProfileCallback } from "../../api/main/profiles/useRegisterProfileCallback";
import { ProfileCreateInput } from "../../api/main/generated/globalTypes";
import { ValidationErrors } from "pojo-validator";
import { ConditionalFragment } from "react-conditionalfragment";
import { TwoValueSwitch } from "../shared/TwoValueSwitch";
import { TagSelector } from "../tags/TagSelector";
import { useItemTags } from "../../api/main/itemTags/useItemTags";
import moment from 'moment';

export interface RegisterProfileDetailsProps {
    // Public
    registerProfile: (userId: string) => Promise<void>,
    isRegisteringProfile: boolean,
    registerProfileErrors: any,

    validate: ValidateCallback,
    validationErrors: ValidationErrors

    // Private
    model: ProfileModel,
    change: (changes: Partial<ProfileModel>) => void,
    changes: Partial<ProfileModel>
}

/**
 * Custom hook that lets users of RegisterProfileDetails own the state of the Profile without being aware of its format.
 * @param userId
 */
export function useRegisterProfileDetailsProps(): RegisterProfileDetailsProps {
    const { t } = useTranslation();

    const [_registerProfile, { isExecuting: isRegisteringProfile, errors: registerProfileErrors }] = useRegisterProfileCallback();
    const { model, change, changes } = useChanges<ProfileModel>(null, { id: Guid.newGuid(), firstName: '', lastName: '', organisationName: '', organisationType: 'School', isSubscribedToDailyNewsLetter: false, isSubscribedToWeeklyNewsLetter: false, isIntrestedInWorkingTogether: false, urn: '', jobTitle: '', registrationSchoolPhasesJson: '[]', registeredDate: moment().toISOString(), });

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        if (!model) {
            return;
        }

        const rules = {
            firstName: () => !model.firstName ? t('registerProfileDetails.firstNameRequired', 'First name is required') : '',
            lastName: () => !model.lastName ? t('registerProfileDetails.lastNameRequired', 'Last name is required') : '',
            organisationName: () => !model.organisationName ? t('registerProfileDetails.organisationName', 'Organisation name is required') : '',
            organsiationType: () => !model.organisationType ? t('registerProfileDetails.organisationType', 'Organisation type is required') : '',

        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const registerProfile = React.useCallback(async (userId: string) => {
        await _registerProfile({ ...model, userId: userId } as ProfileCreateInput);
    }, [_registerProfile, model]);

    return {
        registerProfile: registerProfile,
        isRegisteringProfile: isRegisteringProfile,
        registerProfileErrors: registerProfileErrors,

        validate: validate,
        validationErrors: validationErrors,

        model: model,
        change: change,
        changes: changes,
    };
};


/**
 * Allow the user to set their personal details on their profile during registration.
 */
export const RegisterProfileDetails = (props: RegisterProfileDetailsProps & { children?: React.ReactNode }) => {
    const { model, change, validate, validationErrors, children, } = props;
    const { t } = useTranslation();

    const { data: { items: schoolPhaseTags }} = useItemTags({ tagType: 'SchoolPhase' });

    // Check if a school phase has been selected by the user.
    const isSchoolPhaseSelected = React.useCallback((id: string) => {
        if (!model?.registrationSchoolPhasesJson) {
            return false;
        }

        if (!schoolPhaseTags) {
            return false;
        }

        const selectedNames = JSON.parse(model.registrationSchoolPhasesJson || '[]') as Array<string>;
        const tag = schoolPhaseTags.find(it => it.id === id);
        if (!tag) {
            return false;
        }

        const ret = selectedNames.find(it => it === tag.name);
        return !!ret;
    }, [model, schoolPhaseTags]);

    // Toggle the selection of a school phase.
    const toggleSchoolPhase = React.useCallback((id: string) => {
        if (!model || !schoolPhaseTags) {
            return;
        }

        let selectedNames = JSON.parse(model.registrationSchoolPhasesJson || '[]') as Array<string>;
        const tag = schoolPhaseTags.find(it => it.id === id);
        if (!tag) {
            return false;
        }

        const existing = selectedNames.find(it => it === tag.name);
        if (existing) {
            selectedNames = selectedNames.filter(it => it !== tag.name);
        } else {
            selectedNames = [...selectedNames, tag.name];
        }

        change({ registrationSchoolPhasesJson: JSON.stringify(selectedNames) });
    }, [model, change, schoolPhaseTags])

    // Render the UI.
    //
    return (
        <>
            <FormGroup>
                <Label htmlFor="firstName">{t('profileDetails.firstName', 'First name')}</Label>
                <ValidatedInput name="firstName" type="text" value={model.firstName ?? ''} onChange={e => change({ firstName: e.currentTarget.value })} onBlur={e => validate('firstName')} validationErrors={validationErrors['firstName']} />
            </FormGroup>
            <FormGroup>
                <Label htmlFor="lastName">{t('profileDetails.lastName', 'Last name')}</Label>
                <ValidatedInput name="lastName" type="text" value={model.lastName ?? ''} onChange={e => change({ lastName: e.currentTarget.value })} onBlur={e => validate('lastName')} validationErrors={validationErrors['lastName']} />
            </FormGroup>
            <FormGroup>
                <Label htmlFor="organisationType">{t('profileDetails.organisationType', 'What type of organisation do you work for?')}</Label>
                <ValidatedInput name="organsationType" type="select" value={model.organisationType ?? ''} onChange={e => change({ organisationType: e.currentTarget.value })} onBlur={e => validate('organisationType')} validationErrors={validationErrors['organisationType']}>
                    <option value="School">{t('registerProfileDetails.organisationType.school', 'Maintained School')}</option>
                    <option value="MAT">{t('registerProfileDetails.organisationType.mat', 'Academy')}</option>
                    <option value="Other">{t('registerProfileDetails.organisationType.other', 'Other')}</option>
                </ValidatedInput>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="organisationName">
                    {
                    model?.organisationType === 'School' ? t('profileDetails.organisationName.school', 'School')
                            : model?.organisationType === 'MAT' ? t('profileDetails.organisationName.mat', 'Academy')
                            : t('profileDetails.organisationName.other', 'Organisation')
                    }
                </Label>
                <ValidatedInput name="organisationName" type="text" value={model.organisationName ?? ''} onChange={e => change({ organisationName: e.currentTarget.value })} onBlur={e => validate('organisationName')} validationErrors={validationErrors['organisationName']} />
            </FormGroup>

            <ConditionalFragment showIf={model?.organisationType === 'School' || model?.organisationType === 'MAT'}>
                <FormGroup>
                    <Label htmlFor="urn">{t('profileDetails.urn', 'DfE number (optional)')}</Label>
                    <ValidatedInput name="urn" type="text" value={model.urn ?? ''} onChange={e => change({ urn: e.currentTarget.value })} onBlur={e => validate('urn')} validationErrors={validationErrors['urn']} />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="schoolPhasesJson">{t('profileDetails.urn', 'School phases')}</Label>
                    <TagSelector color="primary"
                        items={schoolPhaseTags ?? []}
                        isSelected={id => isSchoolPhaseSelected(id)}
                        toggle={(id) => toggleSchoolPhase(id)} />
                </FormGroup>

            </ConditionalFragment>

            <FormGroup>
                <Label htmlFor="jobTitle">{t('profileDetails.jobTitle', 'Job role / Job title')}</Label>
                <ValidatedInput name="jobTitle" type="text" value={model.jobTitle ?? ''} onChange={e => change({ jobTitle: e.currentTarget.value })} onBlur={e => validate('jobTitle')} validationErrors={validationErrors['jobTitle']} />
            </FormGroup>

            {children}

            <FormGroup>
                <Label htmlFor="">{t('profileDetails.subscribeToDailyNewslette', 'Subscribe to daily update email?')}</Label>

                <Row>
                    <Col xs="auto">
                        <TwoValueSwitch leftLabel={t('common.no', 'No')} rightLabel={t('common.yes', 'Yes')} checked={model?.isSubscribedToDailyNewsLetter ?? false} onChange={value => change({ isSubscribedToDailyNewsLetter: value })} />
                    </Col>
                    <Col>
                        <div className="">
                            {t('registerProfileDetails.subscribeToDailyNewsletter.description', 'Subscribe to daily update emails to receive the need-to-know sector information for your school straight to your inbox every morning.')}
                        </div>
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="">{t('profileDetails.subscribeToWeeklyNewsletter', 'Subscribe to This Week on TheSchoolBus?')}</Label>

                <Row>
                    <Col xs="auto">
                        <TwoValueSwitch leftLabel={t('common.no', 'No')} rightLabel={t('common.yes', 'Yes')} checked={model?.isSubscribedToWeeklyNewsLetter ?? false} onChange={value => change({ isSubscribedToWeeklyNewsLetter: value })} />
                    </Col>
                    <Col>
                        <div className="">
                            {t('registerProfileDetails.subscribeToDailyWeekly.description', 'Subscribe to This Week on TheSchoolBus to receive a weekly summary of what\'s happening in education to your inbox every week.')}
                        </div>
                    </Col>
                </Row>
            </FormGroup>

            <ConditionalFragment showIf={model?.organisationType === 'Other'}>
                <Label htmlFor="">{t('profileDetails.isIntrestedInWorkingTogether', 'Are you a high quality provider in education interested in working with us or featuring your events on TheSchoolYear?')}</Label>
                <Row>
                    <Col xs="auto">
                        <TwoValueSwitch leftLabel={t('common.no', 'No')} rightLabel={t('common.yes', 'Yes')} checked={model?.isIntrestedInWorkingTogether ?? false} onChange={value => change({ isIntrestedInWorkingTogether: value })} />
                    </Col>
                    <Col>
                        <div className="">
                            {t('registerProfileDetails.isIntrestedInWorkingTogether.description', 'If you work in education or education consultancy and are interested in having your events on TheSchoolYear or working with us on TheSchoolYear or TheSchoolBus let us know and we\'ll get in touch with you to arrange a good time to chat.')}
                        </div>
                    </Col>
                </Row>
            </ConditionalFragment>
        </>
    );
};
