import * as React from "react";
import { Row, Col, Form, FormGroup, Label, Spinner, Alert, FormText } from "reactstrap";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync"
import { useAuthenticatedState } from "../../api/api-authorization/useAuthenticatedState";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Guid } from "guid-string";
import { useProfile } from "../../api/main/profiles/useProfile";
import { useSaveProfileCallback } from "../../api/main/profiles/useSaveProfileCallback";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useTranslation } from "react-i18next";
import { FormButtons } from "../shared/FormButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TwoValueSwitch } from "../shared/TwoValueSwitch";
import { ConditionalFragment } from "react-conditionalfragment";
import { useAsyncCallback } from "react-use-async-callback";
import { isNullOrUndefined } from "util";

/**
 * Allow the user to change their personal details on their profile.
 */
export const ProfileDetails = () => {
    const { t } = useTranslation();
    const [hasSaved, setHasSaved] = React.useState<boolean>(false);
    const [isCreate, setIsCreate] = React.useState<boolean>(false);
    const { user } = useAuthenticatedState({ includeUser: true});
    const { data: { model: storeModel, }, isLoading, errors: loadErrors, refresh: refreshProfile } = useProfile(user?.sub ?? '', {
        isUserId: true,
        lazy: true,
        onCompleted: (data) => {
            // If we don't find a record in the database, create one ready to save.
            if (!data) {
                setIsCreate(true);
                change({ id: Guid.newGuid(), userId: user?.sub ?? '' });
            }
        } });
    const { model, change, changes } = useChanges(storeModel);
    const [save, { isExecuting: isSaving, errors: saveErrors }] = useSaveProfileCallback({ afterSaving: () => setIsCreate(false) })

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        if (!model) {
            return;
        }

        const rules = {
            firstName: () => !model.firstName ? t('profileDetails.firstNameRequired', 'First name is required') : '',
            lastName: () => !model.lastName ? t('profileDetails.lastNameRequired', 'Last name is required') : '',
            organisationName: () => !model.organisationName ? t('profileDetails.organisationName', 'Organisation name is required') : '',
            organsiationType: () => !model.organisationType ? t('profileDetails.organisationType', 'Organisation type is required') : '',

        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Only load the profile once we know the user.
    React.useEffect(() => {
        if (!user) {
            return;
        }

        refreshProfile();
    }, [user, refreshProfile]);

    // Locking of the daily and weekly email toggles.
    const [lockDailyEmail, setLockDailyEmail] = React.useState<boolean>(false);
    const [lockWeeklyEmail, setLockWeeklyEmail] = React.useState<boolean>(false);
    const [hasDefaultedLockState, setHasDefaultedLockState] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (!model ||  isNullOrUndefined(model?.id) || hasDefaultedLockState) {
            return;
        }

        setLockDailyEmail(model.isSubscribedToDailyNewsLetter);
        setLockWeeklyEmail(model.isSubscribedToWeeklyNewsLetter);
        setHasDefaultedLockState(true);
    }, [model, hasDefaultedLockState, setHasDefaultedLockState, setLockDailyEmail, setLockWeeklyEmail]);

    const [saveForm] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        await save(model.id, changes, isCreate);

        setHasSaved(true);

        setLockDailyEmail(model.isSubscribedToDailyNewsLetter);
        setLockWeeklyEmail(model.isSubscribedToWeeklyNewsLetter);
    }, [save, setLockDailyEmail, setLockWeeklyEmail, model, setHasSaved, validate, isCreate, changes]);


    // Render the UI.
    //

    // If we're not ready, show the loading indicator.
    if (isLoading || !user || !model) {
        return (
            <>
                <AlertOnErrors errors={[loadErrors, saveErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    return (
        <>
            <Form onSubmit={async e => { e.preventDefault(); await saveForm(); }}>
                <AlertOnErrors errors={[loadErrors, saveErrors]} />

                {
                    hasSaved ? (
                        <Alert color="success">
                            {t('profileDetails.hasSaved', 'Your details have been updated.')}
                        </Alert>
                        ): null
                }

                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="firstName">{t('profileDetails.firstName', 'First name')}</Label>
                            <ValidatedInput name="firstName" type="text" value={model.firstName ?? ''} onChange={e => change({ firstName: e.currentTarget.value })} onBlur={e => validate('firstName')} validationErrors={validationErrors['firstName']} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="lastName">{t('profileDetails.lastName', 'Last name')}</Label>
                            <ValidatedInput name="lastName" type="text" value={model.lastName ?? ''} onChange={e => change({ lastName: e.currentTarget.value })} onBlur={e => validate('lastName')} validationErrors={validationErrors['lastName']} />
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <Label htmlFor="organisationType">{t('profileDetails.organisationType', 'What type of organisation do you work for?')}</Label>
                    <ValidatedInput name="organsationType" type="select" value={model.organisationType ?? ''} onChange={e => change({ organisationType: e.currentTarget.value })} onBlur={e => validate('organisationType')} validationErrors={validationErrors['organisationType']}>
                        <option value="School">{t('profileDetails.organisationType.school', 'Maintained School')}</option>
                        <option value="MAT">{t('profileDetails.organisationType.mat', 'Academy')}</option>
                        <option value="Other">{t('profileDetails.organisationType.other', 'Other')}</option>
                    </ValidatedInput>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="organisationName">
                        {
                            model?.organisationType === 'School' ? t('profileDetails.organisationName.school', 'School')
                                : model?.organisationType === 'MAT' ? t('profileDetails.organisationName.mat', 'Academy')
                                    : t('profileDetails.organisationName.other', 'Organisation')
                        }
                    </Label>
                    <ValidatedInput name="organisationName" type="text" value={model.organisationName ?? ''} onChange={e => change({ organisationName: e.currentTarget.value })} onBlur={e => validate('organisationName')} validationErrors={validationErrors['organisationName']} />
                </FormGroup>

                <ConditionalFragment showIf={model?.organisationType === 'School' || model?.organisationType === 'MAT'}>
                    <FormGroup>
                        <Label htmlFor="urn">{t('profileDetails.urn', 'DfE number (optional)')}</Label>
                        <ValidatedInput name="urn" type="text" value={model.urn ?? ''} onChange={e => change({ urn: e.currentTarget.value })} onBlur={e => validate('urn')} validationErrors={validationErrors['urn']} />
                    </FormGroup>
                </ConditionalFragment>

                <FormGroup>
                    <Label htmlFor="jobTitle">{t('profileDetails.jobTitle', 'Job role / Job title')}</Label>
                    <ValidatedInput name="jobTitle" type="text" value={model.jobTitle ?? ''} onChange={e => change({ jobTitle: e.currentTarget.value })} onBlur={e => validate('jobTitle')} validationErrors={validationErrors['jobTitle']} />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="">{t('profileDetails.subscribeToDailyNewslette', 'Subscribe to daily update email?')}</Label>

                    <Row>
                        <Col xs="auto">
                            <TwoValueSwitch leftLabel={t('common.no', 'No')} rightLabel={t('common.yes', 'Yes')} checked={model?.isSubscribedToDailyNewsLetter ?? false} onChange={value => change({ isSubscribedToDailyNewsLetter: value })}
                                disabled={lockDailyEmail}
                            />
                        </Col>
                        <Col>
                            <div className="">
                                {t('profileDetails.subscribeToDailyNewsletter.description', 'Subscribe to daily update emails to receive the need-to-know sector information for your school straight to your inbox every morning.')}
                            </div>
                        </Col>
                    </Row>
                    <ConditionalFragment showIf={lockDailyEmail}>
                        <FormText>
                            {t('profileDetails.unsubscribeDailyText', 'To unsubscribe from the daily update emails please use the unsubscribe link in the emails you receive.')}
                        </FormText>
                    </ConditionalFragment>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="">{t('profileDetails.subscribeToWeeklyNewsletter', 'Subscribe to This Week on TheSchoolBus?')}</Label>

                    <Row>
                        <Col xs="auto">
                            <TwoValueSwitch leftLabel={t('common.no', 'No')} rightLabel={t('common.yes', 'Yes')} checked={model?.isSubscribedToWeeklyNewsLetter ?? false} onChange={value => change({ isSubscribedToWeeklyNewsLetter: value })}
                                disabled={lockWeeklyEmail}
                            />
                        </Col>
                        <Col>
                            <div className="">
                                {t('profileDetails.subscribeToDailyWeekly.description', 'Subscribe to This Week on TheSchoolBus to receive a weekly summary of what\'s happening in education to your inbox every week.')}
                            </div>
                        </Col>
                    </Row>
                    <ConditionalFragment showIf={lockWeeklyEmail}>
                        <FormText>
                            {t('profileDetails.unsubscribeDailyText', 'To unsubscribe from the This Week on TheSchoolBus please use the unsubscribe link in the emails you receive.')}
                        </FormText>
                    </ConditionalFragment>
                </FormGroup>

                <ConditionalFragment showIf={model?.organisationType === 'Other'}>
                    <Label htmlFor="">{t('profileDetails.isIntrestedInWorkingTogether', 'Are you a high quality provider in education interested in working with us or featuring your events on TheSchoolYear?')}</Label>
                    <Row>
                        <Col xs="auto">
                            <TwoValueSwitch leftLabel={t('common.no', 'No')} rightLabel={t('common.yes', 'Yes')} checked={model?.isIntrestedInWorkingTogether ?? false} onChange={value => change({ isIntrestedInWorkingTogether: value })} />
                        </Col>
                        <Col>
                            <div className="">
                                {t('profileDetails.isIntrestedInWorkingTogether.description', 'If you work in education or education consultancy and are interested in having your events on TheSchoolYear or working with us on TheSchoolYear or TheSchoolBus let us know and we\'ll get in touch with you to arrange a good time to chat.')}
                            </div>
                        </Col>
                    </Row>
                </ConditionalFragment>

                <FormButtons>
                    <ButtonAsync color="primary" isExecuting={isSaving}
                        executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                        <FontAwesomeIcon icon="save" />
                        <> {t('common.save', 'Save')}</>
                    </ButtonAsync>
                </FormButtons>
            </Form>
        </>
    );
};
