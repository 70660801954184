import { CmsText } from "../../../api/main/models/CmsText";
import * as React from 'react';
import { HtmlDisplay } from "../../../shared/htmlEditor";

export interface CmsTextHtmlDisplayProps {
    model: CmsText | undefined | null,
}

/**
 * Spealised HtmlDisplay that lets us consitantly present CmsText within components or pages.
 * @param props
 */
export const CmsTextHtmlDisplay = (props: CmsTextHtmlDisplayProps) => {
    const { model } = props;

    return (
        <HtmlDisplay html={model?.html ?? ''} />
    );
};