import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadPagedResult } from '../../../../shared/abstractStore';
import { analyticEventsListViewModelQuery, analyticEventsListViewModelQueryVariables } from '../../generated/analyticEventsListViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { analyticEventFieldsFragment } from '../../models/AnalyticEvent';
import { AnalyticEventsAsyncLoadPagedOptions } from '../useAnalyticEvents';
import { useFetchMoreCallback } from '../../../../shared/useFetchMoreCallback';
import { calendarEventScheduleFieldsFragment } from '../../models/CalendarEventSchedule';

/**
 * View model for AnalyticEventsList that replaces the call to useAnalyticEvents() and loads the right related data.
 * @param options
 */
export function useAnalyticEventsListViewModel(options: AnalyticEventsAsyncLoadPagedOptions = {}): AsyncLoadPagedResult<analyticEventsListViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<analyticEventsListViewModelQuery, analyticEventsListViewModelQueryVariables>(
        gql`
        query analyticEventsListViewModelQuery ($offset: Int, $first: Int, $fromDate: DateTimeOffset, $toDate: DateTimeOffset, $eventType: String) {
            items: analyticEvents (offset: $offset, first: $first, fromDate: $fromDate, toDate: $toDate, eventType: $eventType) {
                ...analyticEventFields

                user {
                    id
                    email
                }

                calendarEvent {
                    id
                    name

                    providers {
                        id
                        provider {
                            id
                            name
                        }
                    }
                }

                calendarEventUrl {
                    id
                    name

                    calendarEvent {
                        id
                        name
                    }
                }

                calendarEventSchedule {
                    ...calendarEventScheduleFields
                }
            }
        }

        ${analyticEventFieldsFragment}
        ${calendarEventScheduleFieldsFragment}
        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize,
                fromDate: options.fromDate,
                toDate: options.toDate,
                eventType: options.eventType,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<analyticEventsListViewModelQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])]
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}
