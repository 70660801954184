import * as React from 'react';
import { CmsVideoCreateInput, CmsVideoUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateCmsVideoCallback } from './useCreateCmsVideoCallback';
import { useUpdateCmsVideoCallback } from './useUpdateCmsVideoCallback';
import { CmsVideo } from '../models/CmsVideo';

/**
 * Get a callback to save a CmsVideo in the store using either a create or update.
 */
export function useSaveCmsVideoCallback(options: SaveInStoreOptions<CmsVideo, string> = {}) {
    const [_create, createStatus] = useCreateCmsVideoCallback();
    const create = React.useCallback((model: Partial<CmsVideo>) => _create(model as CmsVideoCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateCmsVideoCallback();
    const update = React.useCallback((id: string, changes: Partial<CmsVideo>) => _update(id, changes as CmsVideoUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
