import * as React from 'react';
import moment, { Moment, MomentInput } from 'moment';
import { InputProps, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export interface DateTimeInputProps extends InputProps {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    format?: string,
}

/**
 * Input with validation support for a datetime picker using moment() to keep our dates straight.
 * @param props
 */
export const ISODateTimeInput = (props: DateTimeInputProps) => {
    const {
        type = 'datetime-local',
        value,
        format,
        onChange,
        plaintext,
        ...rest
    } = props;

    const { t } = useTranslation();

    const theValue = React.useMemo(() => {
        // Use a moment to get the local datetime and format into formats supported by browser input tags.
        const theFormat = format || (type === 'datetime' || type === 'datetime-local' ? 'YYYY-MM-DD[T]HH:mm' : 'YYYY-MM-DD');
        const valueMoment: Moment = moment(value as MomentInput).local();
        let ret = '';
        if (valueMoment.isValid()) {
            ret = valueMoment.format(theFormat);
        }

        // Special handling for plainText rendering, in this case we need to format ready for consumption not for date picker.
        if (plaintext) {
            if (type === 'datetime' || type === 'datetime-local') {
                ret = t('common.datetime', '{{date, DD/MM/YYYY HH:mm}}', { date: valueMoment });
            } else {
                ret = t('common.date', '{{date, DD/MM/YYYY}}', { date: valueMoment });
            }
        }

        return ret;
    }, [value, type, format, plaintext, t]);

    // Wrap the real on change event to always return the ISO date string in UTC time.
    const onChangeWrapper = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            const newValue = moment(event.currentTarget.value).utc().toISOString();
            const newEvent = {
                ...event,
                currentTarget: {
                    ...event.currentTarget,
                    value: newValue,
                },
                target: {
                    ...event.target,
                    value: newValue,
                }
            };

            onChange(newEvent);
        }
    }, [onChange]);

    return (
        <Input type={type} value={theValue} onChange={onChangeWrapper} plaintext={plaintext} {...rest} />
    );
};
