import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CalendarEventTypeUpdateInput } from '../generated/globalTypes';
import { updateCalendarEventTypeMutation, updateCalendarEventTypeMutationVariables } from '../generated/updateCalendarEventTypeMutation';
import { calendarEventTypeFieldsFragment } from '../models/CalendarEventType';

/**
 * Get a callback to update a CalendarEventType in the store.
 */
export function useUpdateCalendarEventTypeCallback(): [(id: string, model: CalendarEventTypeUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<updateCalendarEventTypeMutation, updateCalendarEventTypeMutationVariables>(
        gql`
            mutation updateCalendarEventTypeMutation ($model: CalendarEventTypeUpdateInput!) {
                updateCalendarEventType(model: $model) {
                    ...calendarEventTypeFields
                }
            }

            ${calendarEventTypeFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: CalendarEventTypeUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
