import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { CalendarEventsList } from "./CalendarEventsList";
import { EditCalendarEvent, CreateCalendarEvent } from "./EditCalendarEvent";
import { DeleteCalendarEvent } from "./DeleteCalendarEvent";

export const calendarEventRoutes: Array<RouteEntry> = [
    { path: '/administration/events', exact: true, component: CalendarEventsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/events/edit/:id', component: EditCalendarEvent, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/events/add', component: CreateCalendarEvent, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/events/delete/:id', component: DeleteCalendarEvent, authorize: true, requireRole: IdentityRoles.Administration },
];
