import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../shared/abstractStore';
import { cmsVideoQuery, cmsVideoQueryVariables } from '../generated/cmsVideoQuery';
import { cmsVideoFieldsFragment } from '../models/CmsVideo';
import { mainApiConfig } from '../../../configure/mainApiConfig';
import { Guid } from 'guid-string';

export interface CmsVideoAsyncLoadOptions extends AsyncLoadOptions {
    name?: string,
}

/**
 * Get a CmsVideo from the store.
 * @param id
 * @param options
 */
export function useCmsVideo(id?: string | undefined | null, options: CmsVideoAsyncLoadOptions = {}): AsyncLoadResult<cmsVideoQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<cmsVideoQuery, cmsVideoQueryVariables>(
        gql`
        query cmsVideoQuery ($id: ID, $name: String) {
            model: cmsVideo(id: $id, name: $name) {
                ...cmsVideoFields
            }
        }

        ${cmsVideoFieldsFragment}
        `,
        {
            variables: {
                id: id ?? (options?.name? undefined: Guid.empty), // Only pass undefined if we are looking up by name, otherwise use Guid.empty.
                name: options?.name,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
