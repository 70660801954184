import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { ValidationErrors } from 'pojo-validator';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import * as React from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useTranslation } from 'react-i18next';
import { Button, Col, FormGroup, Label, ListGroupItem, Row, InputGroup, InputGroupAddon } from 'reactstrap';
import { CalendarEventUrl } from '../../api/main/models/CalendarEventUrl';
import { getFullUrl } from '../../utilities/getFullUrl';
import { TrackedLink } from '../shared/trackedLink/TrackedLink';

export interface EventUrlProps {
    model: CalendarEventUrl,
    change: (changes: Partial<CalendarEventUrl>) => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    remove: () => void,
    singleItem?: boolean,
}

/**
 * Component for editing a URL attached to an event as a CalenderEventUrl.
 * @param props
 */
export const EventUrl = (props: EventUrlProps) => {
    const {
        model, change,
        validate, validationErrors,
        remove,
        singleItem = false,
    } = props;

    const { t } = useTranslation();

    return (
        <ListGroupItem style={singleItem ? { paddingLeft: '0px', paddingRight: '0px', paddingTop: '0px', paddingBottom: '0px', borderTopStyle: 'none', borderBottomStyle: 'none' } : undefined}>
            <Row>
                <Col>
                    <FormGroup>
                        <Label htmlFor="name" className="sr-only">{t('eventUrl.name', 'Link text')}</Label>
                        <ValidatedInput name="name" placeholder={t('eventUrl.name.placeholder', 'Link text')} type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label htmlFor="url" className="sr-only">{t('eventUrl.url', 'Url')}</Label>
                        <InputGroup>
                            <ValidatedInput name="url" placeholder={t('eventUrl.url.placeholder', 'Url')} type="url" value={model?.url ?? ''} onChange={e => change({ url: e.currentTarget.value })} onBlur={e => validate('url')} validationErrors={validationErrors['url']} />
                            <InputGroupAddon addonType="append">
                                <TrackedLink href={getFullUrl(model.url) ?? '#'} calendarEventUrlId={model.id ?? ''} calendarEventId={model.calendarEventId ?? ''} target="_blank" rel="noreferrer"
                                    className={`btn btn-secondary ${model.url? '': 'disabled'}`}
                                    >
                                    {t('eventUrl.checkLink', 'Check link')}
                                </TrackedLink>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <ConditionalFragment showIf={!singleItem}>
                    <Col xs="auto">
                        <FormGroup>
                            <Label htmlFor="delete" className="sr-only">&nbsp;</Label>
                            <div>
                                <Button color="danger" outline onClick={() => remove()}>
                                    <FontAwesomeIcon icon="trash" />
                                    <> </>
                                    <span className="sr-only">{t('common.delete', 'Delete')}</span>
                                </Button>
                            </div>
                        </FormGroup>
                    </Col>
                </ConditionalFragment>
            </Row>
        </ListGroupItem>
        );
}