import * as React from 'react';
import { atom, useRecoilState } from 'recoil';
import { persistAtom } from '../../configure/recoilPersistConfig';

export const calendarPreferencesExcludedEventTypesState = atom<Array<string>>({
    key: 'calendarPreferencesExcludedEventTypesState',
    default: [],
    effects_UNSTABLE: [persistAtom],
});

/**
 * Manage the toggleable array of excluded event types. 
 */
export function useCalendarPreferencesExcludedEventTypes(): [(id: string) => boolean, (id: string) => void] {
    const [value, setValue] = useRecoilState(calendarPreferencesExcludedEventTypesState);
    const hasItem = React.useCallback((id: string) => !!value.find(it => it === id), [value]);
    const toggleItem = React.useCallback((id: string) => {
        setValue(prevState => {
            const existing = prevState.find(it => it === id);
            if (existing) {
                return prevState.filter(it => it !== id);
            }

            return [...prevState, id];
        });
    }, [setValue]);

    return [hasItem, toggleItem];
}