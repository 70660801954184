import './aboutPage.scss';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from 'reactstrap';
import { useAboutPageViewModel } from '../../api/main/cmsPages/viewModels/useAboutPageViewModel';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { CmsTextHtmlDisplay } from '../cms/cmsTexts/CmsTextHtmlDisplay';
import { CmsVideoDisplay } from '../cms/cmsVideos/CmsVideoDisplay';
import { Background } from '../shared/background/Background';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { AuthorizeContainer } from '../../shared/authorizeRoute';

/**
 * Cms based About page. 
 */
export const AboutPage = () => {
    const { t } = useTranslation();

    const { data: { video, section1, section2 /*, section3, section4,*/ }, errors: cmsTextErrors } = useAboutPageViewModel();

    return (
        <Background className="about-page">
            <div className="about-page-section-1">
                <Container>
                    <AlertOnErrors errors={cmsTextErrors} />

                    <Row>
                        <Col xs={12} md="">
                            <CmsTextHtmlDisplay model={section1} />
                        </Col>
                        <Col>
                            <CmsVideoDisplay model={video} />
                        </Col>
                    </Row>
                    <AuthorizeContainer showIfNotAuthorized={true}>
                        <div>
                            <h2>
                                {t('aboutPage.registerHeading', 'Not registered yet?')}
                            </h2>
                            <p>
                                {t('aboutPage.registerText', 'Register for your free TheSchoolYear account and keep yourself and your calendar up to date with everything that\'s happening in education, all the time.')}
                            </p>
                            <Row>
                                <Col>
                                    <Link to={`/account/login`}>
                                        {t('aboutPage.login', 'Already have an account?  Sign in here.')}
                                    </Link>
                                </Col>
                                <Col xs="auto">
                                    <LinkContainer to="/account/register">
                                        <Button color="primary">
                                            {t('aboutPage.registerButton', 'Sign up for a free account')}
                                        </Button>
                                    </LinkContainer>
                                </Col>
                            </Row>
                        </div>
                    </AuthorizeContainer>
                </Container>
            </div>
            <div className="about-page-section-2">
                <Container>
                    <CmsTextHtmlDisplay model={section2} />
                </Container>
            </div>
        </Background>
    );
};