import './calendarEventModal.scss';
import * as React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { EventTypeColorBlock } from '../shared/calendar/EventTypeColorBlock';
import { ConditionalFragment } from 'react-conditionalfragment';
import { HtmlDisplay } from '../../shared/htmlEditor';
import { scheduleDescription } from '../../utilities/scheduleDescription';
import { getFullUrl } from '../../utilities/getFullUrl';
import { LinkContainer } from 'react-router-bootstrap';
import { TrackedLink } from '../shared/trackedLink/TrackedLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CalendarHomeScheduleViewModel } from './CalendarHomeScheduleViewModel';

export interface CalendarEventModalProps {
    model: CalendarHomeScheduleViewModel,

    isOpen: boolean,
    toggle: () => void,
}


/**
 * Edit a CalendarEvent.
 */
export const CalendarEventModal = (props: CalendarEventModalProps) => {
    const { model, isOpen, toggle } = props;

    const { t } = useTranslation();


    return (
        <Modal className="calendar-event-modal" size="xl" isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <Row noGutters>
                    <Col xs="auto">
                        <EventTypeColorBlock className="calendar-event-modal-header-event-type-color-block" color={model?.calendarEvent?.eventType?.color ?? 'transparent'} />
                    </Col>
                    <Col className="calendar-event-modal-real-header">
                        <h2>
                            {model?.calendarEvent?.name}
                        </h2>
                        <ConditionalFragment showIf={!!model?.calendarEvent?.location}>
                            <div className="calendar-event-modal-event-location">
                                {model?.calendarEvent?.location}
                            </div>
                        </ConditionalFragment>
                        
                        <div className="calendar-event-modal-event-type">
                            <Row noGutters>
                                {/*<Col xs="auto">*/}
                                {/*    <EventTypeColorBlock color={model?.calendarEvent?.eventType?.color ?? 'transparent'} />*/}
                                {/*</Col>*/}
                                <Col>
                                    {model?.calendarEvent?.eventType?.name}
                                </Col>
                                <Col xs="auto" className="calendar-event-modal-time">
                                    <FontAwesomeIcon icon="calendar-alt" />
                                    <> </>
                                    {
                                        model ? (<>{scheduleDescription(model)}</>)
                                            : (<></>)
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs={12} md="">
                        <ConditionalFragment showIf={!!model?.calendarEvent?.imageBlobReference}>
                            <div className="calendar-event-modal-promotional-image-container">
                                <img className="calendar-event-modal-promotional-image img-fluid" src={model?.calendarEvent?.imageBlobReference?.url || ''} alt={model?.calendarEvent?.name} />
                            </div>
                        </ConditionalFragment>

                        <div className="calendar-event-modal-promotional-description">
                            <HtmlDisplay html={model?.calendarEvent?.descriptionHtml ?? ''} />
                        </div>
                    </Col>
                    <Col xs={12} md={3}>
                        <div className="calendar-event-modal-sidebar">
                            <div className="calendar-event-modal-urls">
                                <div>
                                    {t('calendarEventModal.nextSteps', 'Next steps:')}
                                </div>
                                {
                                    model?.calendarEvent?.urls?.map((item, index) => (
                                        <TrackedLink key={item.id}
                                            href={getFullUrl(item.url ?? '')}
                                            calendarEventUrlId={item.id}
                                            calendarEventId={model?.calendarEvent?.id}
                                            rel="noreferrer"
                                            target="_blank"
                                            className={`calendar-event-modal-url-button btn btn-primary`}
                                        >
                                            {item.name}
                                        </TrackedLink>
                                    ))
                                }
                            </div>
                            <div className="calendar-event-modal-subscribe">
                                <div>
                                    {t('calendarEventModal.nextSteps', 'Add to your calendar:')}
                                </div>
                                <a className="btn btn-light calendar-event-modal-calendar-button" href={`/api/icalendar/download/${model.id}`} download>
                                    <FontAwesomeIcon icon="calendar-plus" /><> </>
                                    {t('calendarEventModal.addToCalendar', 'Add this event your calendar')}
                                </a>
                                {/*<a className="btn btn-light calendar-event-modal-calendar-button" href={`/api/icalendar/subscribe`} download>*/}
                                {/*    {t('calendarEventModal.addMultipleToCalendar', 'Add multiple event your calendar')}*/}
                                {/*</a>*/}
                                <a className="btn btn-light calendar-event-modal-calendar-button" href={`${new URL(`/api/icalendar/subscribe`, window.location.href).href.replace(/^https:/, 'webcal:')}`}>
                                    <FontAwesomeIcon icon="calendar-alt" /><> </>
                                    {t('calendarEventModal.subscribeToCalendar', 'Add TheSchoolYear to your calendar')}
                                </a>
                            </div>
                            <div className="calendar-event-modal-providers">
                                <div className="calendar-event-modal-providers-heading">
                                    {t('calendarEventModal.providersHeading', 'Organisation:')}
                                </div>
                                <div className="calendar-event-modal-providers-list">
                                    <Row>
                                        {
                                            model?.calendarEvent?.providers?.map(item => (
                                                <Col key={item.id} xs="" md={12} className="calendar-event-modal-provider">
                                                    <LinkContainer to={`/calendar/search/${encodeURIComponent(item.provider?.name ?? '')}`}>
                                                        {
                                                            item?.provider?.imageBlobReference ? (<img className="calendar-event-modal-provider-image img-fluid" src={item.provider?.imageBlobReference?.url ?? ''} alt={item.provider?.name ?? ''} />)
                                                                : (<div className="calendar-event-modal-provider-no-image">{item.provider?.name ?? ''}</div>)
                                                        }
                                                    </LinkContainer>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};
