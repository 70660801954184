import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { loginPartialPageViewModelQuery } from '../../generated/loginPartialPageViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { cmsVideoFieldsFragment } from '../../models/CmsVideo';
import { blobReferenceFieldsFragment } from '../../models/BlobReference';
import { cmsTextFieldsFragment } from '../../models/CmsText';

/**
 * View model for LoginPartialPage that loads all cms data needed for the page at once.
 * @param options
 */
export function useLoginPartialPageViewModel(options: AsyncLoadOptions = {}): AsyncLoadResult<loginPartialPageViewModelQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<loginPartialPageViewModelQuery>(
        gql`
        query loginPartialPageViewModelQuery {
            video: cmsVideo(name: "Login.Video") {
                ...cmsVideoFields

                videoBlobReference {
                    ...blobReferenceFields
                }

                thumbnailBlobReference {
                    ...blobReferenceFields
                }
            }

            text: cmsText(name: "Login.VideoText") {
                ...cmsTextFields
            }
        }

        ${cmsVideoFieldsFragment}
        ${blobReferenceFieldsFragment}
        ${cmsTextFieldsFragment}
        `,
        {
            variables: {
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}