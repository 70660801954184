import gql from "graphql-tag";
import { cmsVideoFields } from "../generated/cmsVideoFields";

export const cmsVideoFieldsFragment = gql`
    fragment cmsVideoFields on CmsVideo {
        id
        name
        videoBlobReferenceId
        thumbnailBlobReferenceId
        archived
        createdByUserId
        createdDate
    }
`;


export type CmsVideo = Omit<cmsVideoFields, '__typename'>;
