import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CalendarEventCalendarEventProviderCreateInput } from '../generated/globalTypes';
import { createCalendarEventCalendarEventProviderMutation, createCalendarEventCalendarEventProviderMutationVariables } from '../generated/createCalendarEventCalendarEventProviderMutation';
import { calendarEventCalendarEventProviderFieldsFragment } from '../models/CalendarEventCalendarEventProvider';

/**
 * Get a callback to create a CalendarEventCalendarEventProvider in the store.
 */
export function useCreateCalendarEventCalendarEventProviderCallback(): [(model: CalendarEventCalendarEventProviderCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createCalendarEventCalendarEventProviderMutation, createCalendarEventCalendarEventProviderMutationVariables>(
        gql`
            mutation createCalendarEventCalendarEventProviderMutation ($model: CalendarEventCalendarEventProviderCreateInput!) {
                createCalendarEventCalendarEventProvider(model: $model) {
                    ...calendarEventCalendarEventProviderFields
                }
            }

            ${calendarEventCalendarEventProviderFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: CalendarEventCalendarEventProviderCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
